import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  display: ${(props) => props.display || 'block'};

  ${(props) =>
    props.responsiveSizes?.map(
      (size, index) => `
    @media (max-width: ${size.breakpoint}px) {
      font-size: ${size.fontsize};
      font-weight: ${size.fontweight};
      color: ${size.color};
      line-height:${size.lineheight};
      display:${size.display};
      margin:${size.margin};
      
    }
    
  `
    )}

  ${(props) =>
    props.specialRes?.map(
      (size, index) => `
    @media (max-width: ${size.breakpoint}px) {
      margin:${size.margin};
      display:${size.display};
     
    }

  `
    )}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
`;

export const Section = styled.div`
  width: 100%;
  /* min-height: 30rem; */
  ${'' /* background-color:red ; */}
  display:flex;
  padding: 2rem 15rem 0;
  overflow: hidden;

  @media (max-width: 2561px) {
    padding: 2rem 40rem;
  }
  @media (max-width: 2200px) {
    padding: 2rem 30rem;
  }
  @media (max-width: 1900px) {
    padding: 2rem 25rem;
  }
  @media (max-width: 1600px) {
    padding: 2rem 17rem;
  }
  @media (max-width: 1300px) {
    padding: 2rem 12rem;
  }
  @media (max-width: 1200px) {
    padding: 2rem 10rem;
  }
  @media (max-width: 1100px) {
    padding: 2rem 8rem;
  }
  @media (max-width: 1000px) {
    padding: 2rem 6rem;
  }
  @media (max-width: 750px) {
    padding: 2rem 4rem;
  }
  @media (max-width: 500px) {
    /* padding: 0rem 2rem; */
  }
  @media (max-width: 420px) {
    padding: 0rem 2rem;
  }
`;

export const Container = styled.div`
  flex: 0 1 100%;
  ${'' /* background-color:green ; */}
  min-height:40rem;
  display: flex;

  @media (max-width: 920px) {
    min-height: 20rem;
  }
`;

export const CoverLeftContainer = styled.div`
  flex: 0 1 70%;
  /* background-color: red; */

  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 10rem;
  ${'' /* justify-content:space-between ; */}
  justify-content: space-between;

  @media (max-width: 1500px) {
    flex: 0 1 75%;
    padding: 2rem 0 0 4rem;
  }
  @media (max-width: 920px) {
    flex: 0 1 80%;
    padding: 2rem 0 0 4rem;
  }

  @media (max-width: 650px) {
    flex: 0 1 100%;
  }
  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const CoverRightContainer = styled.div`
  flex: 0.85;
  ${'' /* background-color:#eee ; */}
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const CoverTitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

export const HomeTitle = styled.p`
  font-size: 4.8rem;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 2560px) {
    font-size: 5.8rem;
  }
  @media (max-width: 2000px) {
    font-size: 5rem;
  }
  @media (max-width: 1800px) {
    font-size: 4.6rem;
  }
  @media (max-width: 1350px) {
    font-size: 4.3rem;
  }
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  @media (max-width: 1100px) {
    font-size: 3.5rem;
  }
  @media (max-width: 320px) {
    font-size: 3rem;
  }
`;

export const HighlightSpan = styled.span`
  color: var(--secondary-blue);
`;

export const CoverSearchBarContainer = styled.div`
  position: relative;
  flex: 0 1 20%;
  margin: 10rem 8rem 0 0;
  display: flex;
  justify-content: left;
  align-items: center;

  @media (max-width: 1500px) {
    margin: 10rem 8rem 0 0;
  }
  @media (max-width: 1200px) {
    margin: 7rem 5rem 0 0;
  }
  @media (max-width: 650px) {
    margin: 7rem 0rem 0 0;
  }
  @media (max-width: 600px) {
    display: none;
    /* margin: 1rem 0 0 0; */
    padding: 0;
  }
`;

export const SearchInput = styled.input`
  flex: 0.9;
  padding: 2rem;
  background-color: #ddd;
  outline: none;
  border: none;
  border-radius: 1rem;
  font-size: 1.5rem;

  @media (max-width: 420px) {
    padding: 1.5rem 1rem;
    font-size: 1.3rem;
    width: 80%;
  }
`;

export const SearchBtn = styled.div`
  width: 4rem;
  height: 4rem;
  ${'' /* background-color:red ; */}
  position: relative;
  right: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  transition: all 0.3s;
  :hover {
    cursor: pointer;
    font-size: 3.5rem;
  }
  @media (max-width: 1500px) {
    right: 9%;
  }
  @media (max-width: 1200px) {
    right: 12%;
  }
  @media (max-width: 750px) {
    right: 15%;
  }

  @media (max-width: 420px) {
    position: absolute;
    right: 10%;
  }
`;

export const LocationBtn = styled.div`
  width: 20%;
  padding: 1rem 1rem;
  background-color: var(--main-blue);
  border-radius: 3rem;
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1500px) {
    width: 30%;
  }
  @media (max-width: 1120px) {
    width: 35%;
  }
  @media (max-width: 1050px) {
    width: 40%;
  }
  @media (max-width: 720px) {
    width: 50%;
  }
  @media (max-width: 650px) {
    width: 40%;
  }
  @media (max-width: 530px) {
    width: 50%;
  }
  @media (max-width: 380px) {
    width: 60%;
  }
  @media (max-width: 330px) {
    width: 70%;
  }
`;

export const FadingCircle = styled.div`
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  background: rgb(82, 159, 252);
  background: radial-gradient(
    circle,
    rgba(82, 159, 252, 1) 0%,
    rgba(82, 159, 252, 0.8520658263305322) 29%,
    rgba(82, 159, 252, 0.7540266106442577) 53%,
    rgba(82, 159, 252, 0.4543067226890757) 70%
  );
  filter: blur(5px);
  @media (max-width: 920px) {
    width: 20rem;
    height: 20rem;
  }
`;

export const UserOneContainer = styled.div`
  width: 15rem;
  height: 25rem;
  border-radius: 15rem;
  ${'' /* background-color:red ; */}
  position:absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  @media (max-width: 920px) {
    width: 10rem;
    height: 15rem;
    top: 10%;
  }
`;

export const UserTwoContainer = styled.div`
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  ${'' /* background-color:red ; */}
  position:absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url(${(props) => props.img});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid #529ffc;
  z-index: 0;

  @media (max-width: 920px) {
    width: 15rem;
    height: 15rem;
    top: 50%;
  }
`;

export const ButtonRow = styled.div`
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  padding: 2rem 2rem 2rem 0;
  flex-wrap: wrap;
  /* background-color: red; */

  @media (max-width: 1310px) {
    width: 85%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    justify-content: flex-start;
  }
  @media (max-width: 600px) {
    padding: 0rem 0;
  }
`;

export const LinkButtonContainer = styled(Link)`
  display: flex;
  width: 43%;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  margin: 1rem 0;
  background-color: ${(props) =>
    props.selected ? 'var(--gold)' : 'var(--main-blue)'};
  border-radius: 1rem;
  transition: all 0.3s;
  text-decoration: none;
  color: #eee;

  -webkit-box-shadow: 0px 8px 15px 5px #c3c3c3;
  box-shadow: 0px 8px 15px 5px #c3c3c3;

  @media (max-width: 2560px) {
    padding: 1.6rem 2.5rem;
  }
  @media (max-width: 1800px) {
    padding: 1.4rem 2.5rem;
  }
  @media (max-width: 1600px) {
    padding: 1.2rem 2.5rem;
  }
  @media (max-width: 1500px) {
    padding: 1rem 2.5rem;
  }

  @media (max-width: 1305px) {
    width: 40%;
  }
  @media (max-width: 1100px) {
    margin: 1rem 2rem;
  }
  @media (max-width: 820px) {
    width: 60%;
  }
  @media (max-width: 650px) {
    width: 40%;
  }

  @media (max-width: 540px) {
    margin: 1rem 0.5rem;
    width: 45%;
  }
  @media (max-width: 426px) {
    margin: 1rem 0rem;
    width: 100%;
  }

  :hover {
    background-color: var(--gold);
    color: #333;
    cursor: pointer;
  }
`;
