import React from "react";
import Dashboard from "../../../components/shared/Dashboard";
import UniProfile from "../../../components/university/dashboard-components/UniProfile";

const UniversityProfile = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <UniProfile />
          </>
        }
      />
    </div>
  );
};

export default UniversityProfile;
