import React from 'react'
import Dashboard from '../../../components/shared/Dashboard'
import DashboardHome from '../../../components/shared/DashboardHome';

const UniversityDashHome = () => {
  return (
    <div>
      <Dashboard rightContainerContent={
        <DashboardHome  />
      } />
    </div>
  );
}

export default UniversityDashHome