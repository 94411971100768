import React, { useState } from 'react';
import * as u from './AdminProfileElements';
import { FaCamera } from 'react-icons/fa';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
// import { Button } from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AdminProfile = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState(user?.name || '');
  const [userId] = useState(user?.id);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [logo, setLogo] = useState(null);

  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
  };

  /////////////////// Update Admin Profile Details (START) ///////////////////
  const updateProfile = async () => {
    const formData = { name };
    return toast.promise(
      fetch(`/api/v1/users/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Error updating name');
        }
        return response.json();
      }),
      {
        loading: 'Updating name...',
        success: 'Name updated successfully!',
        error: 'Failed to update name',
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };
  /////////////////// Update Admin Profile Details (END) ///////////////////

  ////////////////// Update Admin Profile Password (START) //////////////////
  const updatePassword = async () => {
    const formData = { password, newPassword, passwordConfirm };

    return toast.promise(
      fetch('/api/v1/auth/update-my-password', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Error updating the password');
        }
        return response.json();
      }),
      {
        loading: 'Updating password...',
        success: 'Password updated successfully!',
        error: 'Failed to update password',
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };
  ////////////////// Update Admin Profile Password (END) //////////////////

  //////////////////// Deactivate Admin Profile (START) ///////////////////
  const deactivateProfile = async () => {
    var result = window.confirm(
      'Are you sure you want to Deactivate the University?'
    );
    if (result === true) {
      return toast.promise(
        fetch(`/api/v1/users/deactivateUser/${userId}`, {
          method: 'PATCH',
          //body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Failed to deactivate the university');
          }
          logout();
          navigate('/login');
          return response.json();
        }),
        {
          loading: 'Deactivating University...',
          success: 'University Deactivated!',
          error: 'Failed to deactivate the university',
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    }
  };
  //////////////////// Deactivate Admin Profile (END) ///////////////////

  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>University Admin Profile</u.HeaderTitle>
      </u.HeaderContainer>
      <u.FormContainer>
        <u.Logo>
          <u.ImageSubSec>
            {logo ? <u.LogoImage src={URL.createObjectURL(logo)} /> : <p></p>}
          </u.ImageSubSec>
          <u.Icon>
            <FaCamera />
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={handleLogo}
            />
          </u.Icon>
          <u.Text>
            <p>Profile Picture</p>
          </u.Text>
        </u.Logo>
        <u.Fields>
          <DashboardTextField
            placeholder={'Email'}
            value={user?.email || 'email'}
            disabled
          />
          <DashboardTextField
            placeholder="Enter Name Here"
            value={name}
            setValue={setName}
            disabled={false}
          />
        </u.Fields>

        <u.Button>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
            onClick={updateProfile}
          />
        </u.Button>
      </u.FormContainer>
      <u.HeaderContainer>
        <u.HeaderTitle>Update Password</u.HeaderTitle>
      </u.HeaderContainer>
      <u.FormContainer>
        <u.Fields>
          <DashboardTextField
            placeholder={'Current Password'}
            value={password}
            setValue={setPassword}
          />
          <DashboardTextField
            placeholder={'New Password'}
            value={newPassword}
            setValue={setNewPassword}
          />
          <DashboardTextField
            placeholder={'Confirm New Password'}
            value={passwordConfirm}
            setValue={setPasswordConfirm}
          />
        </u.Fields>
        <u.Button>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
            onClick={updatePassword}
          />
        </u.Button>
      </u.FormContainer>
      <u.HeaderContainer>
        {/* <Button
          sx={{ borderRadius: "30px", fontSize: "1.5rem" }}
          size="large"
          color="error"
          variant="contained"
          onClick={deactivateProfile}
        >
          Deactivate University
        </Button> */}
      </u.HeaderContainer>
    </u.Container>
  );
};

export default AdminProfile;
