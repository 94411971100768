export const countries =
    [
        'Australia',
        'Austria',
        'Bangaladesh',
        'Belarus',
        'Belgium',
        'Canada',
        'China',
        'Cyprus',
        'Denmark',
        'Finland',
        'France',
        'Germany',
        'India',
        'Korea',
        'Latvia',
        'Malayasia',
        'Netherlands',
        'New Zealand',
        'Romania',
        'Russia',
        'Singapore',
        'Switzerland',
        'United Kingdom',
        'USA'
    ];
