import React from 'react'
import Dashboard from '../../../components/shared/Dashboard'
import AdminProfile from '../../../components/university/dashboard-components/AdminProfile'
const UniversityAdminProfile = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <AdminProfile/>
          </>
        }
      />
    </div>
  )
}

export default UniversityAdminProfile