import styled from "styled-components";

export const Container = styled.div`
  ${"" /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  ${"" /* background-color:red ; */}
  width:100%;
  display: flex;
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0 auto 0 0;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  margin: 1rem;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CardHeader = styled.div`
  font-size: 2.5rem;
`;

export const Label = styled.label`
  border: 0px solid #ccc;
  display: inline-block;
  padding: 6px 18px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-right: 1rem;

  &:hover {
    background-color: #ffffff;
    color: #222;
    transition: all 0.3s ease-in-out;
  }

  & input[type="file"] {
    display: none;
  }
`;
