import React from 'react'
import Dashboard from '../../components/shared/Dashboard'
import Profile from '../../components/student/Profile'

const StudentProfile = () => {
  return (
      <div>
      <Dashboard
        rightContainerContent={
          <>
            <Profile/>
          </>
        }
      />
      </div>
  )
}

export default StudentProfile