import React from 'react';
import * as c from './UniLogoListelemenets';
import img0 from './../../images/home_universities/1.png';
import img1 from './../../images/home_universities/2.png';
import img2 from './../../images/home_universities/3.png';
import img3 from './../../images/home_universities/4.png';
import img4 from './../../images/home_universities/5.png';
import img5 from './../../images/home_universities/6.png';
import img6 from './../../images/home_universities/7.png';
import img7 from './../../images/home_universities/8.png';
import img8 from './../../images/home_universities/9.png';
import img9 from './../../images/home_universities/10.png';
import img10 from './../../images/home_universities/11.png';
import img11 from './../../images/home_universities/12.png';
import img12 from './../../images/home_universities/13.png';
import img13 from './../../images/home_universities/14.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const UniLogoList = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <c.Section>
      <c.Container>
        <c.CarousalContainer>
          <Slider {...settings}>
            <div>
              <c.UniLogoContainer img={img0} />
            </div>
            <div>
              <c.UniLogoContainer img={img1} />
            </div>
            <div>
              <c.UniLogoContainer img={img2} />
            </div>
            <div>
              <c.UniLogoContainer img={img3} />
            </div>
            <div>
              <c.UniLogoContainer img={img4} />
            </div>
            <div>
              <c.UniLogoContainer img={img5} />
            </div>
            <div>
              <c.UniLogoContainer img={img6} />
            </div>
            <div>
              <c.UniLogoContainer img={img7} />
            </div>
            <div>
              <c.UniLogoContainer img={img8} />
            </div>
            <div>
              <c.UniLogoContainer img={img9} />
            </div>
            <div>
              <c.UniLogoContainer img={img10} />
            </div>
            <div>
              <c.UniLogoContainer img={img11} />
            </div>
            <div>
              <c.UniLogoContainer img={img12} />
            </div>
            <div>
              <c.UniLogoContainer img={img13} />
            </div>
          </Slider>
        </c.CarousalContainer>
      </c.Container>
    </c.Section>
  );
};

export default UniLogoList;
