import React from 'react'
import AboutUS from '../components/shared/AboutUs'

const AboutUs = () => {
    return ( 
        <>
            <AboutUS />
        </>
     );
}
 
export default AboutUs;