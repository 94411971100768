import { FiHome, FiUsers, FiBook, FiCheckCircle } from 'react-icons/fi';

const links = [
  {
    icon: <FiHome />,
    linkText: 'Home',
    url: '/admin/home',
  },
  {
    icon: <FiBook />,
    linkText: 'University Profiles',
    url: '/admin/universities',
  },
  {
    icon: <FiCheckCircle />,
    linkText: 'Approve Courses',
    url: '/admin/courses',
  },
  {
    icon: <FiBook />,
    linkText: 'Study Abroad Profiles',
    url: '/admin/study-abroad-agencies',
  },
  {
    icon: <FiCheckCircle />,
    linkText: 'Approve Study Abroad Courses',
    url: '/admin/study-abroad-agencies/courses',
  },

  {
    icon: <FiUsers />,
    linkText: 'Users',
    url: '/admin/users',
  },
  // {
  //   icon: <FiImage />,
  //   linkText: 'Banners',
  //   url: '/admin/banners',
  // },
];

export { links as adminLinks };
