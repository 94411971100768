import React from 'react'
import styled from 'styled-components'


const TextLabel = styled.p`
    font-size:${props => props.fontsize || '1.5rem'} ;
    margin:${props => props.margin || '1rem 0'}  ;
    font-weight:${props => props.fontweight || '400'}  ;
    color:${props => props.fontcolor || '#333'}  ;
    font-family:${props => props.fontfamily || 'var(--dashboard-font)'}  ;
    @media screen and (max-width: 800px) {
      font-size:${props => props.fontsize || '1.2rem'} ;
    }
`;

const Label = ({text}) => {
  return (
      <TextLabel>
          {text}
    </TextLabel>
  )
}

export default Label