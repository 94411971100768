import React, { useState } from 'react';
import * as c from './ScolarshipInsightsElements.js';
// import { BiSearch } from 'react-icons/bi';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-hot-toast';
import submitForm from './../../../hooks/submitForm.js';

const ScholarshipInsights = ({ scholarInsights, university }) => {
  //const [checked, setChecked] = useState(false);

  // const handleChange = (event, scholarInsight) => {
  //   setChecked(event.target.checked);
  //   updateChecked(scholarInsight, event.target.checked);
  // };

  // const updateChecked = async (scholarInsight, ticked) => {
  //   await toast.promise(
  //     submitForm(
  //       `/api/v1/insights/scholar/${scholarInsight._id}`,
  //       { ticked },
  //       "patch",
  //       {}
  //     ),
  //     {
  //       loading: "Updating leads...",
  //       success: (data) => {
  //         console.log({ data });
  //         window.location.reload(false);
  //         return ` ${data.data?.message} ` || "success";
  //       },
  //       error: (err) => `${err.response.data.message}`,
  //     },
  //     {
  //       style: {
  //         borderRadius: "10px",
  //         background: "#333",
  //         color: "#fff",
  //         fontSize: "2rem",
  //       },
  //     }
  //   );
  // };

  const [status, setStatus] = useState({});

  const handleChange = (event, si) => {
    setStatus(event.target.value);
    updateScholarLeadStatus(si, event.target.value);
  };
  const updateScholarLeadStatus = async (si, status) => {
    await toast.promise(
      submitForm(
        `/api/v1/insights/scholar/${si._id}`, // Change 'ci.id' to 'ci._id'
        { status },
        'patch',
        {}
      ),
      {
        loading: 'Updating Course Status...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return `${data.data?.message}` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };
  return (
    <>
      {university.packages === 'standard' ||
      university.packages === 'premium' ? (
        <c.Container>
          <c.HeaderContainer>
            <c.HeaderTitle>
              Scholarships Leads ({scholarInsights.length})
            </c.HeaderTitle>

            {/* <c.SearchBarContainer>
              <c.SearchBar placeholder="Enter course name" />
              <c.SearchIconContainer>
                {' '}
                <BiSearch />{' '}
              </c.SearchIconContainer>
            </c.SearchBarContainer> */}
          </c.HeaderContainer>

          <c.TableContainer>
            <c.Table>
              <c.TableHeader>
                <c.TableDataCell th={true}>#</c.TableDataCell>
                <c.TableDataCell th={true}>Date</c.TableDataCell>
                <c.TableDataCell th={true}>Scholarship</c.TableDataCell>
                <c.TableDataCell th={true}>Name</c.TableDataCell>
                <c.TableDataCell th={true}>Email</c.TableDataCell>
                <c.TableDataCell th={true}>Mobile</c.TableDataCell>
                <c.TableDataCell th={true}>Action</c.TableDataCell>
              </c.TableHeader>

              {scholarInsights.map((si, i) => {
                return (
                  <c.TableRow key={i}>
                    <c.TableDataCell>{i + 1} </c.TableDataCell>
                    <c.TableDataCell>
                      {si.createdAt.split('T')[0]}
                      {''}
                      {/* <Checkbox
                        size="large"
                        checked={scholarInsights.ticked}
                        onChange={(event) =>
                          handleChange(event, scholarInsights)
                        }
                      />{" "} */}
                    </c.TableDataCell>

                    <c.TableDataCell>
                      {' '}
                      {si?.scholarship?.scholarName}{' '}
                    </c.TableDataCell>
                    <c.TableDataCell className="flex">
                      {si?.personName}
                    </c.TableDataCell>
                    <c.TableDataCell minwidth="15rem">
                      {si?.personEmail}{' '}
                    </c.TableDataCell>
                    <c.TableDataCell minwidth="15rem">
                      {si?.personMobile}{' '}
                    </c.TableDataCell>
                    <c.TableDataCell minwidth="10rem">
                      <FormControl>
                        <Select
                          name="package"
                          sx={{
                            m: 1,
                            minWidth: 120,
                          }}
                          size="small"
                          value={si?.status}
                          onChange={(event) => handleChange(event, si)}
                        >
                          {['Not Contacted', 'Qualified', 'Rejected'].map(
                            (status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </c.TableDataCell>
                  </c.TableRow>
                );
              })}
            </c.Table>
          </c.TableContainer>

          <c.TablePaginationContainer></c.TablePaginationContainer>
        </c.Container>
      ) : (
        <>
          <c.Container>
            <c.HeaderContainer>
              <c.HeaderTitle>
                Scholarships Leads ({scholarInsights.length})
              </c.HeaderTitle>
            </c.HeaderContainer>
          </c.Container>
        </>
      )}
    </>
  );
};

export default ScholarshipInsights;
