import React from 'react';
import Banner from '../components/Home/Banner';
// import CoursesCategory from '../components/Home/CoursesCategory';
import HomeCover from '../components/Home/HomeCover';
import UniLogoList from '../components/Home/UniLogoList';

const Home = () => {
  return (
    <>
      <HomeCover />
      {/* <CoursesCategory /> */}
      <Banner />
      <UniLogoList />
    </>
  );
};

export default Home;
