import React from 'react'
import Filter from '../../components/university/UniversityListing/Filter'

const UniversityListing = () => {
  return (
    <>
          <Filter/>
    </>
  )
}

export default UniversityListing