import Dashboard from '../../../components/shared/Dashboard';
import AddScholarship from '../../../components/university/dashboard-components/AddScholarships.jsx';

const UniversityAddCourse = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <AddScholarship />
          </>
        }
      />
    </div>
  );
};

export default UniversityAddCourse;
