import React, { useEffect, useState } from 'react';
import * as c from './SingleCourseElements';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
import { DashboardDropdown } from '../../shared/dashboard-form-elements/DropDown';
import { FaCamera } from 'react-icons/fa';
import TextBox from '../../shared/Form Elements/TextBox';
import { toast } from 'react-hot-toast';
import submitForm from '../../../hooks/submitForm';
import { useParams } from 'react-router-dom';
// import useFetch from '../../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';

import Checkbox from '@mui/material/Checkbox';
// import validateImage from '../../../hooks/validateImage';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};

const SingleCourse = ({ isPending, data }) => {
  const { id } = useParams();

  const [local, setLocal] = useState(false);
  const [foreign, setForeign] = useState(false);
  const [fcDescription, setFcDescription] = useState('');
  const [fcCoursePrice, setFcCoursePrice] = useState('');
  const [fcCountry, setFcCountry] = useState({
    label: 'Select Country',
    value: '',
  });

  const [courseName, setCourseName] = useState('');
  const [commencement, setCommencement] = useState('');
  const [entryRequirements, setEntryRequirements] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [coursePrice, setCoursePrice] = useState('');
  const [courseDuration, setDuration] = useState('');
  const [cover, setCover] = useState(null);
  const handleCover = (e) => {
    setCover(e.target.files[0]);
    // validateImage(e.target.files[0], 600, 600, setCover);
  };

  const [category, setCategory] = useState({
    label: 'Select course category',
    value: '',
  });

  const [formatDropDownVal, setFormatDropDownVal] = useState({
    label: 'Format',
    value: '',
  });

  const [qualificationDropDownVal, setQualificationDropDownVal] = useState({
    label: 'Qualification',
    value: '',
  });
  const [entryLevelDropDownVal, setEntryLevelDropDownVal] = useState({
    label: 'Entry level',
    value: '',
  });

  const fcCountries = [
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Bangaladesh', value: 'Bangaladesh' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Canada', value: 'Canada' },
    { label: 'China', value: 'China' },
    { label: 'Cyprus', value: 'Cyprus' },
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Finland', value: 'Finland' },
    { label: 'France', value: 'France' },
    { label: 'Germany', value: 'Germany' },
    { label: 'India', value: 'India' },
    { label: 'Korea', value: 'Korea' },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Malayasia', value: 'Malayasia' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Romania', value: 'Romania' },
    { label: 'Russia', value: 'Russia' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'USA', value: 'USA' },
  ];
  const formats = [
    { label: 'Full Time', value: 'Full-Time' },
    { label: 'Part Time', value: 'Part-Time' },
  ];

  const qualifications = [
    { label: 'Foundation', value: 'Foundation' },
    { label: 'Certificate', value: 'Certificate' },
    { label: 'Diploma', value: 'Diploma' },
    { label: 'Top-Up', value: 'Top-Up' },
    { label: 'Bachelor', value: 'Bachelor' },
    { label: 'Masters', value: 'Masters' },
  ];
  const entryLevels = [
    { label: 'Certificate', value: 'Certificate' },
    { label: 'GCE-O/L', value: 'GCE-O/L' },
    { label: 'GCE-A/L', value: 'GCE-A/L' },
    { label: 'Edexcel', value: 'Edexcel' },
    { label: 'Cambridge', value: 'Cambridge' },
    { label: 'HND', value: 'HND' },
    { label: 'BSc', value: 'BSc' },
    { label: 'PGD', value: 'PGD' },
    { label: 'Masters', value: 'Masters' },
  ];
  const Categories = [
    { label: 'Architecture & Design ', value: 'Architecture & Design' },
    { label: 'Arts & Humanities ', value: 'Arts & Humanities' },
    { label: 'Aviation ', value: 'Aviation' },
    { label: 'Beauty & Hair ', value: 'Beauty & Hair' },
    { label: 'Business & Management  ', value: 'Business & Management' },
    { label: 'Computing & IT', value: 'Computing & IT' },
    { label: 'Construction ', value: 'Construction' },
    { label: 'Engineering  ', value: 'Engineering' },
    {
      label: 'Event Management & Tourism ',
      value: 'Event Management & Tourism',
    },
    {
      label: 'Food Science & Agriculture ',
      value: 'Food Science & Agriculture',
    },
    { label: 'Finance ', value: 'Finance' },
    { label: 'Hotel & Hospitality ', value: 'Hotel & Hospitality' },
    {
      label: 'Journalism & Media Studies ',
      value: 'Journalism & Media Studies',
    },
    { label: 'Languages ', value: 'Languages' },
    { label: 'Law & Administration  ', value: 'Law & Administration' },
    { label: 'Marketing  ', value: 'Marketing' },
    { label: 'Medicine and Health   ', value: 'Medicine and Health' },
    { label: 'Military Sciences  ', value: 'Military Sciences' },
    { label: 'Marine  ', value: 'Marine' },
    {
      label: 'Professional Certifications  ',
      value: 'Professional Certifications',
    },
    { label: 'Physical Science  ', value: 'Physical Science' },
    { label: 'Social Science  ', value: 'Social Science' },
    { label: 'Soft Skill Development  ', value: 'Soft Skill Development' },
    { label: 'Sports & Coaching  ', value: 'Sports & Coaching' },
  ];
  const [appear, setAppear] = useState(true);

  const handleEdit = () => {
    setAppear(true);
  };

  const updateCourse = async (e) => {
    e.preventDefault();

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    if (
      courseDescription.replace(/[\r\n]/g, '').length > 500 ||
      fcDescription.replace(/[\r\n]/g, '').length > 500
    ) {
      return toast.error(
        'Course descriptions character limit is exceeded. Limit is 500 characters.',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    }

    const formData = new FormData();

    formData.append('courseName', courseName);
    formData.append('courseDescription', courseDescription);
    formData.append('coursePrice', coursePrice);
    formData.append('courseType', formatDropDownVal.value);
    formData.append('courseQualification', qualificationDropDownVal.value);
    formData.append('courseEntryLevel', entryLevelDropDownVal.value);
    formData.append('category', category.value);
    formData.append('courseDuration', courseDuration);
    formData.append('cover', cover);
    formData.append('commencement', commencement);
    formData.append(
      'entryRequirements',
      entryRequirements.replace(/[\r\n]+/g, '')
    );

    formData.append('local', local);
    formData.append('foreign', foreign);
    formData.append('fcDescription', fcDescription);
    formData.append('fcCountry', fcCountry.value);
    formData.append('fcCoursePrice', fcCoursePrice);

    await toast.promise(
      submitForm(`/api/v1/courses/${id}`, formData, 'patch', headers),
      {
        loading: 'Updating Course...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  useEffect(() => {
    //   const { data, isError, isPending } = useFetch(`/api/v1/courses/${id}`);
    fetch(`/api/v1/courses/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error loading data');
        }
        return response.json();
      })
      .then((data) => {
        // console.log({ data });
        setLocal(data.data.course.local);
        setForeign(data.data.course.foreign);
        setFcCountry({
          label: data.data.course.fcCountry || 'not available',
          value: data.data.course.fcCountry || '',
        });
        setFcDescription(data.data.course.fcDescription);
        setFcCoursePrice(data.data.course.fcCoursePrice);
        setCourseName(data.data.course.courseName);
        setCommencement(data.data.course.commencement);
        setEntryRequirements(data.data.course.entryRequirements);
        setCourseDescription(data.data.course.courseDescription);
        setCoursePrice(data.data.course.coursePrice);
        setDuration(data.data.course.courseDuration);
        setCover(data.data.course.courseCoverPhoto);
        setFormatDropDownVal({
          label: data.data.course.courseType,
          value: data.data.course.courseType,
        });
        setEntryLevelDropDownVal({
          label: data.data.course.courseEntryLevel,
          value: data.data.course.courseEntryLevel,
        });
        setQualificationDropDownVal({
          label: data.data.course.courseQualification,
          value: data.data.course.courseQualification,
        });
        setCategory({
          label: data.data.course?.category || 'not available',
          value: data.data.course?.category || 'not available',
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleChangeLocal = (event) => {
    setLocal(event.target.checked);
  };

  const handleChangeForeign = (event) => {
    setForeign(event.target.checked);
  };

  return (
    <c.Container>
      <c.HeaderContainer>
        <c.HeaderTitle>Update Course</c.HeaderTitle>
      </c.HeaderContainer>

      {isPending && (
        <ClipLoader color="#333" size={60} cssOverride={override} />
      )}
      {true && (
        <c.FormContainer onSubmit={updateCourse}>
          <c.Cover>
            <c.CoverSection>
              {cover ? (
                <c.CoverImage
                  img={
                    typeof cover === 'object'
                      ? URL.createObjectURL(cover)
                      : cover
                  }
                />
              ) : (
                <p></p>
              )}
            </c.CoverSection>
            {appear ? (
              <>
                <c.CoverIcon>
                  <FaCamera />
                  <input
                    type="file"
                    id="file1"
                    accept="image/*"
                    onChange={handleCover}
                  />
                </c.CoverIcon>
                <c.Text>
                  <p>Course Cover Upload</p>
                </c.Text>
              </>
            ) : null}
          </c.Cover>
          <c.Fields>
            {/* <c.PText>
              <Checkbox
                size="large"
                checked={local}
                onChange={(event) => handleChangeLocal(event)}
              />
              Is this Course Locally Available?
            </c.PText> */}
            <DashboardTextField
              disabled={appear ? false : true}
              value={courseName}
              setValue={setCourseName}
              placeholder={'Course'}
            />

            <TextBox
              disabled={appear ? false : true}
              value={courseDescription}
              setValue={setCourseDescription}
              placeholder={'Course Description'}
            />
            <TextBox
              disabled={appear ? false : true}
              value={entryRequirements}
              setValue={setEntryRequirements}
              placeholder={
                'Enter course entry requirements as below comma seperated values\n  \nEx:\nRequirement 1,\nRequirement 2'
              }
            />
            <DashboardTextField
              disabled={appear ? false : true}
              value={commencement}
              setValue={setCommencement}
              placeholder={'Commencement'}
            />

            {appear ? (
              <DashboardDropdown
                // Placeholder={'Select course qualification'}
                dropDownValues={Categories}
                currentDropdownVal={category}
                setCurrentDropdownVal={setCategory}
              />
            ) : (
              <DashboardTextField disabled={true} placeholder={'Category'} />
            )}
            {appear ? (
              <DashboardDropdown
                // Placeholder={'Select course qualification'}
                dropDownValues={qualifications}
                currentDropdownVal={qualificationDropDownVal}
                setCurrentDropdownVal={setQualificationDropDownVal}
              />
            ) : (
              <DashboardTextField
                disabled={true}
                placeholder={'Qualification'}
              />
            )}

            {appear ? (
              <DashboardDropdown
                // Placeholder={courseEntryLevel}
                dropDownValues={entryLevels}
                currentDropdownVal={entryLevelDropDownVal}
                setCurrentDropdownVal={setEntryLevelDropDownVal}
              />
            ) : (
              <DashboardTextField disabled={true} placeholder={'Entry Level'} />
            )}
            {appear ? (
              <DashboardDropdown
                // Placeholder={courseType}
                dropDownValues={formats}
                currentDropdownVal={formatDropDownVal}
                setCurrentDropdownVal={setFormatDropDownVal}
              />
            ) : (
              <DashboardTextField disabled={true} placeholder={'Type'} />
            )}
            {local ? (
              <DashboardTextField
                disabled={appear ? false : true}
                value={coursePrice}
                setValue={setCoursePrice}
                placeholder={'Course Fee'}
              />
            ) : (
              <c.PText></c.PText>
            )}
            <DashboardTextField
              disabled={appear ? false : true}
              value={courseDuration}
              setValue={setDuration}
              placeholder={'Course Duration'}
            />
            <c.Text>Foreign Course Details</c.Text>

            <c.PText>
              <Checkbox
                size="large"
                checked={foreign}
                onChange={(event) => handleChangeForeign(event)}
              />
              Study abroad availability
            </c.PText>
            {foreign ? (
              <>
                <TextBox
                  value={fcDescription}
                  setValue={setFcDescription}
                  placeholder={'Foreign Course Description'}
                />
                <DashboardDropdown
                  dropDownValues={fcCountries}
                  currentDropdownVal={fcCountry}
                  setCurrentDropdownVal={setFcCountry}
                />
                <DashboardTextField
                  value={fcCoursePrice}
                  setValue={setFcCoursePrice}
                  placeholder={'Enter Foreign Course Fee in USD. \tEX: 180000'}
                />
              </>
            ) : (
              <c.PText></c.PText>
            )}
          </c.Fields>
          <c.ButtonSection>
            {/* <c.Button>
              <RegularButton
                fontweight={'bold'}
                backgroundcolor={'var(--fourth-blue)'}
                textcolor={'white'}
                btntext={'Edit'}
                onClick={handleEdit}
              />
            </c.Button> */}
            <c.Button>
              <RegularButton
                type="submit"
                fontweight={'bold'}
                backgroundcolor={'var(--fourth-blue)'}
                textcolor={'white'}
                btntext={'Update'}
              />
            </c.Button>
          </c.ButtonSection>
        </c.FormContainer>
      )}
    </c.Container>
  );
};

export default SingleCourse;
