import React from 'react'
import * as c from './CoursesElements';

const Courses = () => {
  return (
    <c.Container>
           <c.HeaderContainer>
        
        <c.HeaderTitle>Courses</c.HeaderTitle>



      </c.HeaderContainer>
      </c.Container>
  )
}

export default Courses