import React from 'react';
import * as h from './HomeCoverElements';
// import { GoSearch } from 'react-icons/go';
// import { MdLocationPin } from 'react-icons/md';
import user1 from './../../images/home_users/1.webp';
import user2 from './../../images/home_users/2.webp';
// import { BiWorld, BiPalette, BiLineChart, BiSolidPlane } from 'react-icons/bi';
import {
  FaPlane,
  FaUniversity,
  FaGraduationCap,
  FaUserGraduate,
} from 'react-icons/fa';
// import { MdEventAvailable } from 'react-icons/md';

const HomeCover = () => {
  const responsiveSizesText = [
    {
      breakpoint: 1500,
      fontsize: '6rem',
      lineSpacing: '4rem',
      margin: '6rem 0 0 2rem',
      backgroundColor: 'red',
    },
    { breakpoint: 1400, fontsize: '5.2rem', lineSpacing: '2rem' },
    { breakpoint: 1300, fontsize: '4.7rem', lineSpacing: '2rem' },
    { breakpoint: 1200, fontsize: '4.5rem' },
    { breakpoint: 1120, fontsize: '4rem', lineheight: '0.5rem' },
    { breakpoint: 1100, fontsize: '3.8rem' },
    { breakpoint: 950, display: 'block', lineheight: '0.5rem' },
    { breakpoint: 800, fontsize: '3.3rem' },
    { breakpoint: 700, fontsize: '3rem' },
    {
      breakpoint: 426,
      fontsize: '2.8rem',
      lineheight: '0.2rem',
      margin: '2rem 0',
    },
    { breakpoint: 360, fontsize: '2.5rem', lineheight: '0.1rem' },
    { breakpoint: 330, fontsize: '2.3rem', lineheight: '0.1rem' },
  ];

  const specialRes = [
    { breakpoint: 950, margin: '5rem 0 0' },
    { breakpoint: 650, display: 'inline', margin: '5rem 0 0 1rem' },
    { breakpoint: 426, display: 'block', margin: '4rem 0 0 0rem' },
  ];

  return (
    <>
      <h.Section>
        <h.Container>
          <h.CoverLeftContainer>
            <h.CoverTitleContainer>
              {/* <h.Text
                responsiveSizes={responsiveSizesText}
                fontsize="5rem"
                fontweight="600"
                color="#222"
                fontfamily="var(--secondary-font)"
              >
                THE BEST WAY TO
              </h.Text>
              <h.Text
                responsiveSizes={responsiveSizesText}
                margin="5rem 0 0"
                fontsize="5rem"
                fontweight="600"
                color="#222"
                fontfamily="var(--secondary-font)"
              >
                FIND YOUR
                <h.Text
                  specialRes={specialRes}
                  responsiveSizes={responsiveSizesText}
                  margin="0 0 0 3rem"
                  fontsize="5rem"
                  display="inline"
                  fontweight="600"
                  color="var(--secondary-blue)"
                  fontfamily="var(--secondary-font)"
                >
                  FUTURE!
                </h.Text>
              </h.Text> */}
              <h.HomeTitle>
                Your Dream Career is <h.HighlightSpan>Just</h.HighlightSpan>{' '}
              </h.HomeTitle>
              <h.HomeTitle>
                {' '}
                <h.HighlightSpan>a Click </h.HighlightSpan>Away!
              </h.HomeTitle>
              {/* <h.HomeTitle>
                Unlock your path to success with Sri Lanka's premier career
                pathway finder platform. Explore a vast array of courses and
                opportunities to chart your course towards a brighter future.
              </h.HomeTitle> */}
            </h.CoverTitleContainer>

            {/* <h.CoverSearchBarContainer>
              <h.SearchInput placeholder="What to study? / Where to Study?" />
              <h.SearchBtn>
                <GoSearch />
              </h.SearchBtn>
            </h.CoverSearchBarContainer> */}

            {/* <h.LocationBtn>
              <h.IconContainer fontsize="2rem" color="#fff">
                <MdLocationPin />
              </h.IconContainer>
              <h.Text
                fontsize="1.2rem"
                fontweight="600"
                margin="0 0 0 1rem"
                color="var(--white)"
              >
                All of Sri Lanka
              </h.Text>
            </h.LocationBtn> */}
            <h.ButtonRow>
              <h.LinkButtonContainer to="/courses">
                <h.IconContainer fontsize="2.5rem">
                  <FaGraduationCap />
                </h.IconContainer>
                <h.Text
                  responsiveSizesText={responsiveSizesText}
                  margin="0 0 0 1rem"
                  fontsize="1.3rem"
                  fontweight="600"
                >
                  Courses
                </h.Text>
              </h.LinkButtonContainer>
              <h.LinkButtonContainer to="/study-abroad">
                <h.IconContainer fontsize="2.5rem">
                  <FaPlane />
                </h.IconContainer>
                <h.Text
                  responsiveSizesText={responsiveSizesText}
                  margin="0 0 0 1rem"
                  fontsize="1.3rem"
                  fontweight="600"
                >
                  Study Abroad
                </h.Text>
              </h.LinkButtonContainer>
              <h.LinkButtonContainer to="/universities">
                <h.IconContainer fontsize="2.5rem">
                  <FaUniversity />
                </h.IconContainer>
                <h.Text
                  responsiveSizesText={responsiveSizesText}
                  margin="0 0 0 1rem"
                  fontsize="1.3rem"
                  fontweight="600"
                >
                  Universities
                </h.Text>
              </h.LinkButtonContainer>
              <h.LinkButtonContainer to="/scholarships" selected={false}>
                <h.IconContainer fontsize="2.5rem">
                  <FaUserGraduate />
                </h.IconContainer>
                <h.Text
                  responsiveSizesText={responsiveSizesText}
                  margin="0 0 0 1rem"
                  fontsize="1.3rem"
                  fontweight="600"
                >
                  Scholarships
                </h.Text>
              </h.LinkButtonContainer>
            </h.ButtonRow>
          </h.CoverLeftContainer>

          <h.CoverRightContainer>
            <h.FadingCircle />
            <h.UserOneContainer img={user1} />
            <h.UserTwoContainer img={user2} />
          </h.CoverRightContainer>
        </h.Container>
      </h.Section>
    </>
  );
};

export default HomeCover;
