import styled from 'styled-components';

export const Container = styled.div`
  ${'' /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  ${'' /* background-color:red ; */}
  width:100%;
  display: flex;
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0 auto 0 0;
`;

export const FormContainer = styled.form`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 5% 0;
`;
export const Fields = styled.div``;
export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.div`
  width: 20%;
  margin: 5%;
`;
export const Cover = styled.div`
  width: 100%;
  height: 200px;
  margin: 2% 0 8% 0;
  border-radius: 20px;
  background-color: var(--ternary-blue);
`;
export const CoverSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const CoverIcon = styled.label`
  & {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: var(--main-blue);
    display: flex;
    margin-bottom: 1%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: relative;
    top: -15%;
    left: 0%;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Text = styled.div`
  display: flex;
  position: relative;
  top: -15%;
  font-size: 16px;
  font-weight: 600;
`;

export const PText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--form-input-text-color);
  font-family: var(--dashboard-font);
`;
