import React, { useState } from 'react';
import * as b from './AdminBannersCompElements';
import { Box, Paper, useTheme } from '@mui/material';
import { toast } from 'react-hot-toast';
import axios from 'axios';

const AdminBannersComp = ({ banner_1, banner_2, banner_3 }) => {
  const [banner1, setBanner1] = useState(`${banner_1}`);
  const [banner2, setBanner2] = useState(`${banner_2}`);
  const [banner3, setBanner3] = useState(`${banner_3}`);

  const theme = useTheme();
  const FileUploader = async (e, formData) => {
    console.log({ e, formData });
    try {
      const res = await axios({
        method: 'POST',
        url: '/api/v1/banners',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      // console.log(err.response.data);
    }
  };

  const handleFileInput1 = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setBanner1(reader.result);
      };
      reader.readAsDataURL(file);

      // console.log({ file });

      const formData = new FormData();

      formData.append('banner', file);

      toast.promise(
        FileUploader(e, formData),
        {
          loading: 'Uploading banner ...',
          success: (data) => `Banner uploaded successfully `,
          error: (err) => {
            if (!err.response.data.message) {
              return 'Something went wrong. Try again.';
            }
            return `${err?.response?.data?.message?.toString()}`;
          },
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    }
  };

  const handleFileInput2 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBanner2(reader.result);
    };
    reader.readAsDataURL(file);

    // console.log({ file2: file });
    const formData = new FormData();

    formData.append('banner', file);

    toast.promise(
      FileUploader(e, formData),
      {
        loading: 'Uploading banner ...',
        success: (data) => `Banner uploaded successfully `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  const handleFileInput3 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBanner3(reader.result);
    };
    reader.readAsDataURL(file);

    const formData = new FormData();

    formData.append('banner', file);

    toast.promise(
      FileUploader(e, formData),
      {
        loading: 'Uploading banner ...',
        success: (data) => `Banner uploaded successfully `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  const handleRemoveFile1 = (e) => {
    setBanner1(null);
  };
  const handleRemoveFile2 = (e) => {
    setBanner2(null);
  };
  const handleRemoveFile3 = (e) => {
    setBanner3(null);
  };

  return (
    <b.Container>
      <b.HeaderContainer>
        <b.HeaderTitle>Banners</b.HeaderTitle>
      </b.HeaderContainer>

      <b.CardContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            margin: '2rem',
            marginLeft: '10rem',
            [theme.breakpoints.down('sm')]: {
              marginLeft: '0',
            },
          }}
        >
          <Box
            sx={{
              width: '60%',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}
          >
            <Paper
              sx={{
                backgroundImage: `url(${banner1})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                p: 2,
                height: '10rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'right',
                }}
              >
                <b.Label htmlFor="file-upload-1">
                  Upload
                  <input
                    id="file-upload-1"
                    type="file"
                    onChange={handleFileInput1}
                  />
                </b.Label>
                {/* <Button
                  id="file-upload-1"
                  size="medium"
                  color="warning"
                  variant="contained"
                  onClick={handleRemoveFile1}
                >
                  Remove
                </Button> */}
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            margin: '2rem',
            marginLeft: '10rem',
            [theme.breakpoints.down('sm')]: {
              marginLeft: '0',
            },
          }}
        >
          <Box
            sx={{
              width: '60%',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}
          >
            <Paper
              sx={{
                backgroundImage: `url(${banner2})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                p: 2,
                height: '10rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'right',
                }}
              >
                <b.Label htmlFor="file-upload-2">
                  Upload
                  <input
                    id="file-upload-2"
                    type="file"
                    onChange={handleFileInput2}
                  />
                </b.Label>
                {/* <Button
                  id="file-upload-2"
                  size="medium"
                  color="warning"
                  variant="contained"
                  onClick={handleRemoveFile2}
                >
                  Remove
                </Button> */}
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            margin: '2rem',
            marginLeft: '10rem',
            [theme.breakpoints.down('sm')]: {
              marginLeft: '0',
            },
          }}
        >
          <Box
            sx={{
              width: '60%',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}
          >
            <Paper
              sx={{
                backgroundImage: `url(${banner3})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                p: 2,
                height: '10rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'right',
                }}
              >
                <b.Label htmlFor="file-upload-3">
                  Upload
                  <input
                    id="file-upload-3"
                    type="file"
                    onChange={handleFileInput3}
                  />
                </b.Label>
                {/* <Button
                  id="file-upload-3"
                  size="medium"
                  color="warning"
                  variant="contained"
                  onClick={handleRemoveFile3}
                >
                  Remove
                </Button> */}
              </Box>
            </Paper>
          </Box>
        </Box>
      </b.CardContainer>
    </b.Container>
  );
};

export default AdminBannersComp;
