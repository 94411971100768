import React, { useState } from 'react';
import * as c from './CourseInsightsElements.js';
// import { BiSearch } from 'react-icons/bi';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-hot-toast';
import submitForm from './../../../hooks/submitForm.js';

const CourseInsights = ({ coursesInsights, university }) => {
  //const [checked, setChecked] = useState(false);

  // const handleChange = (event, courseInsight) => {
  //   setChecked(event.target.checked);
  //   updateChecked(courseInsight, event.target.checked);
  // };

  // const updateChecked = async (courseInsight, ticked) => {
  //   await toast.promise(
  //     submitForm(
  //       `/api/v1/insights/course/${
  //         courseInsight._id
  //       }`,
  //       {ticked},
  //       "patch",
  //       {}
  //     ),
  //     {
  //       loading: "Updating Lead...",
  //       success: (data) => {
  //         console.log({ data });
  //         window.location.reload(false);
  //         return ` ${data.data?.message} ` || "success";
  //       },
  //       error: (err) => `${err.response.data.message}`,
  //     },
  //     {
  //       style: {
  //         borderRadius: "10px",
  //         background: "#333",
  //         color: "#fff",
  //         fontSize: "2rem",
  //       },
  //     }
  //   );
  // };

  const [status, setStatus] = useState({});

  const handleChange = (event, ci) => {
    setStatus(event.target.value);
    updateCourseLeadStatus(ci, event.target.value);
  };
  const updateCourseLeadStatus = async (ci, status) => {
    // console.log(ci);
    await toast.promise(
      submitForm(
        `/api/v1/insights/course/${ci._id}`, // Change 'ci.id' to 'ci._id'
        { status },
        'patch',
        {}
      ),
      {
        loading: 'Updating Course Status...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return `${data.data?.message}` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  return (
    <>
      {university.packages === 'standard' ||
      university.packages === 'premium' ? (
        <c.Container>
          <c.HeaderContainer>
            <c.HeaderTitle>
              Courses Leads ({coursesInsights.length})
            </c.HeaderTitle>

            {/* <c.SearchBarContainer>
              <c.SearchBar placeholder="Enter course name" />
              <c.SearchIconContainer>
                {' '}
                <BiSearch />{' '}
              </c.SearchIconContainer>
            </c.SearchBarContainer> */}
          </c.HeaderContainer>

          <c.TableContainer>
            <c.Table>
              <tbody>
                <c.TableHeader>
                  <c.TableDataCell th={true}>#</c.TableDataCell>
                  <c.TableDataCell th={true}>Date</c.TableDataCell>
                  <c.TableDataCell th={true}>Course</c.TableDataCell>
                  <c.TableDataCell th={true}>Name</c.TableDataCell>
                  <c.TableDataCell th={true}>Email</c.TableDataCell>
                  <c.TableDataCell th={true}>Mobile</c.TableDataCell>
                  <c.TableDataCell th={true}>Status</c.TableDataCell>
                </c.TableHeader>

                {coursesInsights.map((ci, i) => {
                  return (
                    <c.TableRow key={i}>
                      <c.TableDataCell>{i + 1} </c.TableDataCell>
                      <c.TableDataCell>
                        {ci.createdAt.split('T')[0]}
                        {''}
                        {/* <Checkbox
                          size="large"
                          checked={coursesInsights.ticked}
                          onChange={(event) => handleChange(event, coursesInsights)}
                        />{" "} */}
                      </c.TableDataCell>

                      <c.TableDataCell>
                        {' '}
                        {ci.course?.courseName}{' '}
                      </c.TableDataCell>
                      <c.TableDataCell className="flex">
                        {ci.personName}
                      </c.TableDataCell>
                      <c.TableDataCell minwidth="15rem">
                        {ci?.personEmail}{' '}
                      </c.TableDataCell>
                      <c.TableDataCell minwidth="15rem">
                        {ci?.personMobile}{' '}
                      </c.TableDataCell>
                      <c.TableDataCell minwidth="10rem">
                        <FormControl>
                          <Select
                            name="package"
                            sx={{
                              m: 1,
                              minWidth: 120,
                            }}
                            size="small"
                            value={ci.status}
                            onChange={(event) => handleChange(event, ci)}
                          >
                            {['Not Contacted', 'Qualified', 'Rejected'].map(
                              (status) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </c.TableDataCell>
                    </c.TableRow>
                  );
                })}
              </tbody>
            </c.Table>
          </c.TableContainer>

          <c.TablePaginationContainer>
            {/* <Pagination
          // color="secondary"
          size="large"
          count={5} defaultPage={6} siblingCount={0} /> */}
          </c.TablePaginationContainer>
        </c.Container>
      ) : (
        <>
          <c.Container>
            <c.HeaderContainer>
              <c.HeaderTitle>
                Courses Leads ({coursesInsights?.length})
              </c.HeaderTitle>
            </c.HeaderContainer>
          </c.Container>
        </>
      )}
    </>
  );
};

export default CourseInsights;
