import {
  FiHome,
  FiSettings,
  FiEye,
  FiBookOpen,
  FiBarChart2,
  FiAward,
} from 'react-icons/fi';

const links = [
  {
    icon: <FiHome />,
    linkText: 'Home',
    url: '/uni/home',
  },
  {
    icon: <FiEye />,
    linkText: 'Public Page',
    url: '/uni/public-profile',
  },
  // {
  //   icon: <FiActivity />,
  //   linkText: 'Insights',
  //   url: '/uni/insights',
  // },

  // {
  //   icon: <FiTv />,
  //   linkText: 'Events',
  //   url: '/uni/events',
  // },
  {
    icon: <FiBookOpen />,
    linkText: 'Courses',
    url: '/uni/courses',
  },
  {
    icon: <FiAward />,
    linkText: 'Scholarships',
    url: '/uni/scholarships',
  },
  {
    icon: <FiBarChart2 />,
    linkText: 'Courses Leads',
    url: '/uni/course-insights',
  },
  {
    icon: <FiBarChart2 />,
    linkText: 'Scholarships Leads',
    url: '/uni/scholarships-Insights',
  },
  {
    icon: <FiSettings />,
    linkText: 'Profile',
    url: '/uni/admin/profile',
  },
];

export { links as uniLinks };
