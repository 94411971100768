import React from 'react'
import AdminLoginForm from '../components/admin/LoginPortal/AdminLogin'

const StaffLogin = () => {
  return (
      <>
          <AdminLoginForm/>
    </>
  )
}

export default StaffLogin