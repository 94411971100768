import React from 'react'

const AdminEvents = () => {
  return (
      <>
          <h1>Welcome Admin</h1>
          <p>This is your Events page 💥</p>
    </>
  )
}

export { AdminEvents as AdminEventsComponent}