import React, { useState } from 'react';
import * as u from './AdminUniElements';
// import { BiSearch } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import { toast } from 'react-hot-toast';
import ToggleSwitch from '../shared/dashboard-form-elements/ToggleSwitch';
import submitForm from '../../hooks/submitForm';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const AdminUni = ({ uniList, setUrl }) => {
  const [packages, setPackages] = useState({});

  const handleChange = (event, uni) => {
    setPackages(event.target.value);
    updateUniPackage(uni, event.target.value);
  };

  const updateUniVisibility = async (uni) => {
    await toast.promise(
      submitForm(
        `/api/v1/universities/${uni.active ? 'deactivate' : 'activate'}/${
          uni._id
        }`,
        {},
        'patch',
        {}
      ),
      {
        loading: 'Updating University...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  const updateUniPackage = async (uni, packages) => {
    await toast.promise(
      submitForm(
        `/api/v1/universities/updatePackage/${uni._id}`,
        { packages },
        'patch',
        {}
      ),
      {
        loading: 'Updating University Package...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>Universities</u.HeaderTitle>

        {/* <u.SearchBarContainer>
          <u.SearchBar placeholder="Enter name or Email" />
          <u.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </u.SearchIconContainer>
        </u.SearchBarContainer> */}

        <u.AddUniversityBtn to={'/admin/universities/add'}>
          <u.AddUniIcon>
            {' '}
            <FiPlusCircle />{' '}
          </u.AddUniIcon>{' '}
          Add University
        </u.AddUniversityBtn>
      </u.HeaderContainer>

      <u.TableContainer>
        <u.Table>
          <u.TableHeader>
            <u.TableDataCell th={true}>#</u.TableDataCell>
            <u.TableDataCell th={true}>Name</u.TableDataCell>
            <u.TableDataCell th={true}>Email</u.TableDataCell>
            <u.TableDataCell th={true}>Profile Status</u.TableDataCell>
            <u.TableDataCell th={true}>Package</u.TableDataCell>
            <u.TableDataCell th={true}>Date Registered</u.TableDataCell>
          </u.TableHeader>

          {uniList.map((uni, i) => {
            return (
              <u.TableRow key={i}>
                <u.TableDataCell>{i + 1}</u.TableDataCell>
                <u.TableDataCell>{uni.name}</u.TableDataCell>
                <u.TableDataCell minwidth="10rem">
                  {uni?.universityClerk?.email}
                </u.TableDataCell>
                <u.TableDataCell className="flex">
                  <ToggleSwitch
                    checked={uni.active}
                    handleChange={() => {
                      updateUniVisibility(uni);
                    }}
                  />
                  {/* <u.ActiveLabel active={uni.active} /> */}
                  {uni.active ? 'Active' : 'Deactive'}
                </u.TableDataCell>
                <u.TableDataCell minwidth="10rem">
                  <FormControl>
                    <Select
                      name="package"
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                      value={uni.packages}
                      onChange={(event) => handleChange(event, uni)}
                    >
                      {['free', 'standard', 'premium'].map((packagesa) => (
                        <MenuItem key={packagesa} value={packagesa}>
                          {packagesa}
                        </MenuItem>
                      ))}
                      This will dynamically create a MenuItem component for each
                      package in the array, with the value set to the package
                      name.
                    </Select>
                  </FormControl>
                </u.TableDataCell>
                <u.TableDataCell minwidth="10rem">
                  {uni.createdAt.split('T')[0]}
                </u.TableDataCell>
              </u.TableRow>
            );
          })}
        </u.Table>
      </u.TableContainer>

      <u.TablePaginationContainer>
        <Pagination
          // color="secondary"
          size="large"
          count={5}
          defaultPage={1}
          siblingCount={0}
        />
      </u.TablePaginationContainer>
    </u.Container>
  );
};

export { AdminUni as AdminUniComponent };
