import React, { useEffect, useState } from 'react';
import * as c from './CoursesFilterElements';
import { GoSearch } from 'react-icons/go';
// import { TbCertificate } from 'react-icons/tb';
// import DropDown from './../../../components/shared/Form Elements/DropDown'
import DropDown from '../shared/Form Elements/DropDown';
import Pagination from '@mui/material/Pagination';
// import RegularButton from '../shared/Form Elements/RegularButton';
import ClipLoader from 'react-spinners/ClipLoader';
import useFetch from './../../hooks/useFeth';
import AutoCompleteDropdown from '../shared/Form Elements/AutoCompleteDropdown';
import NoRecordFound from '../shared/NoRecordFound';
import CheckBox from '../shared/Form Elements/CheckBox';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { VscSettings, VscChromeClose } from 'react-icons/vsc';

import ad1 from './../../images/default_banners/5.webp';
import ad2 from './../../images/default_banners/6.webp';

// courseQualification=Masters&courseType=Part-Time
const CoursesFilter = ({
  uniList,
  defaultUni,
  disable,
  studyAbroad,
  setPopup,
  setUniversity,
  setCourse,
  isAgency,
}) => {
  const [keyWord, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [category, setCategory] = useState([]);
  const [local, setLocal] = useState(true);
  const [foreign, setForeign] = useState(false);
  let [search, setSearch] = useState(0);
  const [searchPending, setSearchPending] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchedCourses, setSearchedCourses] = useState([]);

  const [checkboxState, setCheckboxState] = useState({
    Accounting: false,
    'Architecture & Design': false,
    'Arts & Humanities': false,
    Aviation: false,
    'Beauty & Hair': false,
    'Business & Management': false,
    'Computing & IT': false,
    Construction: false,
    Engineering: false,
    'Event Management & Tourism': false,
    'Food Science & Agriculture': false,
    Finance: false,
    'Hotel & Hospitality': false,
    'Journalism & Media Studies': false,
    Languages: false,
    'Law & Administration': false,
    Marketing: false,
    'Medicine and Health': false,
    'Military Sciences': false,
    Marine: false,
    'Professional Certifications': false,
    'Physical Science': false,
    'Social Science': false,
    'Soft Skill Development': false,
    'Sports & Coaching': false,
  });

  const [formatDropDownVal, setFormatDropDownVal] = useState({
    label: 'Format',
    value: '',
  });

  const [qualificationDropDownVal, setQualificationDropDownVal] = useState({
    label: 'Qualification',
    value: '',
  });
  const [entryLevelDropDownVal, setEntryLevelDropDownVal] = useState({
    label: 'Entry level',
    value: '',
  });
  const [uniDropDownVal, setUniDropDownVal] = useState({
    label: defaultUni.label || 'University',
    value: defaultUni.value || '',
  });

  // /api/v1/courses/getAgencyCourses

  let url = `/api/v1/courses${
    isAgency ? 'getAgencyCourses' : ''
  }?active=true&limit=${limit}&page=${page}${
    formatDropDownVal.value ? `&courseType=${formatDropDownVal.value}` : ''
  }${
    qualificationDropDownVal.value
      ? `&courseQualification=${qualificationDropDownVal.value}`
      : ''
  }${
    entryLevelDropDownVal.value
      ? `&courseEntryLevel=${entryLevelDropDownVal.value}`
      : ''
  }${
    uniDropDownVal.value ? `&university=${uniDropDownVal.value}` : ''
  }&local=${local}${foreign ? `&foreign=${foreign}` : ``}${
    category.length > 0 ? `&category=${category}` : ''
  }`;

  const { data, isError, isPending } = useFetch(url);
  // console.log({ data });

  const formats = [
    { label: 'Full Time', value: 'Full-Time' },
    { label: 'Part Time', value: 'Part-Time' },
  ];

  const qualifications = [
    { label: 'Foundation', value: 'Foundation' },
    { label: 'Certificate', value: 'Certificate' },
    { label: 'Diploma', value: 'Diploma' },
    { label: 'Top-Up', value: 'Top-Up' },
    { label: 'Bachelor', value: 'Bachelor' },
    { label: 'Masters', value: 'Masters' },
    { label: 'Phd', value: 'Phd' },
  ];
  const entryLevels = [
    { label: 'Certificate', value: 'Certificate' },
    { label: 'GCE-O/L', value: 'GCE-O/L' },
    { label: 'GCE-A/L', value: 'GCE-A/L' },
    { label: 'Edexcel', value: 'Edexcel' },
    { label: 'Cambridge', value: 'Cambridge' },
    { label: 'HND', value: 'HND' },
    { label: 'BSc', value: 'BSc' },
    { label: 'PGD', value: 'PGD' },
    { label: 'Masters', value: 'Masters' },
  ];

  const override = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
    // margin: '0 auto',
    // top: '50%',
    // left: '0%',
    // transform: 'translate(-50%,-50%)',
  };

  let courses = [];

  if (data) {
    courses = data.data.courses.filter(
      (course) =>
        course.courseName.toLowerCase().includes('') ||
        course.courseDescription.toLowerCase().includes('')
    );

    // console.log({ courses });
  }
  useEffect(() => {
    if (search) {
      setSearchPending(true);
      // Make a GET request using fetch
      fetch(`api/v1/courses/search/${keyWord}`)
        .then((response) => {
          // Check if the request was successful (status code 200)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the JSON response
          return response.json();
        })
        .then((data) => {
          // Handle the data returned from the server
          // console.log({ data });
          setSearchedCourses(data.courses);
          setSearchPending(false);
        })
        .catch((error) => {
          // Handle errors that may occur during the fetch
          console.error('Fetch error:', error);
          setSearchPending(false);
        });
    }
  }, [search]);

  const handleCheckboxChange = (event) => {
    let { id, checked } = event.target;
    setCheckboxState({
      ...checkboxState,
      [id]: checked,
    });
  };

  useEffect(() => {
    let categories = Object.keys(checkboxState).filter(
      (category) => checkboxState[category]
    );
    // console.log({ categories });

    categories.forEach((str, index, arr) => {
      arr[index] = str.replace(/&/g, '%26');
    });
    // console.log({ categories });
    setCategory(categories);
  }, [checkboxState]);

  const [filters, setFilters] = useState(false);

  return (
    <c.Section>
      <c.Container>
        {filters && (
          <c.FilterPopupContainer>
            <c.FilterPopupCloseBtn onClick={(e) => setFilters(false)}>
              <AiOutlineCloseCircle />
            </c.FilterPopupCloseBtn>
            <c.LeftTitle>Select University</c.LeftTitle>

            <c.DropDownContainer>
              <DropDown
                dropDownValues={formats}
                currentDropdownVal={formatDropDownVal}
                setCurrentDropdownVal={setFormatDropDownVal}
              />
            </c.DropDownContainer>
            <c.LeftTitle>Select Qualification </c.LeftTitle>

            <c.DropDownContainer>
              <DropDown
                dropDownValues={qualifications}
                currentDropdownVal={qualificationDropDownVal}
                setCurrentDropdownVal={setQualificationDropDownVal}
              />
            </c.DropDownContainer>
            <c.LeftTitle>Select Entry Level </c.LeftTitle>

            <c.DropDownContainer>
              <DropDown
                dropDownValues={entryLevels}
                currentDropdownVal={entryLevelDropDownVal}
                setCurrentDropdownVal={setEntryLevelDropDownVal}
              />
            </c.DropDownContainer>
            <c.LeftTitle>Select University </c.LeftTitle>

            <c.LeftDrodownContainer>
              {
                <AutoCompleteDropdown
                  dropDownValues={uniList}
                  currentDropdownVal={uniDropDownVal}
                  setCurrentDropdownVal={setUniDropDownVal}
                  disable={disable}
                />
              }
            </c.LeftDrodownContainer>

            {!isAgency && (
              <>
                <c.LeftTitle>Course Locality </c.LeftTitle>
                <c.CheckBoxesContainer>
                  {/* <CheckBox
                labelTxt={'Local'}
                checked={local}
                onChange={() => setLocal(!local)}
              /> */}
                  <CheckBox
                    labelTxt={'Hybrid'}
                    checked={foreign}
                    onChange={() => setForeign(!foreign)}
                  />
                </c.CheckBoxesContainer>
              </>
            )}

            <c.LeftTitle>Course Categories </c.LeftTitle>

            <c.CheckBoxesContainer>
              <CheckBox
                labelTxt={'Accounting'}
                checked={checkboxState['Accounting']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Architecture & Design'}
                checked={checkboxState['Architecture & Design']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Arts & Humanities'}
                checked={checkboxState['Arts & Humanities']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Aviation'}
                checked={checkboxState.Aviation}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Beauty & Hair'}
                checked={checkboxState['Beauty & Hair']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Business & Management'}
                checked={checkboxState['Business & Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Computing & IT'}
                checked={checkboxState['Computing & IT']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Construction'}
                checked={checkboxState.Construction}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Engineering'}
                checked={checkboxState.Engineering}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Event Management & Tourism'}
                checked={checkboxState['Event Management & Tourism']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Food Science & Agriculture'}
                checked={checkboxState['Food Science & Agriculture']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Finance'}
                checked={checkboxState.Finance}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Hotel & Hospitality'}
                checked={checkboxState['Hotel & Hospitality']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Journalism & Media Studies'}
                checked={checkboxState['Journalism & Media Studies']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Languages'}
                checked={checkboxState.Languages}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Law & Administration'}
                checked={checkboxState['Law & Administration']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Logistics & Supply chain Management'}
                checked={checkboxState['Logistics & Supply chain Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marketing'}
                checked={checkboxState.Marketing}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Medicine and Health'}
                checked={checkboxState['Medicine and Health']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Military Sciences'}
                checked={checkboxState['Military Sciences']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marine'}
                checked={checkboxState.Marine}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Professional Certifications'}
                checked={checkboxState['Professional Certifications']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Physical Science'}
                checked={checkboxState['Physical Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Social Science'}
                checked={checkboxState['Social Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Soft Skill Development'}
                checked={checkboxState['Soft Skill Development']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Sports & Coaching'}
                checked={checkboxState['Sports & Coaching']}
                onChange={handleCheckboxChange}
              />
            </c.CheckBoxesContainer>

            <c.LeftDrodownContainer>
              <c.ClearFiltersBtn
                onClick={() => {
                  if (Object.keys(defaultUni).length === 0) {
                    setUniDropDownVal({
                      label: 'University',
                      value: '',
                    });
                  }
                  setFormatDropDownVal({
                    label: 'Format',
                    value: '',
                  });
                  setQualificationDropDownVal({
                    label: 'Qualification',
                    value: '',
                  });
                  setEntryLevelDropDownVal({
                    label: 'Entry level',
                    value: '',
                  });

                  setCheckboxState({
                    'Arts & Humanities': false,
                    Aviation: false,
                    'Beauty & Hair': false,
                    'Business & Management': false,
                    'Computing & IT': false,
                    Construction: false,
                    Engineering: false,
                    'Event Management & Tourism': false,
                    'Food Science & Agriculture': false,
                    Finance: false,
                    'Hotel & Hospitality': false,
                    'Journalism & Media Studies': false,
                    Languages: false,
                    'Law & Administration': false,
                    Marketing: false,
                    'Medicine and Health': false,
                    'Military Sciences': false,
                    Marine: false,
                    'Professional Certifications': false,
                    'Physical Science': false,
                    'Social Science': false,
                    'Soft Skill Development': false,
                    'Sports & Coaching': false,
                  });
                }}
              >
                Clear Filters
              </c.ClearFiltersBtn>
            </c.LeftDrodownContainer>
          </c.FilterPopupContainer>
        )}

        <c.ContainerTop>
          <c.ContainerTopSearch>
            <c.SearchBarContainer>
              <c.SearchInput
                value={keyWord}
                onSubmit={(e) => {
                  e.preventDefault();
                  setSearch(search++);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    // e.preventDefault();
                    setSearch(search++);
                  }
                }}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  if (e.target.value.length === 0) {
                    setSearch(0);
                    setSearchedCourses([]);
                  }
                }}
                placeholder="Search Courses"
              />
              <c.SearchBtnsContainer>
                <c.SearchBtn onClick={(e) => setSearch(search++)}>
                  <GoSearch onClick={(e) => setSearch(search++)} />
                </c.SearchBtn>
                <c.SearchBtn
                  onClick={(e) => {
                    setSearch(0);
                    setKeyword('');
                    setSearchPending(false);
                    setSearchedCourses([]);
                  }}
                >
                  <VscChromeClose />
                </c.SearchBtn>
              </c.SearchBtnsContainer>
            </c.SearchBarContainer>
            <c.FilterBtn onClick={(e) => setFilters(true)}>
              <VscSettings />
            </c.FilterBtn>
          </c.ContainerTopSearch>

          <c.ContainerTopDropDown>
            <c.DropDownContainer>
              <DropDown
                dropDownValues={formats}
                currentDropdownVal={formatDropDownVal}
                setCurrentDropdownVal={setFormatDropDownVal}
              />
            </c.DropDownContainer>
            <c.DropDownContainer>
              <DropDown
                dropDownValues={qualifications}
                currentDropdownVal={qualificationDropDownVal}
                setCurrentDropdownVal={setQualificationDropDownVal}
              />
            </c.DropDownContainer>
            <c.DropDownContainer>
              <DropDown
                dropDownValues={entryLevels}
                currentDropdownVal={entryLevelDropDownVal}
                setCurrentDropdownVal={setEntryLevelDropDownVal}
              />
            </c.DropDownContainer>
          </c.ContainerTopDropDown>
        </c.ContainerTop>

        <c.ContainerBody>
          <c.ContainerLeft>
            <c.LeftTitle>Select University </c.LeftTitle>

            <c.LeftDrodownContainer>
              {
                <AutoCompleteDropdown
                  dropDownValues={uniList}
                  currentDropdownVal={uniDropDownVal}
                  setCurrentDropdownVal={setUniDropDownVal}
                  disable={disable}
                />
              }
            </c.LeftDrodownContainer>
            {!isAgency && (
              <>
                <c.LeftTitle>Course Locality </c.LeftTitle>
                <c.CheckBoxesContainer>
                  {/* <CheckBox
                labelTxt={'Local'}
                checked={local}
                // onChange={() => setLocal(!local)}
              /> */}

                  <CheckBox
                    labelTxt={'Hybrid'}
                    checked={foreign}
                    onChange={() => setForeign(!foreign)}
                  />
                </c.CheckBoxesContainer>
              </>
            )}
            <c.LeftTitle>Course Categories </c.LeftTitle>

            <c.CheckBoxesContainer>
              <CheckBox
                labelTxt={'Accounting'}
                checked={checkboxState['Accounting']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Architecture & Design'}
                checked={checkboxState['Architecture & Design']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Arts & Humanities'}
                checked={checkboxState['Arts & Humanities']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Aviation'}
                checked={checkboxState.Aviation}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Beauty & Hair'}
                checked={checkboxState['Beauty & Hair']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Business & Management'}
                checked={checkboxState['Business & Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Computing & IT'}
                checked={checkboxState['Computing & IT']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Construction'}
                checked={checkboxState.Construction}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Engineering'}
                checked={checkboxState.Engineering}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Event Management & Tourism'}
                checked={checkboxState['Event Management & Tourism']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Food Science & Agriculture'}
                checked={checkboxState['Food Science & Agriculture']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Finance'}
                checked={checkboxState.Finance}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Hotel & Hospitality'}
                checked={checkboxState['Hotel & Hospitality']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Journalism & Media Studies'}
                checked={checkboxState['Journalism & Media Studies']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Languages'}
                checked={checkboxState.Languages}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Law & Administration'}
                checked={checkboxState['Law & Administration']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Logistics & Supply chain Management'}
                checked={checkboxState['Logistics & Supply chain Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marketing'}
                checked={checkboxState.Marketing}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Medicine and Health'}
                checked={checkboxState['Medicine and Health']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Military Sciences'}
                checked={checkboxState['Military Sciences']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marine'}
                checked={checkboxState.Marine}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Professional Certifications'}
                checked={checkboxState['Professional Certifications']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Physical Science'}
                checked={checkboxState['Physical Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Social Science'}
                checked={checkboxState['Social Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Soft Skill Development'}
                checked={checkboxState['Soft Skill Development']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Sports & Coaching'}
                checked={checkboxState['Sports & Coaching']}
                onChange={handleCheckboxChange}
              />
            </c.CheckBoxesContainer>

            <c.LeftDrodownContainer>
              <c.ClearFiltersBtn
                onClick={() => {
                  if (Object.keys(defaultUni).length === 0) {
                    setUniDropDownVal({
                      label: 'University',
                      value: '',
                    });
                  }
                  setFormatDropDownVal({
                    label: 'Format',
                    value: '',
                  });
                  setQualificationDropDownVal({
                    label: 'Qualification',
                    value: '',
                  });
                  setEntryLevelDropDownVal({
                    label: 'Entry level',
                    value: '',
                  });

                  setCheckboxState({
                    'Arts & Humanities': false,
                    Aviation: false,
                    'Beauty & Hair': false,
                    'Business & Management': false,
                    'Computing & IT': false,
                    Construction: false,
                    Engineering: false,
                    'Event Management & Tourism': false,
                    'Food Science & Agriculture': false,
                    Finance: false,
                    'Hotel & Hospitality': false,
                    'Journalism & Media Studies': false,
                    Languages: false,
                    'Law & Administration': false,
                    Marketing: false,
                    'Medicine and Health': false,
                    'Military Sciences': false,
                    Marine: false,
                    'Professional Certifications': false,
                    'Physical Science': false,
                    'Social Science': false,
                    'Soft Skill Development': false,
                    'Sports & Coaching': false,
                  });
                }}
              >
                Clear Filters
              </c.ClearFiltersBtn>
            </c.LeftDrodownContainer>
          </c.ContainerLeft>

          <c.ContainerRight>
            <c.AdContainer>
              <c.AdImg img={ad1} />
            </c.AdContainer>
            {(isPending || searchPending) && (
              <ClipLoader color="#333" size={60} cssOverride={override} />
            )}

            {courses.length === 0 && !isPending && <NoRecordFound />}
            {search !== 0 && searchedCourses.length === 0 && !searchPending && (
              <NoRecordFound />
            )}

            {data &&
              !isPending &&
              !search &&
              courses.map((course, i) => {
                // console.log({ course });
                return (
                  // <c.Card to={`/courses/${course.id}`} key={i}>
                  <c.Card key={i}>
                    <c.CardLeft>
                      <c.Image src={course.university?.uniLogo} />
                    </c.CardLeft>
                    <c.CardRight>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="700"
                        color="#333"
                        // textalign="center"
                        fontsize="2rem"
                        margin="0 0 2rem 0"
                      >
                        {/* {`${course.courseName
                          .split(' ')[0]
                          .toUpperCase()} ${course.courseName
                          ?.split(' ')[1]
                          ?.toUpperCase()}`} */}
                        {course.courseName.split(' ').length > 1
                          ? course.courseName.split(' ').map((el) => {
                              return `${el.charAt(0).toUpperCase()}${el.slice(
                                1
                              )} `;
                            })
                          : `${
                              course.courseName.charAt(0).toUpperCase() +
                              course.courseName.slice(1)
                            }`}
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.4rem"
                      >
                        Intake :{' '}
                        <c.CardValueLabel>
                          {course.commencement || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Duration :{' '}
                        <c.CardValueLabel>
                          {course.courseDuration || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Qualification :{' '}
                        <c.CardValueLabel>
                          {course.courseQualification ||
                            'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Field :{' '}
                        <c.CardValueLabel>
                          {course.category || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                    </c.CardRight>
                    <c.CardBtnContainer>
                      <c.CardBtnInvert
                        target={'_blank'}
                        to={`/courses/${course.id}`}
                      >
                        Read More
                      </c.CardBtnInvert>
                      <c.CardBtn
                        onClick={() => {
                          setUniversity(course.university.id);
                          setCourse(course.id);
                          setPopup(true);
                        }}
                      >
                        Inquire Now
                      </c.CardBtn>
                    </c.CardBtnContainer>
                  </c.Card>
                );
              })}

            {searchedCourses.length > 0 &&
              search > 0 &&
              !searchPending &&
              searchedCourses.map((course, i) => {
                // console.log({ course });
                return (
                  // <c.Card to={`/courses/${course.id}`} key={i}>
                  <c.Card key={i}>
                    <c.CardLeft>
                      <c.Image src={course.university?.uniLogo} />
                    </c.CardLeft>
                    <c.CardRight>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="700"
                        color="#333"
                        // textalign="center"
                        fontsize="2rem"
                        margin="0 0 2rem 0"
                      >
                        {/* {`${course.courseName
                          .split(' ')[0]
                          .toUpperCase()} ${course.courseName
                          ?.split(' ')[1]
                          ?.toUpperCase()}`} */}
                        {course.courseName.split(' ').length > 1
                          ? course.courseName.split(' ').map((el) => {
                              return `${el.charAt(0).toUpperCase()}${el.slice(
                                1
                              )} `;
                            })
                          : `${
                              course.courseName.charAt(0).toUpperCase() +
                              course.courseName.slice(1)
                            }`}
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.4rem"
                      >
                        Intake :{' '}
                        <c.CardValueLabel>
                          {course.commencement || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Duration :{' '}
                        <c.CardValueLabel>
                          {course.courseDuration || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Qualification :{' '}
                        <c.CardValueLabel>
                          {course.courseQualification ||
                            'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Field :{' '}
                        <c.CardValueLabel>
                          {course.category || 'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                    </c.CardRight>
                    <c.CardBtnContainer>
                      <c.CardBtnInvert to={`/courses/${course.id}`}>
                        Read More
                      </c.CardBtnInvert>
                      <c.CardBtn
                        onClick={() => {
                          setUniversity(course.university.id);
                          setCourse(course.id);
                          setPopup(true);
                        }}
                      >
                        Inquire Now
                      </c.CardBtn>
                    </c.CardBtnContainer>
                  </c.Card>
                );
              })}

            <c.AdContainer>
              <c.AdImg img={ad2} />
            </c.AdContainer>

            {data && (
              <>
                <c.PaginationContainer>
                  <Pagination
                    color="primary"
                    defaultPage={page}
                    // siblingCount={5}
                    size="large"
                    count={Math.floor(data.data.totalRecords / limit) + 1}
                    onChange={(e, val) => {
                      setPage(val);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  />
                </c.PaginationContainer>
              </>
            )}
          </c.ContainerRight>
        </c.ContainerBody>
      </c.Container>
    </c.Section>
  );
};

export default CoursesFilter;
