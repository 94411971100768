import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonContainer = styled(Link)`
  width: 100%;
  text-decoration: none;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  background-color: ${(props) => props.backgroundcolor || 'var(--white)'};
  color: ${(props) => props.textcolor || 'var(--primary-font-color)'};
  padding: ${(props) => props.padding || '1rem 2rem'};
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: ${(props) => props.texttransform || 'uppercase'};
  font-weight: ${(props) => props.fontweight || '300'};

  &:active {
    color: var(--primary-font-color);
  }
`;
