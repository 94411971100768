import React from 'react';
import Dashboard from '../../../components/shared/Dashboard';
import SingleCourse from '../../../components/university/dashboard-components/SingleCourse';
import { useParams } from 'react-router-dom';
// import useFetch from '../../../hooks/useFeth';
const UniversitySingleCourse = () => {
  const { id } = useParams();

  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <SingleCourse />
          </>
        }
      />
    </div>
  );
};

export default UniversitySingleCourse;
