import { useState } from 'react';
import * as l from './CoverElements';
import { AiOutlineCloseCircle } from 'react-icons/ai';
// import { Link } from 'react-router-dom';
// import toast from 'react-hot-toast';
const Cover = ({ uni }) => {
  const [More, setMore] = useState(false);
  const toggleMore = () => {
    setMore(!More);
  };
  const [schedule, setSchedule] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  const onRequestCallSubmit = (e) => {
    e.preventDefault();
    setSchedule(false);

    const data = {
      name,
      email,
      mobile,
    };
    alert(data);
  };

  return (
    <l.Container>
      <l.SubContainer>
        <l.CoverImage img={uni.uniCoverPhoto}>
          {/* <l.CoverImg src={uni.uniCoverPhoto} /> */}
        </l.CoverImage>

        <l.DetailSection>
          <l.UniversityDetilas>
            <l.LogoSection>
              <l.Logo>
                <l.Image src={uni.uniLogo} />{' '}
              </l.Logo>
            </l.LogoSection>
            {/* <l.UniversityName>
              <l.Text>{uni.uniNameComplete.toUpperCase()} </l.Text>
            </l.UniversityName> */}
          </l.UniversityDetilas>
          {/* <l.ButtonSection></l.ButtonSection> */}
        </l.DetailSection>

        <l.UniTitleContainer>
          <l.Text>{uni.uniNameComplete.toUpperCase()} </l.Text>
        </l.UniTitleContainer>

        <l.DescriptionContainer>
          <l.DescriptionText>{uni.uniDescription}</l.DescriptionText>
        </l.DescriptionContainer>

        {/* {More && (
          <l.PopUp>
            <l.Content>
              <l.Close>
                <AiOutlineCloseCircle onClick={toggleMore} />
              </l.Close>
              <l.Heading>INFORMATICS INSTITUTE OF TECHNOLOGY</l.Heading>
              <l.Description>
                <l.Text>
                  The Informatics Institute of Technology is a private higher
                  education institute in Sri Lanka which offers specialized
                  offshore British degree programmes in IT and Business.
                </l.Text>
                <br />
                <l.Text>
                  IIT Celebrates 30 years of Educational Excellence with
                  resounding success in Information Technology and Business
                  Education. We have groomed more than 5000 successful graduates
                  during the past three decades with a growing network of alumni
                  in Sri Lanka and around the globe.
                </l.Text>
              </l.Description>
              <l.PopButtonSection>
                <l.Button>Website</l.Button>
                <l.Button>Location</l.Button>
              </l.PopButtonSection>
            </l.Content>
          </l.PopUp>
        )} */}
        {schedule && (
          <l.PopUp>
            <l.PopupForm onSubmit={onRequestCallSubmit}>
              <l.PopupCloseBtn
                onClick={() => {
                  setSchedule(false);
                }}
              >
                {' '}
                <AiOutlineCloseCircle />{' '}
              </l.PopupCloseBtn>
              <l.PopupTitle>Contact Details</l.PopupTitle>
              <l.PopupFormGroup>
                <l.Label>Name</l.Label>
                <l.Input
                  placeholder="Enter your name here"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </l.PopupFormGroup>
              <l.PopupFormGroup>
                <l.Label>Email</l.Label>
                <l.Input
                  placeholder="Enter your email here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </l.PopupFormGroup>
              <l.PopupFormGroup>
                <l.Label>Mobile</l.Label>
                <l.Input
                  placeholder="Enter your mobile number here"
                  value={name}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </l.PopupFormGroup>
              <l.PopupFormGroup>
                <l.PopupSubmitBtn>Request call</l.PopupSubmitBtn>
              </l.PopupFormGroup>
            </l.PopupForm>
          </l.PopUp>
        )}
      </l.SubContainer>
    </l.Container>
  );
};

export default Cover;
