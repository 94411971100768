import React from 'react';
import * as a from './AboutUsElements';
import UniNotesLogo from '../../images/new_logos/Logo icon/uninotes-logo-icon.png';
import Facebook from '../../images/socialMedia Icons/facebook.png';
import Instagram from '../../images/socialMedia Icons/instagram.png';
const AboutUs = () => {
  return (
    <a.AboutUsContainer>
      <a.AboutUsTitle>ABOUT US</a.AboutUsTitle>
      <a.UniNotesLogo>
        <img src={UniNotesLogo} />
      </a.UniNotesLogo>
      <a.AboutUsParaOne>
        <p>
          Uninotes is a cutting-edge platform that aims to revolutionize the
          education landscape by providing a comprehensive listing of
          alluniversities in Sri Lanka, enabling students to discover their
          ideal education pathway in just less than one minute.
        </p>
      </a.AboutUsParaOne>
      <a.AboutUsParaTwo>
        <p>
          Looking for your dream course but don't know where to start? Uninotes
          makes it easy for education seekers to find their desiredcourse in
          less than one minute! With a simple search, you can browse
          universities in Sri Lanka and find available scholarships thatmatch
          your interests.
        </p>
        <p>
          We understand that finding the right course can be overwhelming, which
          is why we've streamlined the process to make it easy andefficient.
          Whether you're a student or a working professional, Uninotes has
          everything you need to kickstart your education journey.So why wait?
          Start exploring today and take the first step toward your future
          career!
        </p>
      </a.AboutUsParaTwo>
      <a.AboutUsContact>
        <a.AboutUsContactColOne>
          <p>Phone: (+94) 74 255 4570</p>
        </a.AboutUsContactColOne>
        <a.AboutUsContactColTwo>
          <a.IconContainer to="http://www.facebook.com/uninoteslk">
            <img src={Facebook} />
          </a.IconContainer>
          <a.IconContainer to="http://www.instagram.lk/uninoteslk">
            <img src={Instagram} />
          </a.IconContainer>
        </a.AboutUsContactColTwo>
        <a.AboutUsContactColThree>
          <p>Email: connect@uninotes.lk</p>
        </a.AboutUsContactColThree>
      </a.AboutUsContact>
    </a.AboutUsContainer>
  );
};

export default AboutUs;
