import { FiHome,FiSettings,FiBookmark,FiBookOpen,FiTv } from "react-icons/fi";


const links = [
    {
        icon: <FiHome/>,
        linkText: "Home",
        url:'/user/home'
    },
    {
        icon: <FiTv/>,
        linkText: "Favourite Events",
        url:'/user/events'
    },
    {
        icon: <FiBookmark/>,
        linkText: "Favourite Universities",
        url:'/user/universities'
    },
    {
        icon: <FiBookOpen/>,
        linkText: "Favourite Courses",
        url:'/user/courses'
    },
    {
        icon: <FiSettings/>,
        linkText: "Profile",
        url:'/user/profile'
    },
    
]

export {links as studentLinks}
