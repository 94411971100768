import React from 'react'
import * as u from './UniElements'
const Uni = () => {
  return (
    <u.Container>
           <u.HeaderContainer>
        
        <u.HeaderTitle>Universities</u.HeaderTitle>



      </u.HeaderContainer>
      </u.Container>
  )
}

export default Uni