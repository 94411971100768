import React, { useState } from 'react';
import CoursesFilter from '../components/courses/CoursesFilter';
import useFetch from '../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import Label from '../components/shared/Form Elements/Label';
import { DashboardTextField } from '../components/shared/dashboard-form-elements/TextField';
import { TbBrandTelegram } from 'react-icons/tb';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import useAuth from '../hooks/useAuth';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '43%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};
const LoadingContainer = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  ${'' /* transform: translate(-50%, -50%); */}
  width:100%;
  height: 100%;
  z-index: 999999;

  ${'' /* background-color: red; */}

  display:flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    background-color: rgba(39, 91, 143, 0.86);
  }
`;

const PopupForm = styled.form`
  flex: 0 1 50%;
  ${'' /* min-height: 0rem; */}
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  justify-content: flex-start;
  padding: 3rem 5rem;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 1000px) {
    flex: 0 1 70%;
  }
  @media (max-width: 600px) {
    flex: 0 1 90%;
  }
`;

const PopupCloseBtn = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: none;
  outline: none;
  ${'' /* background-color: red; */}
  font-size:3rem;
  color: var(--fourth-blue);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const FormTitle = styled.p`
  font-size: 2.5rem;
  font-family: var(--dashboard-font);
  color: #333;
  font-weight: 700;
  margin: 0 0 1rem 0;
  margin-right: auto;
`;

const FormGroup = styled.div`
  width: 80%;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
  position: relative;

  @media (max-width: 1000px) {
    font-size: 1.3rem;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const ContactSubmitBtn = styled.button`
  max-width: 35%;
  margin: 2rem auto;
  background-color: #275a8f;
  padding: 1rem 3rem;
  border: 1px solid #fff;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  font-family: var(--dashboard-font);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 0%.75;
  }
`;

const CoursesListing = () => {
  const { user, isAuthenticated } = useAuth();

  const [popup, setPopup] = useState(false);
  const [personName, setName] = useState(user?.name || '');
  const [personEmail, setEmail] = useState(user?.email || '');
  const [personMobile, setMobile] = useState('');
  const [course, setCourse] = useState();
  const [university, setUniversity] = useState();

  const sendInsights = async (e) => {
    e.preventDefault();

    let emailValid = false;
    let mobileValid = false;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    emailValid = emailRegex.test(personEmail);

    const mobileNumberRegex = /^0\d{9}$/;
    mobileValid = mobileNumberRegex.test(personMobile);

    if (personName.length === 0) {
      return toast.error('Please enter name', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }

    if (!emailValid) {
      setEmail('');

      return toast.error('Invalid email address.', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
    if (!mobileValid) {
      setMobile('');

      return toast.error('Invalid mobile number.', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }

    if (personName.length > 0 && emailValid && mobileValid) {
      const formData = {
        course,
        university,
        personName,
        personEmail,
        personMobile,
      };

      setPopup(false);
      setName('');
      setEmail('');
      setMobile('');
      return toast.promise(
        fetch('/api/v1/insights/course/', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Error updating name');
          }
          return response.json();
        }),
        {
          loading: 'Sending Request...',
          success:
            'Request sent successfully! University will contact you soon.',
          error: 'Failed to request',
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
            textAlign: 'center',
          },
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
        }
      );
    }

    // console.log({ formData });
  };

  let {
    data: uniList,
    isError: uniListError,
    isPending: uniListPending,
  } = useFetch('/api/v1/universities?isAgency=false&active=true');

  if (uniList) {
    uniList = uniList.data.universities.map((uni) => {
      return {
        label: uni.name.toUpperCase(),
        value: uni.id,
      };
    });
  }
  return (
    <>
      {/* {isPending && <CoursesFilter url={url} setUrl={setUrl} courses={[]} />} */}
      {uniListPending && (
        <LoadingContainer>
          <ClipLoader color="#333" size={60} cssOverride={override} />
        </LoadingContainer>
      )}
      {uniListError && <p>error</p>}

      {uniList && (
        <CoursesFilter
          uniList={uniList}
          defaultUni={{}}
          disable={false}
          setPopup={setPopup}
          setCourse={setCourse}
          setUniversity={setUniversity}
        />
      )}

      {popup && uniList && (
        <PopupContainer>
          <PopupForm>
            <PopupCloseBtn onClick={() => setPopup(false)}>
              <AiOutlineCloseCircle />
            </PopupCloseBtn>
            <FormTitle>Contact Details</FormTitle>

            <FormGroup>
              <Label text={'Name'} />
              <DashboardTextField
                value={personName}
                placeholder={'Enter your name here | ex: John Doe'}
                setValue={setName}
              />
            </FormGroup>
            <FormGroup>
              <Label text={'Email'} />
              <DashboardTextField
                value={personEmail}
                placeholder={'Enter your email here | ex: youremail@mail.com'}
                setValue={setEmail}
              />
            </FormGroup>
            <FormGroup>
              <Label text={'Mobile'} />
              <DashboardTextField
                value={personMobile}
                placeholder={'Enter your mobile number here | ex : 0771234657'}
                setValue={setMobile}
              />
            </FormGroup>
            <FormGroup>
              <ContactSubmitBtn onClick={sendInsights}>
                Submit{' '}
                <IconContainer fontsize="2rem" color="#fff" margin="0 1rem">
                  <TbBrandTelegram />{' '}
                </IconContainer>
              </ContactSubmitBtn>
            </FormGroup>
          </PopupForm>
        </PopupContainer>
      )}
      {/* <CoursesFilter url={url} setUrl={setUrl} courses={data.data.courses} /> */}
    </>
  );
};

export default CoursesListing;
