import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  border-radius: 1rem;
  outline: none;
  border: none;
  padding: 1.5rem 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1% 0;
  color: var(--form-input-text-color);
  border: 1px solid #ddd;
  font-family: var(--dashboard-font);

  :hover {
    border: 1px solid #999;
  }

  :focus {
    outline: 1px solid #333;
  }

  ::placeholder {
    color: #999;
  }

  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

const TextBox = ({ placeholder, value, setValue, required, disabled }) => {
  return (
    <TextArea
      disabled={disabled}
      required={required}
      value={value !== 'undefined' ? value : placeholder}
      placeholder={placeholder || 'placeholder'}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      rows={8}
      cols={100}
    />
  );
};

export default TextBox;
