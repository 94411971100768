import React, { useState } from 'react';
import * as r from './ResetPasswordFormElements';
import PasswordField from './../shared/Form Elements/PasswordField';
import RegularButton from '../shared/Form Elements/RegularButton';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPasswordForm = () => {
  const { resetPassword } = useAuth();

  const { token } = useParams();

  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setPassword('');
      setPasswordConfirm('');
      return toast.error("Passwords doesn't match", {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      });
    }
    await toast.promise(
      resetPassword({ password, passwordConfirm, token }),
      {
        loading: 'Resetting password ...',
        success: (data) => `Passowrd reset successfuly! `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );

    navigate('/login');
  };
  return (
    <>
      <r.LoginSection>
        <r.LoginContainer>
          <r.LoginLeftSection>
            <r.LeftLogoContainer />
          </r.LoginLeftSection>

          <r.LoginRightSection>
            <r.LoginRightTopSection>
              <r.ImageContainer to={'/'} />
              <r.LoginTitle>Reset your password </r.LoginTitle>
            </r.LoginRightTopSection>
            <r.LoginRIghtFormContainer>
              <form onSubmit={handleSubmit}>
                <r.InputWrappers>
                  <PasswordField
                    required={true}
                    value={password}
                    setValue={setPassword}
                    placeholder={'Your new password'}
                  />
                </r.InputWrappers>
                <r.InputWrappers>
                  <PasswordField
                    required={true}
                    value={passwordConfirm}
                    setValue={setPasswordConfirm}
                    placeholder={'Confirm your new password'}
                  />
                </r.InputWrappers>

                <r.InputWrappers>
                  <RegularButton
                    fontweight={'700'}
                    textcolor={'var(--white)'}
                    btntext={'Reset password'}
                    padding={'1.5rem 3rem'}
                    backgroundcolor={'var(--main-blue)'}
                  />
                </r.InputWrappers>
              </form>
            </r.LoginRIghtFormContainer>
          </r.LoginRightSection>
        </r.LoginContainer>
      </r.LoginSection>
    </>
  );
};

export default ResetPasswordForm;
