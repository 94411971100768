import React from 'react';
import * as u from './AdminUsersElements';
// import { BiSearch } from 'react-icons/bi';
// import { FiPlusCircle } from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import ToggleSwitch from '../shared/dashboard-form-elements/ToggleSwitch';
import submitForm from '../../hooks/submitForm';
import { toast } from 'react-hot-toast';

const AdminUsers = ({ usersList }) => {
  const updateUserStatus = async (user) => {
    await toast.promise(
      submitForm(
        `/api/v1/users/${user.active ? 'deactivateUser' : 'activateUser'}/${
          user._id
        }`,
        {},
        'patch',
        {}
      ),
      {
        loading: 'Updating user status...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>Users</u.HeaderTitle>

        {/* <u.SearchBarContainer>
          <u.SearchBar placeholder="Enter name or Email" />
          <u.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </u.SearchIconContainer>
        </u.SearchBarContainer> */}

        {/* <u.AddUserBtn>
          <u.AddUserIcon> <FiPlusCircle /> </u.AddUserIcon> Add User
        </u.AddUserBtn> */}
      </u.HeaderContainer>

      <u.TableContainer>
        <u.Table>
          <u.TableHeader>
            <u.TableDataCell th={true}>#</u.TableDataCell>
            <u.TableDataCell th={true}>Name</u.TableDataCell>
            <u.TableDataCell th={true}>Email</u.TableDataCell>
            <u.TableDataCell th={true}>Profile Status</u.TableDataCell>
            <u.TableDataCell th={true}>Date Registered</u.TableDataCell>
          </u.TableHeader>

          {usersList.map((user, i) => {
            return (
              <u.TableRow key={i}>
                <u.TableDataCell>{i + 1} </u.TableDataCell>
                <u.TableDataCell>{user.name} </u.TableDataCell>
                <u.TableDataCell minwidth="15rem">{user.email}</u.TableDataCell>
                <u.TableDataCell className="flex">
                  <ToggleSwitch
                    checked={user.active}
                    handleChange={() => {
                      updateUserStatus(user);
                    }}
                  />
                  {/* <u.ActiveLabel active={user.active} /> */}
                  {user.active ? 'Active' : 'Deactve'}
                </u.TableDataCell>
                <u.TableDataCell minwidth="15rem">
                  {user.createdAt.split('T')[0]}
                </u.TableDataCell>
              </u.TableRow>
            );
          })}

          {/* <u.TableRow>
            <u.TableDataCell>2</u.TableDataCell>
            <u.TableDataCell>john</u.TableDataCell>
            <u.TableDataCell minwidth="15rem">john@gmail.com</u.TableDataCell>
            <u.TableDataCell className="flex">
              <u.ActiveLabel active={false} />
              Inactive
            </u.TableDataCell>
            <u.TableDataCell minwidth="15rem">13-05-2023</u.TableDataCell>
          </u.TableRow>
          <u.TableRow>
            <u.TableDataCell>3</u.TableDataCell>
            <u.TableDataCell>mary</u.TableDataCell>
            <u.TableDataCell minwidth="15rem">mary@gmail.com</u.TableDataCell>
            <u.TableDataCell className="flex">
              <u.ActiveLabel active={true} />
              Active
            </u.TableDataCell>
            <u.TableDataCell minwidth="15rem">25-05-2023</u.TableDataCell>
          </u.TableRow> */}
        </u.Table>
      </u.TableContainer>
      <u.TablePaginationContainer>
        <Pagination defaultPage={1} siblingCount={0} size="large" count={5} />
      </u.TablePaginationContainer>
    </u.Container>
  );
};

export { AdminUsers as AdminUsersComponent };
