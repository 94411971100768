import React from 'react';
import Dashboard from '../../../components/shared/Dashboard';
import Courses from '../../../components/university/dashboard-components/Courses';
import useFetch from '../../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  // borderColor: "red",
};

const UniversityCourses = () => {
  const { data, isPending, isError } = useFetch('/api/v1/courses/getMyCourses');

  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {data && !isPending && (
        <Dashboard
          rightContainerContent={<Courses courses={data.data.courses} />}
        />
      )}
    </div>
  );
};

export default UniversityCourses;
