import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${'' /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;

  @media (max-width: 760px) {
    padding: 3rem 0rem;
  }
`;

export const HeaderContainer = styled.div`
  ${'' /* background-color:red ; */}
  width:100%;
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0 auto 0 0;
`;

export const SearchBarContainer = styled.div`
  flex: 0 1 30%;
  position: relative;
  display: flex;

  @media (max-width: 1500px) {
    flex: 0 1 35%;
  }
  @media (max-width: 1300px) {
    flex: 0 1 40%;
  }
  @media (max-width: 1100px) {
    flex: 0 1 45%;
  }
  @media (max-width: 1100px) {
    flex: 0 1 45%;
  }
  @media (max-width: 900px) {
    margin: 1rem 0;
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  font-size: 1.3rem;
  padding: 0.5rem 4rem;
  border-radius: 10rem;
  border: 1px solid #444;
  font-family: var(--dashboard-font);
  outline: none;

  @media (max-width: 450px) {
    font-size: 1.1rem;
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  right: 90%;
  position: relative;

  @media (max-width: 900px) {
    right: 95%;
  }
  @media (max-width: 800px) {
    right: 94%;
  }
  @media (max-width: 760px) {
    right: 95%;
  }
  @media (max-width: 600px) {
    right: 93%;
  }
  @media (max-width: 550px) {
    right: 92%;
  }
  @media (max-width: 450px) {
    right: 91%;
  }
  @media (max-width: 350px) {
    right: 90%;
  }
`;

export const AddEventBtn = styled.div`
  flex: 0 1 15%;
  background-color: #222;
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  border-radius: 10rem;
  font-size: 1.3rem;
  font-family: var(--dashboard-font);
  font-weight: 500;
`;

export const AddEventIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-right: 0.5rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  max-height: 40rem;
  ${'' /* background-color:red ; */}
  overflow-x:scroll;
  /* padding: 4rem 2rem; */
  margin: 2rem 0;
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-family: var(--dashboard-font);

  ${'' /* border-top:1px solid #ccc ; */}
  ${'' /* border-bottom:1px solid #ccc ; */}
`;

export const TableActionBtn = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background-color: #fff;
  border: 1px solid #333;
  margin: 0 1rem 0 0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  font-size: 1.6rem;

  :hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  :hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export const TableHeader = styled.tr`
  border-bottom: 1px solid #ddd;
  background-color: #eee;
  /* background-color: black; */
  position: sticky;
  /* position: absolute; */
  z-index: 2;
  padding: 2rem 0;
  top: 0;
  :hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export const TableDataCell = styled.td`
  padding: 1rem 1rem;
  font-weight: ${(props) => (props.th ? '500' : '400')};
  font-size: ${(props) => (props.th ? '1.3rem' : '1.4rem')};
  ${'' /* text-align:left ; */}
  color:${(props) => (props.th ? '#999' : '#555')};
  min-width: ${(props) => props.minwidth || 'auto'};

  &.flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const ActiveLabel = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => (props.active ? '#07ebcc' : '#f7394c')};
  border-radius: 50%;
  margin: 0 1rem 0 0;
`;

export const TablePaginationContainer = styled.div`
  min-width: 20%;
  ${'' /* background-color:red ; */}
  display:flex;
  justify-content: flex-end;
`;
