import React from 'react'
import Dashboard from '../../components/shared/Dashboard'
import Uni from '../../components/student/Uni'


const StudentUniversities = () => {
  return (
      <div>
        <Dashboard
        rightContainerContent={
          <Uni/>
        }
      />
      </div>
  )
}

export default StudentUniversities