import React, { useState, useEffect, useRef } from 'react';
import * as d from './DashboardElements';
import { adminLinks } from '../../data/dashboard-links/adminLinks';
import { studentLinks } from '../../data/dashboard-links/studentLinks';
import { uniLinks } from '../../data/dashboard-links/uniersitySupervisorLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import img from './../../images/home_users/2.webp';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';

const Dashboard = ({ rightContainerContent }) => {
  const { user, logout } = useAuth();

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('/admin/home');

  const [navLinks, setNavLinks] = useState(studentLinks);

  const [open, setOpen] = useState(false);
  const dropDownRef = useRef(null);

  const navigate = useNavigate();

  const handleClickOutside = (e) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleLogout = async (e) => {
    await toast.promise(
      logout(),
      {
        loading: 'Logging out ...',
        success: (data) => `Logged out successfully `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );

    navigate('/');
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    setCurrentPath(location.pathname);

    switch (user?.role) {
      case 'user':
        setNavLinks(studentLinks);
        break;
      case 'admin':
        setNavLinks(adminLinks);
        break;
      case 'university':
        setNavLinks(uniLinks);
        break;

      default:
        // navigate('/login')
        break;
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // [location,user]
  }, [user]);

  const responsiveSizesText = [
    { breakpoint: 1200, fontsize: '1.4rem' },
    { breakpoint: 900, fontsize: '1.2rem' },
    { breakpoint: 500, fontsize: '1.3rem' },
  ];

  return (
    <d.Section>
      <d.NavbarContainer>
        <d.NavBrandContainer to="/" />
        <d.NavDropdownContainer>
          <d.DropDownHeader ref={dropDownRef} onClick={() => setOpen(!open)}>
            <d.NavDropdownAvatar img={user?.photo || img} />
            <d.IconContainer color={'#666'} fontsize="1.8rem">
              {' '}
              <FiChevronDown />{' '}
            </d.IconContainer>
          </d.DropDownHeader>
          <d.DropDownBody open={open}>
            <d.DropdownBodyTop>
              <d.NavDropdownAvatar img={user?.photo || img} />
              <d.DropdownTopTextContainer>
                <d.Text
                  fontfamily="var(--dashboard-font)"
                  fontweight="500"
                  color="#333"
                  fontsize="1.4rem"
                  margin="0 0 0 1rem"
                >
                  {user?.name || 'user'}{' '}
                </d.Text>
                <d.Text
                  responsiveSizesText={responsiveSizesText}
                  fontfamily="var(--dashboard-font)"
                  fontweight="500"
                  color="#777"
                  fontsize="1.4rem"
                  margin="0 0 0 1rem"
                >
                  {user?.email || 'email'}{' '}
                </d.Text>
              </d.DropdownTopTextContainer>
            </d.DropdownBodyTop>

            <d.DashSubMenueContainer>
              {navLinks.map((link, idx) => {
                return (
                  <d.LeftNavItemContainer
                    onClick={() => {
                      setCurrentPath(link.url);
                    }}
                    key={idx}
                    to={link.url}
                  >
                    <d.IconContainer
                      color={
                        currentPath === link.url ||
                        currentPath.includes(link.url)
                          ? 'var(--secondary-blue)'
                          : '#aaa'
                      }
                      fontsize="2.2rem"
                    >
                      {link.icon}
                    </d.IconContainer>
                    <d.Text
                      responsiveSizesText={responsiveSizesText}
                      fontfamily="var(--dashboard-font)"
                      fontweight="500"
                      color={
                        currentPath === link.url ||
                        currentPath.includes(link.url)
                          ? '#333'
                          : '#aaa'
                      }
                      fontsize="1.6rem"
                      margin="0 0 0 1rem"
                    >
                      {link.linkText}{' '}
                    </d.Text>
                  </d.LeftNavItemContainer>
                );
              })}
            </d.DashSubMenueContainer>
            <d.LogoutBtn
              onClick={() => {
                const role = user?.role;
                const url =
                  role === 'user' ? '/user/profile' : '/uni/admin/profile';
                navigate(url);
              }}
            >
              <d.Text
                fontfamily="var(--dashboard-font)"
                fontweight="500"
                color="#333"
                fontsize="1.4rem"
                margin="0 0 0 1rem"
              >
                Profile{' '}
              </d.Text>
            </d.LogoutBtn>
            <d.LogoutBtn
              onClick={() => {
                handleLogout();
              }}
            >
              <d.Text
                fontfamily="var(--dashboard-font)"
                fontweight="500"
                color="#333"
                fontsize="1.4rem"
                margin="0 0 0 1rem"
              >
                Logout{' '}
              </d.Text>
            </d.LogoutBtn>
          </d.DropDownBody>
        </d.NavDropdownContainer>
      </d.NavbarContainer>

      <d.BodyContainer>
        <d.LeftContainer>
          {navLinks.map((link, idx) => {
            return (
              <d.LeftNavItemContainer
                onClick={() => {
                  setCurrentPath(link.url);
                }}
                key={idx}
                to={link.url}
              >
                <d.IconContainer
                  color={
                    currentPath === link.url || currentPath.includes(link.url)
                      ? 'var(--secondary-blue)'
                      : '#aaa'
                  }
                  fontsize="2.2rem"
                >
                  {link.icon}
                </d.IconContainer>
                <d.Text
                  responsiveSizesText={responsiveSizesText}
                  fontfamily="var(--dashboard-font)"
                  fontweight="500"
                  color={
                    currentPath === link.url || currentPath.includes(link.url)
                      ? '#333'
                      : '#aaa'
                  }
                  fontsize="1.4rem"
                  margin="0 0 0 1rem"
                >
                  {link.linkText}{' '}
                </d.Text>
              </d.LeftNavItemContainer>
            );
          })}
        </d.LeftContainer>

        <d.RightContainer>{rightContainerContent}</d.RightContainer>
      </d.BodyContainer>
    </d.Section>
  );
};

export default Dashboard;
