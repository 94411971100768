import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  margin-bottom: 3rem;
`;
export const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
`;
export const CoverImage = styled.div`
  display: flex;
  height: 33rem;
  border-radius: 1em;
  /* background-color: var(--secondary-blue); */
  width: 100%;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
// export const CoverImg = styled.img`
//   width: 80%;
//   height: auto;
// `;

export const DetailSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  padding: 2rem 0;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    margin: 0 0 3rem;
  }
  @media (max-width: 600px) {
    padding: 0;
    margin: 0;
  }
`;

export const UniTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: green; */

  @media (max-width: 2560px) {
    margin: 7rem 0 0;
  }
  @media (max-width: 2000px) {
    margin: 0;
  }
`;

export const UniversityDetilas = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  padding: 0;

  /* @media (max-width: 900px) {
    display: grid;
    align-items: center;
    justify-content: center;
  } */
`;
export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  position: absolute;
  z-index: 999;
  /* margin: 0 62% 4% 0; */
  /* background-color: yellow; */
  padding: 0;

  @media (max-width: 2000px) {
    margin: 0 59% 5% 0;
  }
  @media (max-width: 900px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 5% 26%; */
  }
  @media (max-width: 800px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 16% 25%; */
    /* padding: 0; */
    /* margin: 0; */
  }
  @media (max-width: 700px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 18% 23%; */
  }
  @media (max-width: 600px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    /* width: 5%; */
    /* margin: 0 0 06% 21%; */
  }
  @media (max-width: 550px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 26% 20%; */
  }
  @media (max-width: 500px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 28% 18%; */
  }
  @media (max-width: 400px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 34% 16%; */
  }
  @media (max-width: 350px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 38% 13%; */
  }
  @media (max-width: 300px) {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 23% 11%; */
  }
  @media (max-width: 900px) {
    width: 15rem;
    height: 15rem;
  }
`;
export const Logo = styled.div`
  width: 15rem;
  height: 15rem;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--main-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* background-color: aliceblue; */

  @media (max-width: 1300px) {
    width: 12rem;
    height: 12rem;
  }
  @media (max-width: 1000px) {
    width: 10rem;
    height: 10rem;
  }
  @media (max-width: 900px) {
    width: 12rem;
    height: 12rem;
  }
`;
export const Image = styled.img`
  width: 70%;
  height: auto;
`;
export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  /* margin-top: 3%; */
  position: relative;
  margin: 2.5rem 0 0;

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.5rem 0 0 0;
  }
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.5rem 0 0 0;
  }
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4.5rem 0 0 0;
  }
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5.5rem 0 0 0;
  }
  @media (max-width: 400px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6.5rem 0 0 0;
  }
`;
export const Button = styled.button`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 50px;
    background-color: var(--secondary-blue);
    border-radius: 50px;
    border: none;
    margin: 0 1%;
    padding: 0 2%;
    color: #fff;
    font-weight: 700;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    height: 40px;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    height: 35px;
  }
`;
export const UniversityName = styled.div`
  width: 85%;
  display: flex;
  /* position: absolute; */
  /* left: 59%; */
  /* top: 58.5%; */
  /* height: 50px; */
  justify-content: flex-start;
  align-items: center;
  /* background-color: green; */
  margin: 0 0 0 auto;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    padding: 10% 0 0 0;
    margin: 0 0 0 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
    padding: 10% 0 0 0;
    margin: 5% 0 0 0;
  }
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin: 6% 0 0 0;
    padding: 20% 0 0 0;
  }
  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
    padding: 25% 0 0 0;
  }
  @media (max-width: 450px) {
    width: 100%;
    text-align: center;
    margin: 10% 0 0 0;
    padding: 30% 0 0 0;
  }
  @media (max-width: 450px) {
    width: 100%;
    text-align: center;
    margin: 4% 0 0 0;
    padding: 30% 0 0 0;
  }
`;
export const Text = styled.p`
  font-size: 2.5rem;
  font-weight: 700;
  @media (max-width: 1200px) {
    font-size: 2rem;
  }
  @media (max-width: 800px) {
    font-size: 1.6rem;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;
export const PopUp = styled.div`
  display: flex;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    background-color: rgba(39, 91, 143, 0.86);
  }
`;

export const PopupForm = styled.form`
  flex: 0 1 50%;
  min-height: 70vh;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem 5rem;
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-around; */
`;

export const PopupCloseBtn = styled.div`
  width: 4rem;
  height: 4rem;
  /* background-color: var(--main-blue); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  color: var(--main-blue);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const PopupFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 8rem;
`;

export const Input = styled.input`
  width: 70%;
  padding: 1rem;
  border-radius: 1rem;
  outline: none;
  border: 1px solid #555;
  font-family: var(--primary-font);
  margin: 1rem 0;
`;

export const Label = styled.label`
  font-size: 1.5rem;
`;

export const PopupTitle = styled.p`
  font-weight: 700;
  font-size: 2.5rem;
`;

export const PopupSubmitBtn = styled.button`
  padding: 1rem 3rem;
  background-color: var(--main-blue);
  color: #fff;
  border: none;
  outline: none;
  font-family: var(--primary-font);
  border-radius: 2rem;
  margin: 2rem auto;
`;

export const Heading = styled.div`
  background-color: #fff;
  width: 50%;
  height: fit-content;
  padding: 1% 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  margin: 5% 0;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }
  @media (max-width: 600px) {
    font-size: 1.4rem;
  }

  @media (max-width: 426px) {
    width: 90%;
  }
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 70%;
  text-align: center;

  @media (max-width: 426px) {
    width: 90%;
  }
`;
export const PopButtonSection = styled.div`
  display: flex;
  margin: 5% 0;
`;
export const Overlay = styled.div`
  background: rgba(49, 49, 49, 0.8);
`;
export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 86%;
  height: 80%;
  padding: 2%;
  border-radius: 50px;
  top: 60%;
  background: var(--main-blue-shade);
  position: relative;
  @media (max-width: 426px) {
    margin: 0;
    padding: 0;
    /* background-color: red; */
  }
`;
export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  & {
    color: #fff;
    position: relative;
    left: 50%;
    font-size: 32px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const TopButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TextFeildSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const TextFeild = styled.input`
  width: 30vw;
  height: 50px;
  padding: 2% 4%;
  background-color: #fff;
  margin: 3% 0;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 1500px) {
    font-size: 1.5rem;
  }
  @media (max-width: 1100px) {
    width: 40vw;
  }
  @media (max-width: 800px) {
    width: 50vw;
  }
  @media (max-width: 400px) {
    width: 60vw;
  }
`;
export const HeadingButton = styled.div`
  background-color: #fff;
  width: 30vw;
  height: 30%;
  padding: 2% 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  margin: 3%;
  text-align: center;

  @media (max-width: 1500px) {
    font-size: 1.5rem;
  }
  @media (max-width: 1400px) {
    font-size: 1.2rem;
    height: 40%;
  }
  @media (max-width: 1000px) {
    font-size: 1rem;
    height: 50%;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    height: 60%;
  }
`;
export const Terms = styled.div`
  color: #fff;
  font-size: 12px;
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  min-height: 10rem;
  padding: 2rem 0 0;
  /* background-color: aqua; */
  /* text-align: center; */
  /* background-color: red; */
`;

export const DescriptionText = styled.p`
  font-size: 1.6rem;
  font-family: var(--dashboard-font);
`;
