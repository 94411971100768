import React from 'react';
import Dashboard from '../../../components/shared/Dashboard';
import Scholarships from '../../../components/university/dashboard-components/Scholarships.jsx';
import useFetch from '../../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  // borderColor: "red",
};
const UniversityScholarships = () => {
  const { data, isPending, isError } = useFetch(
    '/api/v1/scholarships/getMyScholarships'
  );

  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color='#275A8F' size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {data && !isPending && (
        <Dashboard
          rightContainerContent={
            <Scholarships scholarships={data.data.scholarships} />
          }
        />
      )}
      {/* <Dashboard
        rightContainerContent={
          <>
            <Scholarships />
          </>
        }
      /> */}
    </div>
  );
};

export default UniversityScholarships;
