import { useState } from 'react';
import DatePicker from "react-multi-date-picker"
const Datepicker = () => {
    const [startDate, setStartDate] = useState(new Date());
    return ( 
        <DatePicker 
        value={startDate}
        onChange={e=>setStartDate(e.target.value)}
        style={{
            width:'100%',
            margin: '1% 0',
            height:'18%',
            borderRadius: "1rem",
            fontSize: "1.4rem",
            fontWeight:'500',
            padding: "1.5rem 3rem",
            color:'var(--form-input-text-color)',
            border:'1px solid #ddd',
            fontFamily:'var(--dashboard-font)'
          }}/>
     );
}
 
export default Datepicker;