import styled from 'styled-components';
import { Link } from 'react-router-dom';
import img from './../../images/new_logos/Logo type 1/uninotes-logo.png';

// @media (max-width: 1300px) {
//     flex:0 1 55% ;

//     }

export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  display: ${(props) => props.display || 'block'};

  ${(props) =>
    props.responsiveSizesText?.map(
      (size, index) => `
    @media (max-width: ${size.breakpoint}px) {
      font-size: ${size.fontsize};
      font-weight: ${size.fontweight};
      color: ${size.color};
      line-height:${size.lineheight};
      display:${size.display};
      margin:${size.margin};
      
    }
    
  `
    )}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
`;

export const Section = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${'' /* background-color:red ; */}
  ${'' /* padding:3rem ; */}
`;

export const NavbarContainer = styled.div`
  width: 100%;
  height: 7rem;
  ${'' /* background-color:green ; */}
  border-bottom:1px solid #ccc;
  display: flex;
  align-items: center;
  ${'' /* padding:0 2rem ; */}
  position: relative;
  /* background-color: red; */
`;

export const NavBrandContainer = styled(Link)`
  flex: 0 1 12%;
  ${'' /* background-color:red ; */}
  height:100%;
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1300px) {
    flex: 0 1 18%;
  }
  @media (max-width: 1100px) {
    flex: 0 1 20%;
  }
  @media (max-width: 900px) {
    flex: 0 1 22%;
  }
  @media (max-width: 760px) {
    flex: 0 1 30%;
  }
  @media (max-width: 600px) {
    flex: 0 1 40%;
  }
  @media (max-width: 500px) {
    flex: 0 1 50%;
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const LeftContainer = styled.div`
  flex: 0 1 25%;
  ${'' /* background-color:red ; */}
  padding:3rem 0rem 3rem 7rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1520px) {
    padding: 3rem 0rem 3rem 5rem;
  }
  @media (max-width: 1400px) {
    flex: 0 1 30%;
    padding: 3rem 0rem 3rem 3rem;
  }
  @media (max-width: 1100px) {
    padding: 3rem 0rem 3rem 0rem;
  }
  @media (max-width: 760px) {
    display: none;
  }
`;
export const RightContainer = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  ${'' /* background-color:green ; */}

  @media (max-width: 760px) {
    padding: 0 5rem;
  }
  @media (max-width: 550px) {
    padding: 0 3.5rem;
  }
`;

export const LeftNavItemContainer = styled(Link)`
  width: 90%;
  ${'' /* background-color:blue ; */}
  padding:1.5rem 3rem;
  ${'' /* margin:1rem 0 ; */}
  display:flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 0.5rem;

  :hover {
    background-color: #eee;
  }

  @media (max-width: 760px) {
    width: 100%;
    padding: 1.5rem 1rem;
  }
`;

export const NavDropdownAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  ${'' /* background-color:green ; */}
  border-radius:50%;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 500px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const NavDropdownContainer = styled.div`
  flex: 0 1 20%;

  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 0 5rem 0;
  /* background-color: red; */

  @media (max-width: 1300px) {
    flex: 0 1 25%;
  }
  @media (max-width: 1100px) {
    flex: 0 1 30%;
  }
  @media (max-width: 1000px) {
    flex: 0 1 35%;
  }
  @media (max-width: 900px) {
    flex: 0 1 40%;
  }
  @media (max-width: 700px) {
    flex: 0 1 45%;
  }
  @media (max-width: 600px) {
    flex: 0 1 55%;
  }
`;

export const DropDownHeader = styled.div`
  width: 7rem;
  padding: 0.5rem 0.1rem;
  border-radius: 42% 42% 43% 43% / 52% 48% 50% 52%;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

export const DropDownBody = styled.div`
  width: 100%;
  ${'' /* min-height:20rem ; */}
  position: absolute;
  top: 100%;
  right: 15%;
  border-radius: 1rem;
  padding: 1rem 2rem;
  background-color: #fff;
  /* background-color: red; */
  box-shadow: 0px 0px 32px 0px rgba(143, 143, 143, 0.2);
  display: ${(props) => (props.open ? 'block' : 'none')};
  z-index: 9999999999999999999999999999999999999999999999999999999;

  @media (max-width: 1500px) {
    right: 18%;
  }
  @media (max-width: 500px) {
    width: 140%;
  }
  @media (max-width: 400px) {
    width: 150%;
  }
  /* background-color: green; */
`;

export const DropdownBodyTop = styled.div`
  border-bottom: 2px solid #ccc;
  display: flex;
  padding: 1rem 0;
  position: relative;
  /* background-color: red; */
`;

export const DropdownTopTextContainer = styled.div`
  flex: 1;
`;

export const LogoutBtn = styled.button`
  width: 100%;
  padding: 1rem 0rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0 0;
  border-radius: 1rem;
  background-color: #fff;
  transition: all 0.3s;
  :hover {
    cursor: pointer;
    background-color: #eee;
  }
  /* background-color: red; */
`;

export const DashSubMenueContainer = styled.div`
  width: 100%;
  min-height: 10rem;
  border-bottom: 2px solid #ccc;

  display: none;

  @media (max-width: 760px) {
    flex: 0 1 25%;
    justify-content: flex-end;
    padding: 0 2rem 0;
    display: block;
  }
  @media (max-width: 400px) {
    flex: 0 1 25%;
    justify-content: flex-end;
    padding: 0 0rem 0;
    display: block;
  }
`;
