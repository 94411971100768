import React from 'react';
import styled from 'styled-components';
import img from './../images/404.jpg';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  /* background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
`;

const Image = styled.img`
  width: auto;
  height: 100%;
`;

const Text = styled.p`
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
`;

const LinkBtn = styled(Link)`
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  background-color: var(--main-blue);
  font-size: 1.5rem;
  border-radius: 3rem;
  margin: 1rem 0;
  font-weight: 700;
`;

const NotFound = () => {
  return (
    <Container>
      <ImageContainer>
        <Image src={img} />
      </ImageContainer>
      <Text>Sorry, This Page is Not Found. </Text>
      <LinkBtn to={'/'}>Back to Home </LinkBtn>
    </Container>
  );
};

export default NotFound;
