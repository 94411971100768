import React from 'react'
import Dashboard from '../../components/shared/Dashboard'
import Events from '../../components/student/Events';


const StudentEvents = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <Events/>
          </>
        }
      />
    </div>
  );
}

export default StudentEvents