import React from 'react'
import AuthState from './context/auth/AuthState'
import App from './App';
const AppWrapper = () => {
  return (<>

    <AuthState>
        <App/>
  </AuthState>
  </>
  )
}

export default AppWrapper