import React from 'react';
import * as c from './ScholarshipsElements.js';
// import { BiSearch } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
// import Pagination from '@mui/material/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { FiPlusCircle, FiEdit } from 'react-icons/fi';

const Scholarships = ({ scholarships }) => {
  const navigate = useNavigate();

  const onScholUpdate = (e, course) => {
    // console.log(course);
    navigate(`/uni/scholarships/${course.id}`);
  };
  return (
    <c.Container>
      <c.HeaderContainer>
        <c.HeaderTitle>Scholarships</c.HeaderTitle>

        {/* <c.SearchBarContainer>
          <c.SearchBar placeholder="Enter name or Email" />
          <c.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </c.SearchIconContainer>
        </c.SearchBarContainer> */}
        <Link to="/uni/Addscholarship">
          <c.AddEventBtn>
            <c.AddEventIcon>
              {' '}
              <FiPlusCircle />{' '}
            </c.AddEventIcon>{' '}
            Add Scholaship
          </c.AddEventBtn>
        </Link>

        {/* <u.AddUniversityBtn>
          <u.AddUniIcon> <FiPlusCircle /> </u.AddUniIcon> Add University
        </u.AddUniversityBtn> */}
      </c.HeaderContainer>

      <c.TableContainer>
        <c.Table>
          <c.TableHeader>
            <c.TableDataCell th={true}>#</c.TableDataCell>
            <c.TableDataCell th={true}>Name</c.TableDataCell>
            <c.TableDataCell th={true}>Validity Period</c.TableDataCell>
            <c.TableDataCell th={true}>Course</c.TableDataCell>
            <c.TableDataCell th={true}>Actions</c.TableDataCell>
          </c.TableHeader>

          {scholarships.map((schol, i) => {
            return (
              <c.TableRow key={i}>
                <c.TableDataCell>{i + 1} </c.TableDataCell>
                <c.TableDataCell>{schol.scholarName} </c.TableDataCell>
                <c.TableDataCell minwidth="15rem">
                  {schol.scholarValidPeriod}
                </c.TableDataCell>
                <c.TableDataCell>{schol.course.courseName}</c.TableDataCell>
                <c.TableDataCell className="flex" minwidth="15rem">
                  <c.TableActionBtn
                    onClick={(e) => {
                      onScholUpdate(e, schol);
                    }}
                  >
                    <FiEdit />
                  </c.TableActionBtn>
                  {/* <c.TableActionBtn>
                    <FiTrash2 />
                  </c.TableActionBtn> */}
                  <c.TableActionBtn
                    onClick={(e) => {
                      navigate(`/scholarships/${schol.id}`);
                    }}
                  >
                    <AiOutlineEye />
                  </c.TableActionBtn>
                </c.TableDataCell>
              </c.TableRow>
            );
          })}
        </c.Table>
      </c.TableContainer>

      <c.TablePaginationContainer>
        {/* <Pagination
          // color="secondary"
          size="large"
          count={5} defaultPage={6} siblingCount={0} /> */}
      </c.TablePaginationContainer>
    </c.Container>
  );
};

export default Scholarships;
