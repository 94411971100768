import React from 'react';

import * as e from './EventElements';
import { BiSearch } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
// import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';
const Events = () => {
  return (
    <e.Container>
      <e.HeaderContainer>
        <e.HeaderTitle>Events</e.HeaderTitle>

        <e.SearchBarContainer>
          <e.SearchBar placeholder="Enter name or Email" />
          <e.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </e.SearchIconContainer>
        </e.SearchBarContainer>
        <Link to="/uni/Addevent">
          <e.AddEventBtn>
            <e.AddEventIcon>
              {' '}
              <FiPlusCircle />{' '}
            </e.AddEventIcon>{' '}
            Add Event
          </e.AddEventBtn>
        </Link>

        {/* <u.AddUniversityBtn>
          <u.AddUniIcon> <FiPlusCircle /> </u.AddUniIcon> Add University
        </u.AddUniversityBtn> */}
      </e.HeaderContainer>

      <e.TableContainer>
        <e.Table>
          <Link to="/uni/events/:id">
            <e.TableRow>
              <e.TableDataCell th={true}>#</e.TableDataCell>
              <e.TableDataCell th={true}>Name</e.TableDataCell>
              <e.TableDataCell th={true}>Date</e.TableDataCell>
              <e.TableDataCell th={true}>Time</e.TableDataCell>
              <e.TableDataCell th={true}>Venue</e.TableDataCell>
            </e.TableRow>
          </Link>
          <e.TableRow>
            <e.TableDataCell>1</e.TableDataCell>
            <e.TableDataCell>SLLIT</e.TableDataCell>
            <e.TableDataCell minwidth="15rem">sllit@gmail.com</e.TableDataCell>
            <e.TableDataCell className="flex">
              <e.ActiveLabel active={true} /> Active{' '}
            </e.TableDataCell>
            <e.TableDataCell minwidth="15rem">03-06-2023</e.TableDataCell>
          </e.TableRow>
          <e.TableRow>
            <e.TableDataCell>2</e.TableDataCell>
            <e.TableDataCell>IIT</e.TableDataCell>
            <e.TableDataCell minwidth="15rem">iit@gmail.com</e.TableDataCell>
            <e.TableDataCell className="flex">
              <e.ActiveLabel active={false} />
              Inactive
            </e.TableDataCell>
            <e.TableDataCell minwidth="15rem">13-05-2023</e.TableDataCell>
          </e.TableRow>
          <e.TableRow>
            <e.TableDataCell>3</e.TableDataCell>
            <e.TableDataCell>NSBM</e.TableDataCell>
            <e.TableDataCell minwidth="15rem">nsbm@gmail.com</e.TableDataCell>
            <e.TableDataCell className="flex">
              <e.ActiveLabel active={true} />
              Active
            </e.TableDataCell>
            <e.TableDataCell minwidth="15rem">25-05-2023</e.TableDataCell>
          </e.TableRow>
        </e.Table>
      </e.TableContainer>

      <e.TablePaginationContainer>
        {/* <Pagination
          // color="secondary"
          size="large"
          count={5} defaultPage={6} siblingCount={0} /> */}
      </e.TablePaginationContainer>
    </e.Container>
  );
};

export default Events;
