import React from 'react';
import styled from 'styled-components';

const InputField = styled.input`
  width: 100%;
  border-radius: 4rem;
  outline: none;
  border: none;
  padding: 1.5rem 3rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--form-input-text-color);
  transition: all 0.2s;
  border: 1px solid rgba(255, 255, 255, 0);

  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
  :focus {
    border: 1px solid #555;
  }
`;

const TextField = ({ placeholder, value, setValue, required }) => {
  return (
    <InputField
      required={required}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={'text'}
      placeholder={placeholder || 'placeholder'}
    />
  );
};

export default TextField;
