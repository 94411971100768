import React, { useState } from 'react';
import * as f from './ForgotPasswordFormElements.js';
import TextField from '../shared/Form Elements/TextField';
import RegularButton from '../shared/Form Elements/RegularButton.jsx';
import useAuth from '../../hooks/useAuth.js';
import toast from 'react-hot-toast';

const ForgotPasswordForm = () => {
  const { forgotPassword } = useAuth();

  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    await toast.promise(
      forgotPassword({ email }),
      {
        loading: 'Sending Email ...',
        success: (data) => `Email sent successfuly! `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );

    setEmail('');
  };
  return (
    <>
      <f.LoginSection>
        <f.LoginContainer>
          <f.LoginLeftSection>
            <f.LeftLogoContainer />
          </f.LoginLeftSection>

          <f.LoginRightSection>
            <f.LoginRightTopSection>
              <f.ImageContainer to={'/'} />
              <f.LoginTitle>Can't remember password? </f.LoginTitle>
              <f.LoginTitle fontsize="1.4rem" fontweight="300">
                Enter your email address below and we'll send you a link to
                reset your password.
              </f.LoginTitle>
            </f.LoginRightTopSection>
            <f.LoginRIghtFormContainer>
              <form onSubmit={handleSubmit}>
                <f.InputWrappers>
                  <TextField
                    required={true}
                    value={email}
                    setValue={setEmail}
                    placeholder={'Your email'}
                  />
                </f.InputWrappers>

                <f.InputWrappers>
                  <RegularButton
                    fontweight={'700'}
                    textcolor={'var(--white)'}
                    btntext={'Send Email'}
                    padding={'1.5rem 3rem'}
                    backgroundcolor={'var(--main-blue)'}
                  />
                </f.InputWrappers>
              </form>
            </f.LoginRIghtFormContainer>
          </f.LoginRightSection>
        </f.LoginContainer>
      </f.LoginSection>
    </>
  );
};

export default ForgotPasswordForm;
