import Datepicker from '../../shared/dashboard-form-elements/DatePicker';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
import * as s from './AddEventElements'
const AddEvent = () => {
    return ( 
        <s.Container>
      <s.HeaderContainer>
        <s.HeaderTitle>Single Events</s.HeaderTitle>
      </s.HeaderContainer>
      <s.FormContainer>
        <s.Fields>
          <DashboardTextField placeholder={'Event Name'}/>
          <s.DateTime>
            <Datepicker />
          </s.DateTime>
          <DashboardTextField placeholder={'Event Venue'}/>
        </s.Fields>
        <s.Button>
          <RegularButton
             fontweight={'bold'}
             backgroundcolor={'var(--fourth-blue)'}
             textcolor={'white'}
             btntext={'Submit'} />
        </s.Button>
      </s.FormContainer>
    </s.Container>
     );
}
 
export default AddEvent;