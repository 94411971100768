import React from 'react'
import ResetPasswordForm from '../components/reset-password/ResetPasswordForm'

const ResetPassword = () => {
  return (
      <>
          <ResetPasswordForm/>
    </>
  )
}

export default ResetPassword