import WelcomePageCard from '../../components/shared/WelcomePageCard';
import { FiTv } from 'react-icons/fi';
// import { FiBookmark } from "react-icons/fa";

const cards = [
  // {
  //   element: (
  //     <WelcomePageCard
  //       icon={<FiBookOpen />}
  //       cardcolor='#F0F0FF'
  //       iconcirclecolor='#D9D9FC'
  //       text='Favourite Courses'
  //       url='/admin/user/add'
  //     />
  //   ),
  // },
  // {
  //   element: (
  //     <WelcomePageCard
  //       icon={<FiBookmark />}
  //       cardcolor='#EBFAF0'
  //       iconcirclecolor='#CCEBD7'
  //       text='Favourite Universities'
  //       url='/admin/user/add'
  //     />
  //   ),
  // },
  {
    element: (
      <WelcomePageCard
        icon={<FiTv />}
        cardcolor="#FFF9E5"
        iconcirclecolor="#FCEEC5"
        text="My Profile"
        url="/user/profile"
      />
    ),
  },
  // {
  //     element: <WelcomePageCard icon={<FiTv />} cardcolor='#FFF9E5' iconcirclecolor='#FCEEC5' text='View Users' />,
  //     url:'/admin/user/add'
  // },
];

export { cards as studentCards };
