import React, { useState } from 'react'
import * as s from './SingleEventElements';
import Datepicker from '../../shared/dashboard-form-elements/DatePicker';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
const SingleEvent = () => {
  const [appear,setAppear] = useState(false);

    const handleEdit = ()=>{
        setAppear(true);
    }
  return (
    <s.Container>
      <s.HeaderContainer>
        <s.HeaderTitle>Single Events</s.HeaderTitle>
      </s.HeaderContainer>
      <s.FormContainer>
        <s.Fields>
          <DashboardTextField placeholder={'Event Name'}/>
          {
            appear ? 
            <s.DateTime>
              <Datepicker />
            </s.DateTime>
            : 
            <DashboardTextField placeholder={'Date'}/>
          }
          <DashboardTextField placeholder={'Event Venue'}/>
        </s.Fields>
        <s.ButtonSection>
          <s.Button>
            <RegularButton
              fontweight={'bold'}
              backgroundcolor={'var(--fourth-blue)'}
              textcolor={'white'}
              btntext={'Edit'} 
              onClick={handleEdit}/>
          </s.Button>
          <s.Button>
            <RegularButton
                fontweight={'bold'}
                backgroundcolor={'var(--fourth-blue)'}
                textcolor={'white'}
                btntext={'Update'} />
          </s.Button>
        </s.ButtonSection>
      </s.FormContainer>
    </s.Container>
  )
}

export default SingleEvent