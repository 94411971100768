import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.div`
  width: 100%;
  /* min-height: 30rem; */
  ${'' /* background-color:red ; */}
  /* background-color: red; */
  display: flex;
  padding: 0rem 25rem;
  overflow: hidden;

  @media (max-width: 2561px) {
    padding: 2rem 40rem;
  }
  @media (max-width: 2200px) {
    padding: 2rem 30rem;
  }
  @media (max-width: 1900px) {
    padding: 2rem 25rem;
  }
  @media (max-width: 1600px) {
    padding: 2rem 17rem;
  }

  @media (max-width: 1000px) {
    padding: 2rem 6rem;
  }

  @media (max-width: 760px) {
    padding: 2rem 6rem;
  }
  @media (max-width: 550px) {
    padding: 2rem 4rem;
  }
  @media (max-width: 500px) {
    padding: 0rem 0rem;
  }
`;

export const Container = styled.div`
  flex: 0 1 100%;
  ${'' /* background-color:green ; */}
  ${'' /* min-height:35rem ; */}
    display:flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 2rem 0;
  @media (max-width: 500px) {
    margin: 0rem 0;
  }
`;

export const UniversityLogoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${'' /* background-color:red ; */}
  flex-wrap:wrap;
`;

export const UniLogoContainer = styled.div`
  flex: 0 1 15%;
  height: 12rem;
  ${'' /* background-color:green ; */}
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 2px solid #ddd;

  @media (max-width: 2561px) {
    height: 25rem;
  }
  @media (max-width: 2200px) {
    height: 20rem;
  }
  @media (max-width: 1800px) {
    height: 15rem;
  }
  @media (max-width: 1500px) {
    height: 12rem;
  }

  @media (max-width: 1300px) {
    flex: 0 1 12%;
    height: 10rem;
  }
  @media (max-width: 1200px) {
    flex: 0 1 30%;
    height: 15rem;
    margin: 1rem 0;
  }
  @media (max-width: 900px) {
    height: 10rem;
  }
  @media (max-width: 700px) {
    height: 8rem;
  }
  @media (max-width: 500px) {
    flex: 0 1 40%;
  }
`;

export const Button = styled(Link)`
  width: 15%;
  margin: 3rem auto;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5rem 2rem;
  background-color: #e8f2fe;
  text-decoration: none;
  color: #222;

  @media (max-width: 1300px) {
    width: 25%;
  }
  @media (max-width: 850px) {
    width: 35%;
  }
  @media (max-width: 600px) {
    width: 45%;
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    width: 55%;
    font-size: 1.3rem;
  }
  @media (max-width: 350px) {
    width: 65%;
  }
`;

export const LogoContainer = styled.div`
  width: 30%;
  min-height: 10rem;
  background-color: red;
`;

export const CarousalContainer = styled.div`
  width: 100%;
  padding: 5rem 0;
`;

export const Banner = styled.div`
  width: 100%;
  height: 30rem;
  background-color: var(--secondary-blue);
  /* background-color: #eee; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 3rem; */
  padding: 2rem;
  ${'' /* margin:0 1rem ; */}

  @media (max-width: 600px) {
    height: 20rem;
  }
`;
