import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
  width: 100%;
  padding: 5rem 20rem;

  @media (max-width: 2560px) {
    padding: 5rem 45rem;
  }
  @media (max-width: 2200px) {
    padding: 5rem 35rem;
  }
  @media (max-width: 2000px) {
    padding: 5rem 30rem;
  }
  @media (max-width: 1800px) {
    padding: 5rem 20rem;
  }

  @media (max-width: 1000px) {
    padding: 5rem 15rem;
  }
  @media (max-width: 800px) {
    padding: 5rem 10rem;
  }
  @media (max-width: 500px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 350px) {
    padding: 5rem 2rem;
  }
`;
export const PrivacyPolicyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
`;
export const UniNotesLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 15rem;
    height: 15rem;
  }
`;
export const PrivacyPolicyParaOne = styled.div`
  font-size: 1.7rem;
  padding: 2rem 0;
  font-family: var(--dashboard-font);
  color: var(--fourth-blue);

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;
export const PrivacyPolicyParaTwo = styled.div`
  font-size: 1.7rem;
  padding: 1rem 0;
  font-family: var(--dashboard-font);
  color: var(--fourth-blue);
  p {
    padding: 0.8rem 0;
  }

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;
export const PrivacyPolicyContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: grid;
    align-items: center;
    justify-content: center;
  }
`;
export const PrivacyPolicyContactColOne = styled.div`
  width: 44%;
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  font-family: var(--dashboard-font);
  color: var(--fourth-blue);

  @media (max-width: 1600px) {
    width: 40%;
  }
  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin: 0 0 2rem 0;
  }
`;
export const PrivacyPolicyContactColTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    margin: 0 1rem;

    @media (max-width: 1200px) {
      width: 3rem;
      height: 3rem;
      border-radius: 0.75rem;
    }
    @media (max-width: 1000px) {
      width: 2rem;
      height: 2rem;
      border-radius: 0.6rem;
    }
    @media (max-width: 800px) {
      width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
    }
    @media (max-width: 600px) {
      width: 2rem;
      height: 2rem;
      border-radius: 0.3rem;
    }
    @media (max-width: 600px) {
      width: 2rem;
      height: 2rem;
      border-radius: 0.1rem;
    }
  }
`;
export const PrivacyPolicyContactColThree = styled.div`
  width: 44%;
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  font-family: var(--dashboard-font);
  color: var(--fourth-blue);
  margin: 2rem 0;

  @media (max-width: 1600px) {
    width: 40%;
  }
  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
`;
