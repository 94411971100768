import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from './../../images/new_logos/Logo type 1/uninotes-logo-2.png';

export const FooterSection = styled.div`
  width: 100%;
  min-height: 40rem;
  ${'' /* background-color:#333 ; */}
  padding:1rem 15rem;
  overflow: hidden;
  @media (max-width: 2561px) {
    padding: 1rem 25rem;
  }

  @media (max-width: 2000px) {
    padding: 1rem 20rem;
  }
  @media (max-width: 1700px) {
    padding: 1rem 15rem;
  }

  @media (max-width: 1000px) {
    padding: 1rem 6rem;
  }
  @media (max-width: 600px) {
    padding: 1rem 4rem;
  }
  @media (max-width: 500px) {
    padding: 1rem 2rem;
  }
`;

export const MainFooterContainer = styled.div`
  width: 100%;
  min-height: 25rem;
  background-color: var(--main-blue);
  margin: 1rem 0;
  margin: 1rem auto;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 20rem;
  flex-wrap: wrap;

  @media (max-width: 1400px) {
    padding: 3rem 12rem;
  }
  @media (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media (max-width: 700px) {
    padding: 3rem 4rem;
  }
  @media (max-width: 600px) {
    padding: 3rem 2rem;
  }
  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const SecondaryFooterContainer = styled.div`
  width: 100%;
  ${'' /* min-height: 10rem; */}
  margin:2rem auto;
  background-color: var(--main-blue);
  border-radius: 2rem;
  display: flex;
  padding: 2rem 6rem;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    padding: 1.5rem 2rem;
    justify-content: center;
  }
`;

export const FooterColumn = styled.div`
  flex: 0 1 25%;
  /* background: red; */
  text-align: center;

  @media (max-width: 480px) {
    flex: 0 1 100%;
    margin: 2rem 0;
  }
`;

export const FooterColumnTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  @media (max-width: 1100px) {
    font-size: 1.8rem;
  }
  @media (max-width: 900px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;
  margin: 2rem 0 0 0;

  @media (max-width: 480px) {
    margin: 0 0 0 0;
  }
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
  color: var(--white);
  font-size: 1.5rem;
  margin: 1rem 0;
  letter-spacing: 0.1rem;

  @media (max-width: 1100px) {
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    ${'' /* padding:0 0 0 3rem ; */}
  }
`;

export const FooterBrand = styled.div`
  flex: 0 1 20%;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1200px) {
    flex: 0 1 40%;
  }
  @media (max-width: 900px) {
    flex: 0 1 30%;
  }
  @media (max-width: 800px) {
    flex: 0 1 50%;
    height: 5rem;
    margin: 0 0 1rem 0;
  }
  @media (max-width: 500px) {
    height: 5rem;
  }
  @media (max-width: 400px) {
    flex: 0 1 70%;
    height: 8rem;
  }
`;

export const SecondaryFooterLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex: 0 1 100%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
