import React from 'react';
import * as f from './FooterElements';

const Footer = () => {
  return (
    <>
      <f.FooterSection>
        <f.MainFooterContainer>
          <f.FooterColumn>
            <f.FooterColumnTitle>About Uninotes </f.FooterColumnTitle>
            <f.FooterLinksContainer>
              <f.FooterLink to="/aboutus">About Us</f.FooterLink>
              <f.FooterLink to="/university-login">Portal Login</f.FooterLink>
              <f.FooterLink to="/">Careers</f.FooterLink>
            </f.FooterLinksContainer>
          </f.FooterColumn>
          <f.FooterColumn>
            <f.FooterColumnTitle>Main Categories </f.FooterColumnTitle>
            <f.FooterLinksContainer>
              <f.FooterLink to="/universities">Universities</f.FooterLink>
              <f.FooterLink to="/courses">Courses</f.FooterLink>
              <f.FooterLink to="/scholarships">Scholarships</f.FooterLink>
            </f.FooterLinksContainer>
          </f.FooterColumn>
          <f.FooterColumn>
            <f.FooterColumnTitle> Uninotes </f.FooterColumnTitle>
            <f.FooterLinksContainer>
              <f.FooterLink to="http://www.facebook.com/uninoteslk">
                Facebook
              </f.FooterLink>
              <f.FooterLink to="http://www.instagram.lk/uninoteslk">
                Instagram
              </f.FooterLink>
              <f.FooterLink to="http://www.linkedin.com/company/uninoteslk">
                LinkedIn
              </f.FooterLink>
            </f.FooterLinksContainer>
          </f.FooterColumn>
        </f.MainFooterContainer>

        <f.SecondaryFooterContainer>
          <f.FooterBrand />
          <f.SecondaryFooterLinksContainer>
            <f.FooterLink to="/privacypolicy">Privacy Policy </f.FooterLink>
            <f.FooterLink to="/termsconditions">
              Terms & Conditions{' '}
            </f.FooterLink>
            <f.FooterLink to="/">Contact Us </f.FooterLink>
            <f.FooterLink to="/">
              © {new Date().getFullYear()} Uninotes
            </f.FooterLink>
          </f.SecondaryFooterLinksContainer>
        </f.SecondaryFooterContainer>
      </f.FooterSection>
    </>
  );
};

export default Footer;
