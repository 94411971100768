import React from 'react';
import * as b from './LinkButtonElements';

const LinkButton = ({
  btntext,
  to,
  textcolor,
  fontsize,
  backgroundcolor,
  padding,
  fontweight,
  texttransform,
}) => {
  return (
    <b.ButtonContainer
      textcolor={textcolor}
      to={`${to || '/'}`}
      padding={padding}
      backgroundcolor={backgroundcolor}
      fontsize={fontsize}
      fontweight={fontweight}
      texttransform={texttransform}
    >
      {btntext}
    </b.ButtonContainer>
  );
};

export default LinkButton;
