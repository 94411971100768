import React, { useState } from 'react';
import * as a from './ContentElements.js';
import {
  // TbReportMoney,
  // TbBallpen,
  TbChecklist,
  TbCircleCheck,
  TbBrandTelegram,
} from 'react-icons/tb';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Label from '../../shared/Form Elements/Label.jsx';
import { DashboardTextField } from './../../shared/dashboard-form-elements/TextField.jsx';
import useAuth from '../../../hooks/useAuth.js';
import toast from 'react-hot-toast';

const Content = ({
  scholarId,
  description,
  name,
  universityId,
  req,
  img,
  title,
  universityName,
}) => {
  const [data, setData] = useState('requirements');
  const [popup, setPopup] = useState(false);
  const { user } = useAuth();

  const [scholarship] = useState(scholarId);
  const [university] = useState(universityId);
  const [personName, setName] = useState(user?.name || '');
  const [personEmail, setEmail] = useState(user?.email || '');
  const [personMobile, setMobile] = useState('');

  /////////////////// Send insigths (END) (START) ///////////////////
  const sendInsights = async (e) => {
    e.preventDefault();

    let emailValid = false;
    let mobileValid = false;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    emailValid = emailRegex.test(personEmail);

    const mobileNumberRegex = /^0\d{9}$/;
    mobileValid = mobileNumberRegex.test(personMobile);

    if (personName.length === 0) {
      return toast.error('Please enter name', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }

    if (!emailValid) {
      setEmail('');

      return toast.error('Invalid email address.', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
    if (!mobileValid) {
      setMobile('');

      return toast.error('Invalid mobile number.', {
        style: {
          fontSize: '1.5rem',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }

    if (personName.length > 0 && emailValid && mobileValid) {
      const formData = {
        scholarship,
        university,
        personName,
        personEmail,
        personMobile,
      };

      setPopup(false);
      setName('');
      setEmail('');
      setMobile('');

      return toast.promise(
        fetch('/api/v1/insights/scholar/', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Error updating name');
          }
          return response.json();
        }),
        {
          loading: 'Sending Request...',
          success:
            'Request sent successfully!University will contact you soon.',
          error: 'Failed to request',
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
            textAlign: 'center',
          },
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
        }
      );
    }
  };
  /////////////////// Send insigths (END) ///////////////////

  return (
    <>
      <a.Container>
        <a.HeaderContainer>
          <a.HeaderLeft backgroundImage={img}></a.HeaderLeft>
          <a.HeaderRight>
            <a.Text
              margin="0 0 1rem 0"
              color="#333"
              fontsize="2.5rem"
              fontweight="700"
            >
              {title.split(' ').length > 1
                ? `${
                    title.split(' ')[0].charAt(0).toUpperCase() +
                    title.split(' ')[0].slice(1)
                  } ${
                    title.split(' ')[1].charAt(0).toUpperCase() +
                    title.split(' ')[1].slice(1)
                  }`
                : `${title.charAt(0).toUpperCase() + title.slice(1)}`}{' '}
              &nbsp; - &nbsp;{' '}
              <a.UniNmeLink to={`/universities/${universityId}`}>
                {' '}
                {universityName.toUpperCase()}
              </a.UniNmeLink>
            </a.Text>

            <a.Text
              fontfamily="var(--dashboard-font)"
              color="#333"
              fontsize="1.5rem"
              fontweight="400"
            >
              {description}
            </a.Text>

            <a.MiniNavContainer>
              {/* <a.MiniNavItem
            select={data === 'fee' ? true : false}
            onClick={() => setData('fee')}
          >
            Course Fee{' '}
            <a.IconContainer
              color={data === 'fee' ? '#275A8F' : '#333'}
              fontsize='2rem'
              margin='0 1rem'
            >
              <TbReportMoney />
            </a.IconContainer>{' '}
          </a.MiniNavItem> */}

              {/* <a.MiniNavItem
            onClick={() => setData("curriculm")}
            select={data === "curriculm" ? true : false}
          >
            Course Curriculm{" "}
            <a.IconContainer
              color={data === "curriculm" ? "#275A8F" : "#333"}
              fontsize="1.8rem"
              margin="0 1rem"
            >
              <TbBallpen />
            </a.IconContainer>{" "}
          </a.MiniNavItem> */}

              <a.MiniNavItem
                select={data === 'requirements' ? true : false}
                onClick={() => setData('requirements')}
              >
                Entry Requirements{' '}
                <a.IconContainer
                  color={data === 'requirements' ? '#275A8F' : '#333'}
                  fontsize="2rem"
                  margin="0 1rem"
                >
                  <TbChecklist />
                </a.IconContainer>{' '}
              </a.MiniNavItem>
            </a.MiniNavContainer>

            <a.InfoContainer>
              {/* {data === "fee" && (
            <a.Text
              fontfamily="var(--dashboard-font)"
              color="#333"
              fontsize="1.5rem"
              fontweight="400"
            >
              The fee is presently Rs 290,000 per semester for the first two
              years and Rs. 320,000 per semester for the final two years. Fees
              for any subsequent semester should be paid prior to the
              commencement of each semester. This all inclusive fee is charged
              to cover lectures, tutorials and examinations and access to
              computer laboratory facilities and library.
            </a.Text>
          )} */}
              {data === 'requirements' && (
                <>
                  {req &&
                    req?.map((req, i) => {
                      return (
                        <a.CheckListRow>
                          <a.IconContainer
                            color="#275A8F"
                            fontsize="2rem"
                            margin="0 1rem"
                          >
                            <TbCircleCheck />
                          </a.IconContainer>
                          <a.Text
                            fontfamily="var(--dashboard-font)"
                            color="#333"
                            fontsize="1.4rem"
                            fontweight="400"
                          >
                            {req}
                          </a.Text>
                        </a.CheckListRow>
                      );
                    })}
                </>
              )}
            </a.InfoContainer>

            <a.SubmitBtn
              onClick={(e) => {
                setPopup(true);
              }}
            >
              Inquire now{' '}
              <a.IconContainer fontsize="2rem" color="#fff" margin="0 1rem">
                <TbBrandTelegram />{' '}
              </a.IconContainer>{' '}
            </a.SubmitBtn>
          </a.HeaderRight>
        </a.HeaderContainer>

        {popup && (
          <a.PopupContainer>
            <a.PopupForm>
              <a.PopupCloseBtn onClick={() => setPopup(false)}>
                <AiOutlineCloseCircle />
              </a.PopupCloseBtn>
              <a.FormTitle>Contact Details</a.FormTitle>

              <a.FormGroup>
                <Label text={'Name'} />
                <DashboardTextField
                  value={personName}
                  placeholder={'Enter your name here'}
                  setValue={setName}
                />
              </a.FormGroup>
              <a.FormGroup>
                <Label text={'Email'} />
                <DashboardTextField
                  value={personEmail}
                  placeholder={'Enter your email here'}
                  setValue={setEmail}
                />
              </a.FormGroup>
              <a.FormGroup>
                <Label text={'Mobile'} />
                <DashboardTextField
                  value={personMobile}
                  placeholder={'Enter your mobile number here'}
                  setValue={setMobile}
                />
              </a.FormGroup>
              <a.FormGroup>
                <a.ContactSubmitBtn onClick={sendInsights}>
                  Submit{' '}
                  <a.IconContainer fontsize="2rem" color="#fff" margin="0 1rem">
                    <TbBrandTelegram />{' '}
                  </a.IconContainer>
                </a.ContactSubmitBtn>
              </a.FormGroup>
            </a.PopupForm>
          </a.PopupContainer>
        )}
      </a.Container>
    </>
  );
};

export default Content;
