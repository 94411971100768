import React from 'react';
import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  padding: ${(props) => props.padding || '1rem 2rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundcolor || 'var(--white)'};
  color: ${(props) => props.textcolor || 'var(--primary-font-color)'};
  font-weight: ${(props) => props.fontweight || '300'};
  border-radius: 4rem;
  border: none;
  outline: none;
  /* margin: 0 5%; */

  :hover {
    cursor: pointer;
  }
`;

const RegularButton = ({
  btntext,
  onClick,
  padding,
  backgroundcolor,
  textcolor,
  fontweight,
}) => {
  return (
    <Button
      fontweight={fontweight}
      padding={padding}
      backgroundcolor={backgroundcolor}
      textcolor={textcolor}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {btntext}
    </Button>
  );
};

export default RegularButton;
