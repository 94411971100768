import React from 'react'
import Dashboard from '../../components/shared/Dashboard'
import Courses from '../../components/student/Courses'

const StudentCourses = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <Courses />
          </>
        }
      />
    </div>
  );
}

export default StudentCourses