import React from 'react'
import SignUpForm from '../components/SignUp/SignUp'

const SignUp = () => {
  return (
    <div>
      <SignUpForm/>
    </div>
  )
}

export default SignUp