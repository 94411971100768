import React from 'react';
import Dashboard from '../../components/shared/Dashboard';
import AdminBannersComp from './../../components/admin/AdminBannersComp';
import useFetch from '../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  // borderColor: "red",
};

const AdminBanners = () => {
  const { data: banners, isPending, isError } = useFetch('/api/v1/banners');

  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}

      {banners && (
        <Dashboard
          rightContainerContent={
            <AdminBannersComp
              banner_1={
                banners.data?.banners[0]?.bannerPhotoURL ||
                'https://uninotesstorageaccount.blob.core.windows.net/default-images/default_banner.jpg'
              }
              banner_2={
                banners.data?.banners[1]?.bannerPhotoURL ||
                'https://uninotesstorageaccount.blob.core.windows.net/default-images/default_banner.jpg'
              }
              banner_3={
                banners.data?.banners[2]?.bannerPhotoURL ||
                'https://uninotesstorageaccount.blob.core.windows.net/default-images/default_banner.jpg'
              }
            />
          }
        />
      )}
    </div>
  );
};

export default AdminBanners;
