import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
import { DashboardDropdown } from '../../shared/dashboard-form-elements/DropDown';
import { FaCamera } from 'react-icons/fa';
import TextBox from '../../shared/Form Elements/TextBox';
import * as c from './AddScholarshipsElements.js';
import { useState } from 'react';
import useFetch from '../../../hooks/useFeth';
// import toast from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import submitForm from '../../../hooks/submitForm';
// import validateImage from '../../../hooks/validateImage';
import { useNavigate } from 'react-router-dom';

const AddScholarships = () => {
  const navigate = useNavigate();

  const [cover, setCover] = useState(null);
  const [entryRequirements, setEntryRequirements] = useState('');

  const [name, setName] = useState('');
  const [validityPeriod, setValidityPeriod] = useState('');
  const [description, setDescription] = useState('');
  const handleCover = (e) => {
    setCover(e.target.files[0]);
    // validateImage(e.target.files[0], 1080, 1080, setCover);
  };

  const [courseDropDownVal, setCourseDropDownVal] = useState({
    label: 'Course',
    value: '',
  });

  let {
    data: courses,
    isError: courseListError,
    isPending: courseListPending,
  } = useFetch('/api/v1/courses/getMyCourses');

  if (courses) {
    console.log({ courses });
  }
  if (courses) {
    courses = courses.data.courses.map((course) => {
      return {
        label: course.courseName,
        value: course.id,
      };
    });
    // console.log(uniList);
  }

  // const handleSubmit = async (e, formData) => {
  //   try {
  //     const res = await axios({
  //       method: 'POST',
  //       url: `/api/v1/courses`,
  //       data: formData,
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     return res;
  //   } catch (err) {
  //     console.log(err.response.data);
  //     throw err;
  //   }
  // };

  const addScholarship = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('scholarName', name);
    formData.append('scholarCoverPhoto', cover);
    formData.append('scholarDescription', description.slice(0, 500));
    formData.append('scholarValidPeriod', validityPeriod);
    formData.append(
      'entryRequirements',
      entryRequirements.replace(/[\r\n]+/g, '')
    );
    formData.append('course', courseDropDownVal.value);

    if (!name) {
      return toast.error('Please provide Scholarship Name.');
    }
    if (!cover) {
      return toast.error('Please upload scholarship image cover.');
    }
    if (!description) {
      return toast.error('Please provide Scholarship Description.');
    }
    if (!validityPeriod) {
      return toast.error('Please provide Scholarship deadline.');
    }
    if (entryRequirements.length < 1) {
      return toast.error('Please provide Scholarship  entry requirements.');
    }
    if (!courseDropDownVal.value) {
      return toast.error('Please Select relevant course.');
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    if (!cover) {
      return toast.error('Please upload image cover.');
    }

    await toast.promise(
      submitForm('/api/v1/scholarships', formData, 'post', headers),
      {
        loading: 'Adding Scholarship...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.6rem',
        },
      }
    );

    navigate('/uni/scholarships');
  };

  return (
    <c.Container>
      <c.HeaderContainer>
        <c.HeaderTitle>Add a Scholarship</c.HeaderTitle>
      </c.HeaderContainer>
      <c.FormContainer onSubmit={addScholarship}>
        <c.Cover>
          <c.CoverSection>
            {cover ? (
              <c.CoverImage src={URL.createObjectURL(cover)} />
            ) : (
              <p></p>
            )}
          </c.CoverSection>
          <c.CoverIcon>
            <FaCamera />
            <input
              type="file"
              id="file1"
              accept="image/*"
              onChange={handleCover}
            />
          </c.CoverIcon>
          <c.Text>
            <p>Scholarship Cover Upload</p>
          </c.Text>
          <c.Text>
            <c.LogoPlaceholder>(1080px X 1080px)</c.LogoPlaceholder>
          </c.Text>
        </c.Cover>
        <c.Fields>
          <>
            <c.Label>Enter scholarship name.</c.Label>
            <DashboardTextField
              placeholder={'EX: Software Engineering Scholarship'}
              value={name}
              setValue={setName}
            />
          </>
          <>
            <c.Label>Select relevant course for scholarship.</c.Label>

            <DashboardDropdown
              dropDownValues={courses}
              currentDropdownVal={courseDropDownVal}
              setCurrentDropdownVal={setCourseDropDownVal}
            />
          </>
          <>
            <c.Label>Enter scholarship deadline.</c.Label>

            <DashboardTextField
              value={validityPeriod}
              setValue={setValidityPeriod}
              placeholder={'EX: 2023-01-31'}
            />
          </>
          <>
            <c.Label>Enter scholarship description.</c.Label>

            <TextBox
              value={description.slice(0, 500)}
              setValue={setDescription}
              placeholder={'Max 500 characters'}
            />
          </>
          <>
            <c.Label>
              Enter each requirement within square brackets "[ ]".
            </c.Label>

            <TextBox
              value={entryRequirements}
              setValue={setEntryRequirements}
              placeholder={'Ex:\n[Requirement 1]\n[Requirement 2]'}
            />
          </>
        </c.Fields>
        <c.Button>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
          />
        </c.Button>
      </c.FormContainer>
    </c.Container>
  );
};

export default AddScholarships;
