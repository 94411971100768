import React, { useState } from 'react';
import * as f from './FilterElements';
import { GoSearch } from 'react-icons/go';
import Pagination from '@mui/material/Pagination';
import useFetch from './../../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';
import NoRecordFound from '../../shared/NoRecordFound';

const override = {
  position: 'absolute',
  margin: '0 auto',
  top: '43%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};
const Filter = () => {

  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);

  let url = `/api/v1/universities?active=true&limit=${limit}&page=${page}&isAgency=false`;

  const { data, isError, isPending } = useFetch(url);

  let universities = [];

  if (data) {
    universities = data.data.universities.filter((uni) =>
      uni.name.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  return (
    <f.Section>
      <f.Container>
        <f.ContainerTop>
          <f.SearchBarContainer>
            <f.SearchInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search for Universities"
            />
            <f.SearchBtn>
              <GoSearch />
            </f.SearchBtn>
          </f.SearchBarContainer>

        </f.ContainerTop>

        <f.ContainerBody>
          <f.CardContainer>
            {isPending && (
              <ClipLoader color="#333" size={60} cssOverride={override} />
            )}
            {universities.length === 0 && !isPending && <NoRecordFound />}

            {data &&
              !isPending &&
              universities.map((uni, i) => {
                return (
                  <>
                    <f.Card to={`/universities/${uni.id}`} key={i}>
                      <f.CardLeft>
                        <f.Image src={uni.uniLogo} crossorigin="anonymous" />
                      </f.CardLeft>
                      <f.CardRight>
                        {/* <f.LocationRow></f.LocationRow> */}
                        <f.Text
                          fontfamily="var(--dashboard-font)"
                          fontweight="700"
                          color="#333"
                          fontsize="2rem"
                          // textalign="left"
                        >
                          {uni.name.toUpperCase()}
                        </f.Text>
                      </f.CardRight>
                    </f.Card>
                  </>
                );
              })}
          </f.CardContainer>
          {data && !isPending && universities.length !== 0 && (
            <f.PaginationContainer>
              <Pagination
                color="primary"
                defaultPage={page}
                // siblingCount={0}
                size="large"
                count={Math.floor(universities.length / limit) + 1}
                onChange={(e, val) => {
                  setPage(val);
                }}
              />
            </f.PaginationContainer>
          )}
        </f.ContainerBody>
      </f.Container>
    </f.Section>
  );
};

export default Filter;
