import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import { createGlobalStyle } from 'styled-components';
import './variables.css';
import WithoutNavAndFooter from './components/Routing/WithoutNavAndFooter';
import WithNavAndFooter from './components/Routing/WithNavAndFooter';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import StaffLogin from './pages/StaffLogin';
import SharedElements from './pages/SharedElements';
import AdminHome from './pages/admin/AdminHome';
import AdminBanners from './pages/admin/AdminBanners';
import AdminUniversities from './pages/admin/AdminUniversities';
import AdminUsers from './pages/admin/AdminUsers';
import AdminEvents from './pages/admin/AdminEvents';
import AdminCourses from './pages/admin/AdminCourses';
import UniversityHome from './pages/university/UniversityHome';
import UniversityListing from './pages/university/UniversityListing';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import StudentHome from './pages/student/StudentHome';
import StudentCourses from './pages/student/StudentCourses';
import StudentProfile from './pages/student/StudentProfile';
import StudentEvents from './pages/student/StudentEvents';
import StudentUniversities from './pages/student/StudentUniversities';
import UniversityProfile from './pages/university/dashboard pages/UniversityProfile';
import UniversityEvents from './pages/university/dashboard pages/UniversityEvents';
import UniversityDashHome from './pages/university/dashboard pages/UniversityDashHome';
import UniversitySingleEvent from './pages/university/dashboard pages/UniversitySingleEvent';
import UniversityInsights from './pages/university/dashboard pages/UniversityInsights';
import UniversityAdminProfile from './pages/university/dashboard pages/UniversityAdminProfile';
import UniversityCourses from './pages/university/dashboard pages/UniversityCourses';
import UniversitySingleCourse from './pages/university/dashboard pages/UniversitySingleCourse';
import UniversityScholarships from './pages/university/dashboard pages/UniversityScholarships.js';
import UniversitySingleScholarship from './pages/university/dashboard pages/UniversitySingleScholarship.js';
import UniversityCoursesInsights from './pages/university/dashboard pages/UniversityCoursesInsights.js';
import UniversityScholarshipsInsights from './pages/university/dashboard pages/UniversityScholarshipsInsights.js';

import useAuth from './hooks/useAuth';
import { useEffect } from 'react';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CoursesListing from './pages/CoursesListing';
import ToTopBtn from './components/back-to-top/ToTopBtn';
import UniversityAddEvent from './pages/university/dashboard pages/UniversityAddEvent';
import UniversityAddCourse from './pages/university/dashboard pages/UniversityAddCourse';
import UniversityAddScholarship from './pages/university/dashboard pages/UniversityAddScholarship.js';
import AddUsers from './pages/admin/AddUniversities';
import AddUniversities from './pages/admin/AddUniversities';
import SingleCourse from './pages/SingleCourse';
import ScholarshipListing from './pages/scholarships/ScholarshipListing';
import SingleScholarship from './pages/scholarships/SingleScholarship';
import ComingSoon from './pages/ComingSoon';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import StudyAbroad from './pages/StudyAbroad';
import RequireAuth from './components/Routing/RequireAuth';
// import Loading from './pages/Loading';
import StudyAbroadAgencies from './pages/admin/StudyAbroadAgencies';
import AddStudyAbroad from './pages/admin/AddStudyAbroad';
import AgencyCourses from './pages/admin/AgencyCourses';

function App() {
  const { loadUser } = useAuth();

  useEffect(() => {
    loadUser();
  }, []);

  const GlobalStyle = createGlobalStyle`
  @import url('./variables.css');
  
`;

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <Router>
        <GlobalStyle />
        <div className="App">
          <ToTopBtn />
          <Toaster
            containerStyle={{
              position: 'fixed',
              zIndex: '99999999999999999999999999999999999',
              width: 'maxContent',
            }}
            toastOptions={{
              className: '',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                fontSize: '1.5rem',
                position: 'sticky',
                textAlign: 'center',

                zIndex: '99999999999999999999999999999999999',
              },
            }}
          />
          <Routes>
            {/* 💥💥💥 rename /dashboard 💥💥💥 */}

            <>
              <Route element={<WithoutNavAndFooter />}>
                {/* =============== admin [SUPER-ADMIN ] dashboard paths start ============== */}
                {/* <Route element={<RequireAuth allowedRoles={['admin']} />}> */}
                <Route exact path="/admin/home" element={<AdminHome />} />
                {/* 💥💥💥 rename /dashboard 💥💥💥 */}
                <Route
                  exact
                  path="/admin/universities"
                  element={<AdminUniversities />}
                />
                <Route
                  exact
                  path="/admin/universities/add"
                  element={<AddUniversities />}
                />
                <Route
                  exact
                  path="/admin/study-abroad-agencies"
                  element={<StudyAbroadAgencies />}
                />
                <Route
                  exact
                  path="/admin/study-abroad-agencies/add"
                  element={<AddStudyAbroad />}
                />
                <Route
                  exact
                  path="/admin/study-abroad-agencies/courses"
                  element={<AgencyCourses />}
                />
                <Route exact path="/admin/users" element={<AdminUsers />} />
                <Route exact path="/admin/users/:id" element={<AddUsers />} />
                <Route exact path="/admin/events" element={<AdminEvents />} />
                <Route exact path="/admin/banners" element={<AdminBanners />} />
                <Route exact path="/admin/courses" element={<AdminCourses />} />
                {/* </Route> */}
                {/* =============== admin [SUPER-ADMIN ] dashboard paths end ============== */}
                {/* =============== student dashboard paths start ============== */}
                {/* <Route element={<RequireAuth allowedRoles={['admin']} />}> */}
                <Route exact path="/user/home" element={<StudentHome />} />
                {/* 💥💥💥 rename /dashboard 💥💥💥 */}
                <Route exact path="/user/events" element={<StudentEvents />} />
                {/* events that logged in student has followed or registered */}
                <Route
                  exact
                  path="/user/universities"
                  element={<StudentUniversities />}
                />{' '}
                {/* unis' that logged in student has followed or registered */}
                <Route
                  exact
                  path="/user/courses"
                  element={<StudentCourses />}
                />{' '}
                {/* courses that logged in student has followed or registered */}
                <Route
                  exact
                  path="/user/profile"
                  element={<StudentProfile />}
                />{' '}
                {/* courses that logged in student has followed or registered */}
                {/* </Route> */}
                {/* =============== student dashboard paths end ============== */}
                {/* =============== university representative dashboard paths start ============== */}
                {/* <Route
                  element={
                    <RequireAuth allowedRoles={['admin', 'university']} />
                  }
                > */}
                <Route
                  exact
                  path="/uni/home"
                  element={<UniversityDashHome />}
                />
                {/* 💥💥💥 rename /dashboard 💥💥💥 */}
                <Route
                  exact
                  path="/uni/public-profile"
                  element={<UniversityProfile />}
                />
                <Route
                  exact
                  path="/uni/insights"
                  element={<UniversityInsights />}
                />
                <Route
                  exact
                  path="/uni/admin/profile"
                  element={<UniversityAdminProfile />}
                />
                <Route
                  exact
                  path="/uni/events"
                  element={<UniversityEvents />}
                />
                <Route
                  exact
                  path="/uni/events/:id"
                  element={<UniversitySingleEvent />}
                />
                <Route
                  exact
                  path="/uni/Addevent"
                  element={<UniversityAddEvent />}
                ></Route>
                <Route
                  exact
                  path="/uni/courses"
                  element={<UniversityCourses />}
                />
                <Route
                  exact
                  path="/uni/courses/:id"
                  element={<UniversitySingleCourse />}
                />
                <Route
                  exact
                  path="/uni/Addcourse"
                  element={<UniversityAddCourse />}
                ></Route>
                <Route
                  exact
                  path="/uni/scholarships"
                  element={<UniversityScholarships />}
                />
                <Route
                  exact
                  path="/uni/scholarships/:id"
                  element={<UniversitySingleScholarship />}
                />
                <Route
                  exact
                  path="/uni/Addscholarship"
                  element={<UniversityAddScholarship />}
                />
                <Route
                  exact
                  path="/uni/course-insights"
                  element={<UniversityCoursesInsights />}
                />
                <Route
                  exact
                  path="/uni/scholarships-Insights"
                  element={<UniversityScholarshipsInsights />}
                />
                {/* </Route> */}
                {/* =============== university representative dashboard paths end ============== */}
              </Route>

              <Route element={<WithNavAndFooter />}>
                {/* <Route exact path="/" element={<Loading />} /> */}
                <Route exact path="/coming-soon" element={<ComingSoon />} />
                <Route exact path="/login" element={<Login />} />
                🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑
                {/* <Route
                  element={
                    <RequireAuth allowedRoles={['admin', 'university']} />
                  }
                > */}
                <Route exact path="/" element={<Home />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route
                  exact
                  path="/university-login"
                  element={<StaffLogin />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route exact path="/aboutus" element={<AboutUs />} />
                <Route
                  exact
                  path="/privacypolicy"
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path="/termsconditions"
                  element={<TermsConditions />}
                />
                <Route
                  exact
                  path="/shared-elements"
                  element={<SharedElements />}
                />
                {/* <Route exact path='/university-profile' element={<UniversityHome />} /> */}
                <Route
                  exact
                  path="/universities"
                  element={<UniversityListing />}
                />
                <Route
                  exact
                  path="/universities/:id"
                  element={<UniversityHome />}
                />
                <Route exact path="/courses" element={<CoursesListing />} />
                <Route exact path="/courses/:id" element={<SingleCourse />} />
                <Route
                  exact
                  path="/scholarships"
                  element={<ScholarshipListing />}
                />
                <Route
                  exact
                  path="/scholarships/:id"
                  element={<SingleScholarship />}
                />
                <Route exact path="/study-abroad" element={<StudyAbroad />} />
                <Route exact path="/not-found" element={<NotFound />} />
                {/* </Route> */}
                <Route path="*" element={<NotFound />} />
              </Route>
            </>
          </Routes>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
