import LoginForm from "../components/login/LoginForm";

const Login = () => {
    return ( 
        <>
            <LoginForm/>
        </>
     );
}
 
export default Login;