import WelcomePageCard from '../../components/shared/WelcomePageCard';
import { FiEdit, FiUsers } from 'react-icons/fi';

const cards = [
  {
    element: (
      <WelcomePageCard
        icon={<FiEdit />}
        cardcolor="#EBFAF0"
        iconcirclecolor="#CCEBD7"
        text="Register New Universities"
        url="/admin/universities/add"
      />
    ),
  },
  {
    element: (
      <WelcomePageCard
        icon={<FiEdit />}
        cardcolor="#F0F0FF"
        iconcirclecolor="#D9D9FC"
        text="Register New Study Abroad Agency"
        url="/admin/study-abroad-agencies/add"
      />
    ),
  },
  // {
  //   element: (
  //     <WelcomePageCard
  //       icon={<FiActivity />}
  //       cardcolor="#EBFAF0"
  //       iconcirclecolor="#CCEBD7"
  //       text="View Analytics"
  //       url="/admin/user/add"
  //     />
  //   ),
  // },
  {
    element: (
      <WelcomePageCard
        icon={<FiUsers />}
        cardcolor="#FFF9E5"
        iconcirclecolor="#FCEEC5"
        text="View Users"
        url="/admin/users"
      />
    ),
  },
  // {
  //     element: <WelcomePageCard icon={<FiUsers />} cardcolor='#FFF9E5' iconcirclecolor='#FCEEC5' text='View Users' />,
  //     url:'/admin/user/add'
  // },
];

export { cards as adminCards };
