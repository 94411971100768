import styled from 'styled-components';
// import banner_1 from './../../images/default_banners/1.webp';
// import banner_2 from './../../images/default_banners/2.webp';

export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  display: ${(props) => props.display || 'block'};
`;
export const Section = styled.div`
  width: 100%;
  min-height: 30rem;
  ${'' /* background-color:red ; */}
  display:flex;
  padding: 3rem 25rem;
  overflow: hidden;

  @media (max-width: 2561px) {
    padding: 2rem 40rem;
  }
  @media (max-width: 2200px) {
    padding: 2rem 30rem;
  }
  @media (max-width: 1900px) {
    padding: 2rem 25rem;
  }
  @media (max-width: 1600px) {
    padding: 2rem 12rem;
  }

  @media (max-width: 1000px) {
    padding: 2rem 6rem;
  }
  @media (max-width: 600px) {
    min-height: 20rem;
  }

  @media (max-width: 550px) {
    padding: 2rem 4rem;
  }
  @media (max-width: 500px) {
    padding: 0rem 2rem;
  }
`;

export const Container = styled.div`
  flex: 0 1 100%;
  /* background-color: green; */
  ${'' /* min-height:35rem ; */}
  display:flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 2rem 0;
  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const BannerRow = styled.div`
  flex: 0 1 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

export const CarousalContainer = styled.div`
  ${'' /* flex:0 1 100% ; */}
  ${'' /* width:100% ; */}
    ${'' /* width:100vw ; */}
    ${'' /* overflow:hidden ; */}
    ${'' /* background-color:yellow ; */}
    ${'' /* padding:3rem ; */}
`;

export const Banner = styled.div`
  /* background-color: var(--secondary-blue); */
  /* background-color: #eee; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  /* border-radius: 3rem; */
  padding: 1rem;

  @media (max-width: 2560px) {
    min-height: 20rem;
    width: 100%;
  }
  @media (max-width: 2000px) {
    min-height: 17rem;
    width: 100%;
  }
  @media (max-width: 1800px) {
    min-height: 15rem;
    width: 100%;
  }
  @media (max-width: 1300px) {
    min-height: 10rem;
    width: 100%;
  }
  @media (max-width: 800px) {
    min-height: 5rem;
    width: 100%;
  }
  @media (max-width: 600px) {
    min-height: 10rem;
    width: 100%;
  }
`;

export const BannerImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
