import React, { useState } from 'react';
import styled from 'styled-components';
import Datepicker from '../components/shared/dashboard-form-elements/DatePicker';
import { DashboardDropdown } from '../components/shared/dashboard-form-elements/DropDown';
import { DashboardTextField } from '../components/shared/dashboard-form-elements/TextField';
import AutoCompleteDropdown from '../components/shared/Form Elements/AutoCompleteDropdown';
import PasswordField from '../components/shared/Form Elements/PasswordField';
import RegularButton from '../components/shared/Form Elements/RegularButton';
import TextBox from '../components/shared/Form Elements/TextBox';
import TextField from '../components/shared/Form Elements/TextField';
import LinkButton from '../components/shared/LinkButton';
import CheckBox from '../components/shared/Form Elements/CheckBox';

const Container = styled.div`
  width: 100%;
  min-height: 50rem;
  ${'' /* background-color:#333 ; */}
  padding:3rem 10rem;
`;

const ElementContainer = styled.div`
  width: ${(props) => props.length || '200px'};
  margin: 2rem 0;
  background-color: ${(props) => props.baccolor || '#eee'};
  padding: 3rem;
`;

const SharedElements = () => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  const [name, setName] = useState('');

  const [time, setTime] = useState('');
  const timeSlots = ['12 AM', '01 AM', '02 AM', '03 AM'];

  const [uni, setUni] = useState({
    label: 'Select university',
    value: '',
  });
  const uniList = [
    { label: 'nsbm', value: 'NSBM' },
    { label: 'sllit', value: 'SLLIT' },
    { label: 'iit', value: 'IIT' },
    { label: 'nibm', value: 'NIBM' },
  ];

  const regularBtnClickHandler = () => {
    alert('regular btn clicked');
  };

  return (
    <>
      <Container>
        <h1>this page contains previews of shared elemnts</h1>
        <br />
        <h4>typed into email field : {email}</h4>
        <br />
        <h4>typed into password field : {pwd}</h4>
        <br />
        <h4>typed into name field : {name}</h4>
        <br />
        <h4>selected in dropdown : {name}</h4>

        <ElementContainer>
          <LinkButton
            backgroundcolor={'red'}
            btntext={'click me'}
            to={'/shared-elements'}
          />
        </ElementContainer>

        {/* ======================================================================================= */}

        <ElementContainer length={'500px'}>
          <TextField value={email} setValue={setEmail} placeholder={'Email'} />
        </ElementContainer>

        {/* ======================================================================================= */}

        <ElementContainer length={'500px'}>
          <PasswordField
            value={pwd}
            setValue={setPwd}
            placeholder={'password'}
          />
        </ElementContainer>

        {/* ======================================================================================= */}

        <ElementContainer length={'500px'}>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'green'}
            textcolor={'white'}
            btntext={'regular button'}
            onClick={regularBtnClickHandler}
          />
        </ElementContainer>

        {/* ======================================================================================= */}

        <ElementContainer length={'500px'} baccolor="#fff">
          <DashboardTextField
            placeholder="dashboard text field"
            value={name}
            setValue={setName}
            required={true}
          />
        </ElementContainer>
        {/* ======================================================================================= */}

        <ElementContainer length={'180px'} baccolor="#fff">
          <DashboardDropdown
            dropDownValues={timeSlots}
            currentDropdownVal={time}
            setCurrentDropdownVal={setTime}
          />
        </ElementContainer>

        <ElementContainer length={'500px'} baccolor="#fff">
          <TextBox />
        </ElementContainer>

        <ElementContainer>
          <Datepicker />
        </ElementContainer>

        <ElementContainer length={'500px'}>
          <AutoCompleteDropdown
            dropDownValues={uniList}
            currentDropdownVal={uni}
            setCurrentDropdownVal={setUni}
          />
        </ElementContainer>

        <ElementContainer length={'500px'}>
          <CheckBox />
        </ElementContainer>
      </Container>
    </>
  );
};

export default SharedElements;
