import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  display: ${(props) => props.display || 'block'};
  text-align: ${(props) => props.display || 'left'};

  @media (max-width: 426px) {
    font-size: 1.6rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
  text-align: ${(props) => props.display || 'flex-start'};
`;

export const Section = styled.div`
  width: 100%;
  min-height: 30rem;
  display: flex;
  padding: 2rem 25rem;
  ${'' /* background-color:red ; */}

  @media (max-width: 2561px) {
    padding: 3rem 50rem;
  }
  @media (max-width: 2200px) {
    padding: 3rem 40rem;
  }
  @media (max-width: 2000px) {
    padding: 3rem 35rem;
  }
  @media (max-width: 1800px) {
    padding: 3rem 30rem;
  }
  @media (max-width: 1700px) {
    padding: 1rem 20rem;
  }

  @media (max-width: 1000px) {
    padding: 2rem 15rem;
  }
  @media (max-width: 800px) {
    padding: 2rem 8rem;
  }
  @media (max-width: 600px) {
    padding: 2rem 6rem;
  }
  @media (max-width: 500px) {
    padding: 2rem 4rem;
  }
  @media (max-width: 380px) {
    padding: 2rem 2rem;
  }
`;

export const Container = styled.div`
  flex: 0 1 100%;
  min-height: 20rem;
  ${'' /* background-color:green ; */}
  display:flex;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--ternary-blue);
  border-radius: 4rem;

  @media (max-width: 600px) {
    padding: 1rem;
  }
  @media (max-width: 600px) {
  }
`;

export const ContainerTop = styled.div`
  flex: 0 1 40%;
  padding: 1rem;
  display: flex;
  border-bottom: 1px solid #999;
`;

export const ContainerBody = styled.div`
  flex: 0 1 100%;
  /* display: flex; */

  @media (max-width: 900px) {
    display: grid;
  }
`;

export const ContainerLeft = styled.div`
  flex: 0 1 30%;
  ${'' /* background-color:red ; */}
  border-right:1px solid #999;
`;

export const CardContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  ${'' /* background-color:green ; */}
  /* background-color: red; */
  padding: 3rem;
  /* min-height: 70rem; */

  @media (max-width: 1230px) {
    padding: 1rem;
  }
  @media (max-width: 426px) {
    padding: 0rem;
  }
`;

export const Card = styled(Link)`
  flex: 0 1 30%;
  background-color: #fff;
  border-radius: 3rem;
  margin: 1rem 0;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @media (max-width: 1230px) {
    padding: 3rem 1rem;
    font-size: 1.2rem;
  }
  @media (max-width: 1150px) {
    flex: 0 1 45%;
  }
  @media (max-width: 426px) {
    flex-direction: row;
    flex: 0 1 100%;
  }
`;

export const CardLeft = styled.div`
  /* flex: 0 1 10%; */
  /* min-height: 10rem; */
  /* background-image: url(${(props) => props.img}); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 426px) {
    flex: 1;
  }
`;

export const Image = styled.img`
  width: 50%;
  height: auto;

  @media (max-width: 426px) {
    width: 50%;
    height: auto;
  }
`;
export const CardRight = styled.div`
  flex: 0 1 30%;
  /* background-color: green; */
  padding: 2rem 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 426px) {
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }
`;
export const LocationRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #fff;
  outline: none;
  border: 3px solid var(--fourth-blue);
  border-radius: 5rem;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const SearchBarContainer = styled.div`
  position: relative;
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 760px) {
    flex: 0 1 100%;
  }
`;

export const SearchBtn = styled.div`
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 9%;
  font-size: 5rem;
  color: var(--fourth-blue);

  :hover {
    cursor: pointer;
  }
  @media (max-width: 1600px) {
    right: 12%;
  }
  @media (max-width: 1200px) {
    right: 16%;
    width: 2.2rem;
    height: 2.2rem;
  }
  @media (max-width: 700px) {
    right: 20%;
    width: 1.8rem;
    height: 1.8rem;
  }
  @media (max-width: 500px) {
    right: 22%;
    width: 1.5rem;
    height: 1.5rem;
  }
  @media (max-width: 400px) {
    right: 24%;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const DropDownContainer = styled.div`
  flex: 0 1 20%;
  padding: 0 1rem;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;
