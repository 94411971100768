// export const locations = [
//   {id: 1, label: "Colombo"},
//   {id: 2, label: "Kandy"},
//   {id: 3, label: "Galle"},
//   {id: 4, label: "Ampara"},
//   {id: 5, label: "Anuradhapura"},
//   {id: 6, label: "Badulla"},
//   {id: 7, label: "Batticaloa"},
//   {id: 8, label: "Gampaha"},
//   {id: 9, label: "Hambanthota"},
//   {id: 10, label: "Jaffna"},
//   {id: 11, label: "Kaluthara"},
//   {id: 12, label: "Kegalle"},
//   {id: 13, label: "Kilinochchi"},
//   {id: 14, label: "Kurunagala"},
//   {id: 15, label: "Mannar"},
//   {id: 16, label: "Mathale"},
//   {id: 17, label: "Mathara"},
//   {id: 18, label: "Mullativu"},
//   {id: 19, label: "Moneragala"},
//   {id: 20, label: "Nuwara Eliya"},
//   {id: 21, label: "Polonnaruwa"},
//   {id: 22, label: "Puttalam"},
//   {id: 23, label: "Rathnapura"},
//   {id: 24, label: "Thrincomalee"},
//   {id: 25, label: "Vavuniya"},
// ];

export const locations = [
  "Colombo",
  "Kandy",
  "Galle",
  "Ampara",
  "Anuradhapura",
  "Badulla",
  "Batticaloa",
  "Gampaha",
  "Hambanthota",
  "Jaffna",
  "Kaluthara",
  "Kegalle",
  "Kilinochchi",
  "Kurunagala",
  "Mannar",
  "Mathale",
  "Mathara",
  "Mullativu",
  "Moneragala",
  "Nuwara Eliya",
  "Polonnaruwa",
  "Puttalam",
  "Rathnapura",
  "Thrincomalee",
  "Vavuniya",
];
