import React, { useState, useEffect } from 'react';
import * as c from './SingleCourseElements';
import { useParams } from 'react-router-dom';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import TextBox from '../../shared/Form Elements/TextBox';
import RegularButton from '../../shared/Form Elements/RegularButton';
import { FaCamera } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import submitForm from '../../../hooks/submitForm';
// import validateImage from '../../../hooks/validateImage';

const SingleScholarship = () => {
  const { id } = useParams();

  const [name, setName] = useState('');
  const [cover, setCover] = useState(null);
  const [description, setDescription] = useState('');
  const [validPeriod, setValidPeriod] = useState('');
  const [entryRequirements, setEntryRequirements] = useState('');

  const updateSchol = async (e) => {
    e.preventDefault();

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    if (description.replace(/[\r\n]/g, '').length > 500) {
      return toast.error(
        'Course descriptions character limit is exceeded. Limit is 500 characters.',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    }

    const formData = new FormData();
    formData.append('scholarName', name);
    formData.append('cover', cover);
    formData.append('scholarDescription', description);
    formData.append('scholarValidPeriod', validPeriod);
    formData.append(
      'entryRequirements',
      entryRequirements.replace(/[\r\n]+/g, '')
    );

    // console.log({ cover });

    await toast.promise(
      submitForm(`/api/v1/scholarships/${id}`, formData, 'patch', headers),
      {
        loading: 'Updating Scholarship...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  const handleCover = (e) => {
    setCover(e.target.files[0]);
    // validateImage(e.target.files[0], 600, 600, setCover);
  };

  useEffect(() => {
    //   const { data, isError, isPending } = useFetch(`/api/v1/courses/${id}`);
    fetch(`/api/v1/scholarships/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error loading data');
        }
        return response.json();
      })
      .then((data) => {
        // console.log({ data });
        setName(data.data.scholarship.scholarName);
        setCover(data.data.scholarship.scholarCoverPhoto);
        setDescription(data.data.scholarship.scholarDescription);
        setValidPeriod(data.data.scholarship.scholarValidPeriod);
        setEntryRequirements(
          data.data.scholarship.entryRequirements || 'not available'
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);
  return (
    <>
      <c.HeaderContainer>
        <c.HeaderTitle>Update Scholarship</c.HeaderTitle>
      </c.HeaderContainer>
      <c.FormContainer onSubmit={updateSchol}>
        <c.Cover>
          <c.CoverSection>
            {cover ? (
              <c.CoverImage
                img={
                  typeof cover === 'object' ? URL.createObjectURL(cover) : cover
                }
              />
            ) : (
              <p></p>
            )}
          </c.CoverSection>
          <c.CoverIcon>
            <FaCamera />
            <input
              type="file"
              id="file1"
              accept="image/*"
              onChange={handleCover}
            />
          </c.CoverIcon>
          <c.Text>
            <p>Scholarship Cover Upload</p>
          </c.Text>
        </c.Cover>
        <c.Fields>
          <DashboardTextField
            value={name}
            setValue={setName}
            placeholder={'Scholarship name'}
          />

          <TextBox
            value={description}
            setValue={setDescription}
            placeholder={'Scholarship Description'}
          />
          <TextBox
            value={entryRequirements}
            setValue={setEntryRequirements}
            placeholder={
              'Enter entry requirements as below (Seperated by "," ) \n  \nEx:\nRequirement 1,\nRequirement 2'
            }
          />

          <DashboardTextField
            value={validPeriod}
            setValue={setValidPeriod}
            placeholder={'Scholarship ending date. \tEX: 2023-01-31'}
          />
        </c.Fields>
        <c.ButtonSection>
          {/* <c.Button>
              <RegularButton
                fontweight={'bold'}
                backgroundcolor={'var(--fourth-blue)'}
                textcolor={'white'}
                btntext={'Edit'}
                onClick={handleEdit}
              />
            </c.Button> */}
          <c.Button>
            <RegularButton
              type="submit"
              fontweight={'bold'}
              backgroundcolor={'var(--fourth-blue)'}
              textcolor={'white'}
              btntext={'Update'}
            />
          </c.Button>
        </c.ButtonSection>
      </c.FormContainer>
    </>
  );
};

export default SingleScholarship;
