import React from 'react'
import Dashboard from '../../../components/shared/Dashboard'
import Insights from '../../../components/university/dashboard-components/Insights'


const UniversityInsights = () => {
  return (
    <div>
     <Dashboard
        rightContainerContent={
          <>
            <Insights/>
          </>
        }
      />
    </div>
  )
}

export default UniversityInsights