import React from 'react'
import * as e from './EventsElements.js'

const Events = () => {
  return (
      <e.Container>
           <e.HeaderContainer>
        
        <e.HeaderTitle>Events</e.HeaderTitle>



      </e.HeaderContainer>
      </e.Container>
  )
}

export default Events