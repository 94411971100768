import styled from 'styled-components';
import logo from './../../images/logos/uninotes_logo.png';
import logo_withoutText from './../../images/logos/logo_with_text.png';
import { Link } from 'react-router-dom';

export const LoginSection = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled.div`
  flex: 0 1 45%;
  background-color: var(--ternary-blue);
  min-height: 60rem;
  display: flex;
  border-radius: 3rem;

  @media (max-width: 1400px) {
    flex: 0 1 50%;
  }
  @media (max-width: 1300px) {
    flex: 0 1 55%;
  }
  @media (max-width: 1200px) {
    flex: 0 1 60%;
  }
  @media (max-width: 1100px) {
    flex: 0 1 65%;
  }
  @media (max-width: 1000px) {
    flex: 0 1 70%;
  }
  @media (max-width: 900px) {
    flex: 0 1 80%;
  }
  @media (max-width: 760px) {
    min-height: 50rem;
  }
  @media (max-width: 500px) {
    flex: 0 1 90%;
    min-height: 45rem;
  }
`;

export const LoginLeftSection = styled.div`
  flex: 0 1 30%;
  background-color: var(--main-blue);
  position: relative;
  z-index: 10;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const LoginRightSection = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoginRightTopSection = styled.div`
  flex: 0 30%;
  ${'' /* background-color:red ; */}
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 3rem;
  margin: 5rem 0 0;
  /* font-family: var(--dashboard-font); */
`;

export const LoginRIghtFormContainer = styled.div`
  flex: 1;
  ${'' /* background-color:green ; */}
  padding:0 3rem;
  @media (max-width: 500px) {
    padding: 0 1rem;
  }
`;

export const ImageContainer = styled(Link)`
  width: 5rem;
  height: 5rem;
  background-image: url(${logo_withoutText});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginTitle = styled.p`
  font-weight: ${(props) => props.fontweight || 'bolder'};
  font-size: ${(props) => props.fontsize || '1.8rem'};
  color: var(--primary-font-color);
  margin: 2rem 0 0;
`;

export const InputWrappers = styled.div`
  flex: 0 1 80%;
  margin: 2rem 0;
  ${'' /* background-color:red ; */}
  ${'' /* min-height:2rem ; */}
`;

export const LeftLogoContainer = styled.div`
  width: 70%;
  height: 5rem;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 4rem 0;
`;

export const CustomGoogleBtn = styled.button`
  width: 100%;
  background-color: var(--white);
  outline: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4rem;
  padding: 1rem 3rem;

  :hover {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  ${'' /* background-color:red ; */}
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const GoogleBtnTextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
`;

export const TextOR = styled.p`
  font-size: 1.3rem;
  text-align: center;
  font-weight: bolder;
  color: var(--form-password-icon-color);
`;
export const ForgotPassword = styled.div`
  display: flex;
  justify-content: right;
`;
export const ForgotPasswordText = styled.p`
  font-size: 1.3rem;
  text-align: center;
  font-weight: bolder;
  color: var(--form-input-text-color);
`;
