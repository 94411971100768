import React from 'react';
import styled from 'styled-components';
// import img from './../images/coming_soon.jpg';
import img from './../images/coming_soon_new.gif';
import logo from './../images/new_logos/Logo type 1/uninotes-logo-2.png';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999;
  /* padding: 5rem; */
  /* background-color: red; */
`;

const Left = styled.div`
  min-height: 100vh;
  flex: 0 1 50%;
  /* background-color: var(--main-blue); */
  background-color: #023e72;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex: 0 1 40%;
  }
  @media (max-width: 763px) {
    flex: 0 1 100%;
  }
`;
const Right = styled.div`
  flex: 1;
  min-height: 100vh;
  background-color: var(--main-blue);
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LogoContainer = styled.div`
  width: 60%;
  min-height: 10rem;
  /* background-color: red; */
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const TextContainer = styled.div`
  width: 60%;
  /* min-height: 20rem; */
  text-align: center;
  /* background-color: green; */

  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 80%;
  }
  @media (max-width: 370px) {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
`;

const ComingSoon = () => {
  return (
    <Container>
      <Left>
        <LogoContainer />
        <TextContainer>
          <Text>COME BACK LATER FOR OUR BIG REVEAL.</Text>
          <Text>WE PROMISE, IT'LL BE WORTH IT.</Text>
        </TextContainer>
      </Left>
      <Right></Right>
    </Container>
  );
};

export default ComingSoon;
