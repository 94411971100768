import React from 'react'
import * as i from './InsightsElements'

const Insights = () => {
  return (
    <i.Container>
      <i.HeaderContainer>
        
        <i.HeaderTitle>Insights</i.HeaderTitle>

      </i.HeaderContainer>
    </i.Container>
  )
}

export default Insights