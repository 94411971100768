import React from 'react'
import { AddUniversityForm } from '../../components/admin/AddUniversity'
import Dashboard from '../../components/shared/Dashboard'

const AddUniversities = () => {
  return (
      <>
          <Dashboard
              rightContainerContent={
                  <>
                      <AddUniversityForm/>
                  </>
              }
          />
    </>
  )
}

export default AddUniversities