import React from 'react';
import { AdminUsersComponent } from './../../components/admin/AdminUsers';
import Dashboard from '../../components/shared/Dashboard';
import useFetch from '../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';

const AdminUsers = () => {
  const { data, isPending, isError } = useFetch('/api/v1/users');

  const override = {
    position: 'absolute',
    margin: '0 auto',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // borderColor: "red",
  };

  if (data) {
    // console.log({ data });
  }
  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {data && (
        <Dashboard
          rightContainerContent={
            <AdminUsersComponent usersList={data.data.users} />
          }
        />
      )}

      {/* <Dashboard rightContainerContent={<AdminUsersComponent />} /> */}
    </div>
  );
};

export default AdminUsers;
