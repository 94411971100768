import React, { useEffect, useState } from 'react';
import * as l from './SignUpElements';
import TextField from './../shared/Form Elements/TextField';
import PasswordField from './../shared/Form Elements/PasswordField';
import RegularButton from './../shared/Form Elements/RegularButton';
import { FcGoogle } from 'react-icons/fc';
import useAuth from './../../hooks/useAuth';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [mobile, setMobile] = useState('');

  const { signup, continueWithGoogle, user, loading, isAuthenticated } =
    useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user && !loading) {
      switch (user?.role) {
        case 'admin':
          navigate('/admin/home');
          break;
        case 'university':
          navigate('/uni/home');
          break;
        case 'user':
          navigate('/user/home');
          break;

        default:
          navigate('/login');
      }
    }
  }, [isAuthenticated, user]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      //   console.log({codeResponse})
      const googleAccessToken = codeResponse.access_token;

      toast.promise(
        continueWithGoogle({ googleAccessToken }),
        {
          loading: 'Signing Up ...',
          success: (data) => `Signed Up successfully `,
          error: (err) => {
            if (!err.response.data.message) {
              return 'Something went wrong. Try again.';
            }
            return `${err?.response?.data?.message?.toString()}`;
          },
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // signup({ email, password, passwordConfirm })
    toast.promise(
      signup({ email, password, passwordConfirm, mobile }),
      {
        loading: 'Signing Up ...',
        success: (data) => `Signed Up successfully `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  return (
    <l.SignUpSection>
      <l.SignUpContainer>
        <l.SignUpLeftSection>
          <l.LeftLogoContainer />
        </l.SignUpLeftSection>

        <l.SignUpRightSection>
          <l.SignUpRightTopSection>
            <l.ImageContainer to={'/'} />
            <l.SignUpTitle>Signup to your account</l.SignUpTitle>
          </l.SignUpRightTopSection>
          <l.SignUpRIghtFormContainer>
            <form onSubmit={handleSubmit}>
              <l.InputWrappers>
                <TextField
                  required={true}
                  value={mobile}
                  setValue={setMobile}
                  placeholder={'Contact Number'}
                />
              </l.InputWrappers>
              <l.InputWrappers>
                <TextField
                  required={true}
                  value={email}
                  setValue={setEmail}
                  placeholder={'Email'}
                />
              </l.InputWrappers>
              <l.InputWrappers>
                <PasswordField
                  required={true}
                  value={password}
                  setValue={setPassword}
                  placeholder={'Password'}
                />
              </l.InputWrappers>
              <l.InputWrappers>
                <PasswordField
                  required={true}
                  value={passwordConfirm}
                  setValue={setPasswordConfirm}
                  placeholder={'Re-enter Password'}
                />
              </l.InputWrappers>
              <l.ButtonSection>
                <RegularButton
                  fontweight={'700'}
                  textcolor={'var(--white)'}
                  btntext={'Signup'}
                  padding={'1.2rem 3rem'}
                  backgroundcolor={'var(--main-blue)'}
                />
              </l.ButtonSection>
              <l.AlreadyAccText>Have an account already?</l.AlreadyAccText>
              <l.LinkText to="/login"> Log in</l.LinkText>
            </form>
            <l.TextOR>OR</l.TextOR>
            <l.InputWrappers>
              <l.CustomGoogleBtn onClick={() => googleLogin()}>
                <l.IconContainer>
                  {' '}
                  <FcGoogle />{' '}
                </l.IconContainer>
                <l.GoogleBtnTextContainer>
                  Continue with Google
                </l.GoogleBtnTextContainer>
              </l.CustomGoogleBtn>
            </l.InputWrappers>
          </l.SignUpRIghtFormContainer>
        </l.SignUpRightSection>
      </l.SignUpContainer>
    </l.SignUpSection>
  );
};

export default SignUpForm;
