import React from 'react'
import Dashboard from '../../../components/shared/Dashboard'
import Events from '../../../components/university/dashboard-components/Events'
const UniversityEvents = () => {
  return (
      <div>
        <Dashboard
        rightContainerContent={
          <>
            <Events/>
          </>
        }
      />
      </div>
  )
}

export default UniversityEvents