import * as u from './ProfileElements';
import React, { useState, useEffect } from 'react';
import { FaCamera } from 'react-icons/fa';
import { DashboardTextField } from '../shared/dashboard-form-elements/TextField';
import RegularButton from '../shared/Form Elements/RegularButton';
// import TextBox from "../shared/Form Elements/TextBox";
// import TextField from "../shared/Form Elements/TextField";
import { Button, useTheme } from '@mui/material';

import useAuth from './../../hooks/useAuth';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState(user?.name || 'name');
  const [userId] = useState(user?.id);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [googleUser, setGoogleUser] = useState();

  const [logo, setLogo] = useState(null);

  const theme = useTheme();
  useEffect(() => {
    fetch(`/api/v1/users/googleUser/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error loading data');
        }
        return response.json();
      })
      .then((data) => {
        setGoogleUser(true);
        if (data.hasPassword) {
          setGoogleUser(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId, setGoogleUser, googleUser]);

  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
  };

  /////////////////// Update Student Profile Details (START) ///////////////////
  const updateProfile = async () => {
    const formData = { name };
    return toast.promise(
      fetch(`/api/v1/users/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Error updating name');
        }
        return response.json();
      }),
      {
        loading: 'Updating name...',
        success: 'Name updated successfully!',
        error: 'Failed to update name',
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };
  /////////////////// Update Student Profile Details (END) ///////////////////

  //////////////////// Deactivate Student Profile (START) ///////////////////
  const deactivateProfile = async () => {
    var result = window.confirm(
      'Are you sure you want to Delete the account? This cannot be undone!'
    );
    if (result === true) {
      return toast.promise(
        fetch(`/api/v1/users/${userId}`, {
          method: 'DELETE',
          //body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete the account');
          }
          logout();
          navigate('/login');
          return response.json();
        }),
        {
          loading: 'Deleting the account...',
          success: 'Account deleted!',
          error: 'Failed to delete the account',
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    }
  };
  //////////////////// Deactivate Student Profile (END) ///////////////////

  ////////////////// Update Student Profile Password (START) //////////////////
  const updatePassword = async () => {
    const formData = { password, newPassword, passwordConfirm };

    return toast.promise(
      fetch('/api/v1/auth/update-my-password', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Error updating the password');
        }
        return response.json();
      }),
      {
        loading: 'Updating password...',
        success: 'Password updated successfully!',
        error: 'Failed to update password',
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };
  ////////////////// Update Student Profile Password (END) //////////////////
  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>Profile</u.HeaderTitle>
      </u.HeaderContainer>
      <u.FormContainer>
        <u.Logo>
          <u.ImageSubSec>
            {logo ? <u.LogoImage src={URL.createObjectURL(logo)} /> : <p></p>}
          </u.ImageSubSec>
          <u.Icon>
            <FaCamera />
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={handleLogo}
            />
          </u.Icon>
          <u.Text>
            <p>Profile Picture</p>
          </u.Text>
        </u.Logo>
        <u.Fields>
          <DashboardTextField
            placeholder={'Email'}
            value={user?.email || 'email'}
            disabled
          />
          <DashboardTextField
            placeholder="Name"
            value={name}
            setValue={setName}
            disabled={false}
          />
        </u.Fields>

        <u.Button>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
            onClick={updateProfile}
          />
        </u.Button>
      </u.FormContainer>

      <u.HeaderContainer>
        {!googleUser ? <u.HeaderTitle>Update Password</u.HeaderTitle> : <></>}
      </u.HeaderContainer>
      {!googleUser ? (
        <u.FormContainer>
          <u.Fields>
            <DashboardTextField
              placeholder={'Current Password'}
              value={password}
              setValue={setPassword}
            />
            <DashboardTextField
              placeholder={'New Password'}
              value={newPassword}
              setValue={setNewPassword}
            />
            <DashboardTextField
              placeholder={'Confirm New Password'}
              value={passwordConfirm}
              setValue={setPasswordConfirm}
            />
          </u.Fields>
          <u.Button>
            <RegularButton
              fontweight={'bold'}
              backgroundcolor={'var(--fourth-blue)'}
              textcolor={'white'}
              btntext={'Submit'}
              onClick={updatePassword}
            />
          </u.Button>
        </u.FormContainer>
      ) : (
        <></>
      )}
      <u.HeaderContainer>
        <Button
          sx={{
            borderRadius: '30px',
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.2rem',
            },
          }}
          size={theme.breakpoints.down('sm') ? 'small' : 'large'}
          color="error"
          variant="contained"
          onClick={deactivateProfile}
        >
          Delete My Account
        </Button>
      </u.HeaderContainer>
    </u.Container>
  );
};

export default Profile;
