import React, { useState, useEffect } from 'react';
import * as c from './ScholarshipFilterElements';
// import DropDown from '../shared/Form Elements/DropDown';
import Pagination from '@mui/material/Pagination';
import ClipLoader from 'react-spinners/ClipLoader';
import useFetch from '../../hooks/useFeth';
import { GoSearch } from 'react-icons/go';
// import { TbCertificate } from 'react-icons/tb';
import AutoCompleteDropdown from '../shared/Form Elements/AutoCompleteDropdown';
import NoRecordFound from '../shared/NoRecordFound';
import CheckBox from '../shared/Form Elements/CheckBox';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { VscSettings } from 'react-icons/vsc';
import ad1 from './../../images/default_banners/5.webp';
import ad2 from './../../images/default_banners/6.webp';

const ScholarshipFilter = ({ uniList, setPopup, setUniversity, setCourse }) => {
  const [keyWord, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [category, setCategory] = useState([]);

  const [checkboxState, setCheckboxState] = useState({
    'Architecture & Design': false,
    'Arts & Humanities': false,
    Aviation: false,
    'Beauty & Hair': false,
    'Business & Management': false,
    'Computing & IT': false,
    Construction: false,
    Engineering: false,
    'Event Management & Tourism': false,
    'Food Science & Agriculture': false,
    Finance: false,
    'Hotel & Hospitality': false,
    'Journalism & Media Studies': false,
    Languages: false,
    'Law & Administration': false,
    Marketing: false,
    'Medicine and Health': false,
    'Military Sciences': false,
    Marine: false,
    'Professional Certifications': false,
    'Physical Science': false,
    'Social Science': false,
    'Soft Skill Development': false,
    'Sports & Coaching': false,
  });

  const [formatDropDownVal, setFormatDropDownVal] = useState({
    label: 'Format',
    value: '',
  });

  const [qualificationDropDownVal, setQualificationDropDownVal] = useState({
    label: 'Qualification',
    value: '',
  });
  const [entryLevelDropDownVal, setEntryLevelDropDownVal] = useState({
    label: 'Entry level',
    value: '',
  });
  const [uniDropDownVal, setUniDropDownVal] = useState({
    label: 'University',
    value: '',
  });

  let url = `/api/v1/scholarships?limit=${limit}&page=${page}${
    uniDropDownVal.value ? `&university=${uniDropDownVal.value}` : ''
  }${category.length > 0 ? `&category=${category}` : ''}`;
  const { data, isError, isPending } = useFetch(url);

  const formats = [
    { label: 'Full Time', value: 'Full-Time' },
    { label: 'Part Time', value: 'Part-Time' },
  ];

  const qualifications = [
    { label: 'Foundation', value: 'Foundation' },
    { label: 'Certificate', value: 'Certificate' },
    { label: 'Diploma', value: 'Diploma' },
    { label: 'Top-Up', value: 'Top-Up' },
    { label: 'Bachelor', value: 'Bachelor' },
    { label: 'Masters', value: 'Masters' },
  ];
  const entryLevels = [
    { label: 'Certificate', value: 'Certificate' },
    { label: 'GCE-O/L', value: 'GCE-O/L' },
    { label: 'GCE-A/L', value: 'GCE-A/L' },
    { label: 'Edexcel', value: 'Edexcel' },
    { label: 'Cambridge', value: 'Cambridge' },
    { label: 'HND', value: 'HND' },
    { label: 'BSc', value: 'BSc' },
    { label: 'PGD', value: 'PGD' },
    { label: 'Masters', value: 'Masters' },
  ];

  const override = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
  };

  let courses = [];

  if (data) {
    // console.log({ data });
    courses = data.data.scholarships.filter(
      (course) =>
        course.scholarName.toLowerCase().includes(keyWord.toLowerCase()) ||
        course.course.courseName
          .toLowerCase()
          .includes(keyWord.toLowerCase()) ||
        course.scholarDescription.toLowerCase().includes(keyWord.toLowerCase())
    );

    // console.log({ courses });
  }

  const handleCheckboxChange = (event) => {
    let { id, checked } = event.target;
    setCheckboxState({
      ...checkboxState,
      [id]: checked,
    });
  };

  useEffect(() => {
    let categories = Object.keys(checkboxState).filter(
      (category) => checkboxState[category]
    );
    // console.log({ categories });

    categories.forEach((str, index, arr) => {
      arr[index] = str.replace(/&/g, '%26');
    });
    // console.log({ categories });
    setCategory(categories);
  }, [checkboxState]);

  const [filters, setFilters] = useState(false);

  return (
    <c.Section>
      <c.Container>
        {filters && (
          <c.FilterPopupContainer>
            <c.FilterPopupCloseBtn onClick={(e) => setFilters(false)}>
              <AiOutlineCloseCircle />
            </c.FilterPopupCloseBtn>
            <c.LeftTitle>Select University</c.LeftTitle>

            <c.LeftDrodownContainer>
              {/* <DropDown
                dropDownValues={uniList}
                currentDropdownVal={uniDropDownVal}
                setCurrentDropdownVal={setUniDropDownVal}
              /> */}
              {
                <AutoCompleteDropdown
                  dropDownValues={uniList}
                  currentDropdownVal={uniDropDownVal}
                  setCurrentDropdownVal={setUniDropDownVal}
                />
              }
            </c.LeftDrodownContainer>
            <c.LeftTitle>Course Categories </c.LeftTitle>

            <c.CheckBoxesContainer>
              <CheckBox
                labelTxt={'Architecture & Design'}
                checked={checkboxState['Architecture & Design']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Arts & Humanities'}
                checked={checkboxState['Arts & Humanities']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Aviation'}
                checked={checkboxState.Aviation}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Beauty & Hair'}
                checked={checkboxState['Beauty & Hair']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Business & Management'}
                checked={checkboxState['Business & Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Computing & IT'}
                checked={checkboxState['Computing & IT']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Construction'}
                checked={checkboxState.Construction}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Engineering'}
                checked={checkboxState.Engineering}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Event Management & Tourism'}
                checked={checkboxState['Event Management & Tourism']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Food Science & Agriculture'}
                checked={checkboxState['Food Science & Agriculture']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Finance'}
                checked={checkboxState.Finance}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Hotel & Hospitality'}
                checked={checkboxState['Hotel & Hospitality']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Journalism & Media Studies'}
                checked={checkboxState['Journalism & Media Studies']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Languages'}
                checked={checkboxState.Languages}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Law & Administration'}
                checked={checkboxState['Law & Administration']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marketing'}
                checked={checkboxState.Marketing}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Medicine and Health'}
                checked={checkboxState['Medicine and Health']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Military Sciences'}
                checked={checkboxState['Military Sciences']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marine'}
                checked={checkboxState.Marine}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Professional Certifications'}
                checked={checkboxState['Professional Certifications']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Physical Science'}
                checked={checkboxState['Physical Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Social Science'}
                checked={checkboxState['Social Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Soft Skill Development'}
                checked={checkboxState['Soft Skill Development']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Sports & Coaching'}
                checked={checkboxState['Sports & Coaching']}
                onChange={handleCheckboxChange}
              />
            </c.CheckBoxesContainer>
            <c.LeftDrodownContainer>
              <c.ClearFiltersBtn
                onClick={() => {
                  setFormatDropDownVal({
                    label: 'Format',
                    value: '',
                  });
                  setQualificationDropDownVal({
                    label: 'Qualification',
                    value: '',
                  });
                  setEntryLevelDropDownVal({
                    label: 'Entry level',
                    value: '',
                  });
                  setUniDropDownVal({
                    label: 'University',
                    value: '',
                  });
                  setCheckboxState({
                    'Arts & Humanities': false,
                    Aviation: false,
                    'Beauty & Hair': false,
                    'Business & Management': false,
                    'Computing & IT': false,
                    Construction: false,
                    Engineering: false,
                    'Event Management & Tourism': false,
                    'Food Science & Agriculture': false,
                    Finance: false,
                    'Hotel & Hospitality': false,
                    'Journalism & Media Studies': false,
                    Languages: false,
                    'Law & Administration': false,
                    Marketing: false,
                    'Medicine and Health': false,
                    'Military Sciences': false,
                    Marine: false,
                    'Professional Certifications': false,
                    'Physical Science': false,
                    'Social Science': false,
                    'Soft Skill Development': false,
                    'Sports & Coaching': false,
                  });
                }}
              >
                Clear Filters
              </c.ClearFiltersBtn>
            </c.LeftDrodownContainer>
          </c.FilterPopupContainer>
        )}

        <c.ContainerTop>
          <c.SearchBarContainer>
            <c.SearchInput
              value={keyWord}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search Scholarships"
            />
            <c.SearchBtn>
              <GoSearch />
            </c.SearchBtn>
            <c.FilterBtn onClick={(e) => setFilters(true)}>
              <VscSettings />
            </c.FilterBtn>
          </c.SearchBarContainer>

          {/* <c.DropDownContainer>
            <DropDown
              dropDownValues={formats}
              currentDropdownVal={formatDropDownVal}
              setCurrentDropdownVal={setFormatDropDownVal}
            />
          </c.DropDownContainer>
          <c.DropDownContainer>
            <DropDown
              dropDownValues={qualifications}
              currentDropdownVal={qualificationDropDownVal}
              setCurrentDropdownVal={setQualificationDropDownVal}
            />
          </c.DropDownContainer>
          <c.DropDownContainer>
            <DropDown
              dropDownValues={entryLevels}
              currentDropdownVal={entryLevelDropDownVal}
              setCurrentDropdownVal={setEntryLevelDropDownVal}
            />
          </c.DropDownContainer> */}
        </c.ContainerTop>

        <c.ContainerBody>
          <c.ContainerLeft>
            <c.LeftTitle>Select University</c.LeftTitle>

            <c.LeftDrodownContainer>
              {/* <DropDown
                dropDownValues={uniList}
                currentDropdownVal={uniDropDownVal}
                setCurrentDropdownVal={setUniDropDownVal}
              /> */}
              {
                <AutoCompleteDropdown
                  dropDownValues={uniList}
                  currentDropdownVal={uniDropDownVal}
                  setCurrentDropdownVal={setUniDropDownVal}
                />
              }
            </c.LeftDrodownContainer>
            <c.LeftTitle>Course Categories </c.LeftTitle>

            <c.CheckBoxesContainer>
              <CheckBox
                labelTxt={'Architecture & Design'}
                checked={checkboxState['Architecture & Design']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Arts & Humanities'}
                checked={checkboxState['Arts & Humanities']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Aviation'}
                checked={checkboxState.Aviation}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Beauty & Hair'}
                checked={checkboxState['Beauty & Hair']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Business & Management'}
                checked={checkboxState['Business & Management']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Computing & IT'}
                checked={checkboxState['Computing & IT']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Construction'}
                checked={checkboxState.Construction}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Engineering'}
                checked={checkboxState.Engineering}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Event Management & Tourism'}
                checked={checkboxState['Event Management & Tourism']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Food Science & Agriculture'}
                checked={checkboxState['Food Science & Agriculture']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Finance'}
                checked={checkboxState.Finance}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Hotel & Hospitality'}
                checked={checkboxState['Hotel & Hospitality']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Journalism & Media Studies'}
                checked={checkboxState['Journalism & Media Studies']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Languages'}
                checked={checkboxState.Languages}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Law & Administration'}
                checked={checkboxState['Law & Administration']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marketing'}
                checked={checkboxState.Marketing}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Medicine and Health'}
                checked={checkboxState['Medicine and Health']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Military Sciences'}
                checked={checkboxState['Military Sciences']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Marine'}
                checked={checkboxState.Marine}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Professional Certifications'}
                checked={checkboxState['Professional Certifications']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Physical Science'}
                checked={checkboxState['Physical Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Social Science'}
                checked={checkboxState['Social Science']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Soft Skill Development'}
                checked={checkboxState['Soft Skill Development']}
                onChange={handleCheckboxChange}
              />
              <CheckBox
                labelTxt={'Sports & Coaching'}
                checked={checkboxState['Sports & Coaching']}
                onChange={handleCheckboxChange}
              />
            </c.CheckBoxesContainer>
            <c.LeftDrodownContainer>
              <c.ClearFiltersBtn
                onClick={() => {
                  setFormatDropDownVal({
                    label: 'Format',
                    value: '',
                  });
                  setQualificationDropDownVal({
                    label: 'Qualification',
                    value: '',
                  });
                  setEntryLevelDropDownVal({
                    label: 'Entry level',
                    value: '',
                  });
                  setUniDropDownVal({
                    label: 'University',
                    value: '',
                  });
                  setCheckboxState({
                    'Arts & Humanities': false,
                    Aviation: false,
                    'Beauty & Hair': false,
                    'Business & Management': false,
                    'Computing & IT': false,
                    Construction: false,
                    Engineering: false,
                    'Event Management & Tourism': false,
                    'Food Science & Agriculture': false,
                    Finance: false,
                    'Hotel & Hospitality': false,
                    'Journalism & Media Studies': false,
                    Languages: false,
                    'Law & Administration': false,
                    Marketing: false,
                    'Medicine and Health': false,
                    'Military Sciences': false,
                    Marine: false,
                    'Professional Certifications': false,
                    'Physical Science': false,
                    'Social Science': false,
                    'Soft Skill Development': false,
                    'Sports & Coaching': false,
                  });
                }}
              >
                Clear Filters
              </c.ClearFiltersBtn>
            </c.LeftDrodownContainer>
          </c.ContainerLeft>

          <c.ContainerRight>
            <c.AdContainer>
              <c.AdImg img={ad1} />
            </c.AdContainer>
            {isPending && (
              <ClipLoader color="#333" size={60} cssOverride={override} />
            )}

            {courses.length === 0 && !isPending && <NoRecordFound />}

            {data &&
              !isPending &&
              courses.map((course, i) => {
                return (
                  // <c.Card to={`/scholarships/${course.id}`} key={i}>
                  <c.Card key={i}>
                    <c.CardLeft>
                      <c.Image src={course.university?.uniLogo} />
                    </c.CardLeft>

                    <c.CardRight>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="700"
                        color="#333"
                        fontsize="2rem"
                        margin="0 0 2rem 0"
                      >
                        {course.scholarName.split(' ').length > 1
                          ? course.scholarName.split(' ').map((el) => {
                              return `${el.charAt(0).toUpperCase()}${el.slice(
                                1
                              )} `;
                            })
                          : `${
                              course.scholarName.charAt(0).toUpperCase() +
                              course.scholarName.slice(1)
                            }`}
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Course :{' '}
                        <c.CardValueLabel>
                          {course.course.courseName ||
                            'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Validity period :{' '}
                        <c.CardValueLabel>
                          {course.scholarValidPeriod || 'Not mentioned'}
                        </c.CardValueLabel>
                      </c.Text>
                      <c.Text
                        fontfamily="var(--dashboard-font)"
                        fontweight="500"
                        color="#555"
                        fontsize="1.5rem"
                      >
                        Course Level :{' '}
                        <c.CardValueLabel>
                          {course.course.courseQualification ||
                            'Currently not available'}
                        </c.CardValueLabel>
                      </c.Text>
                    </c.CardRight>
                    <c.CardBtnContainer>
                      <c.CardBtnInvert
                        target={'_blank'}
                        to={`/scholarships/${course.id}`}
                      >
                        Read More
                      </c.CardBtnInvert>
                      <c.CardBtn
                        onClick={() => {
                          setUniversity(course.university.id);
                          setCourse(course.id);
                          setPopup(true);
                        }}
                      >
                        Inquire Now
                      </c.CardBtn>
                    </c.CardBtnContainer>
                  </c.Card>
                );
              })}

            <c.AdContainer>
              <c.AdImg img={ad2} />
            </c.AdContainer>

            {data && !isPending && courses.length !== 0 && (
              <>
                <c.PaginationContainer>
                  <Pagination
                    color="primary"
                    defaultPage={page}
                    // siblingCount={0}
                    size="large"
                    count={Math.floor(data.data.totalRecords / limit) + 1}
                    onChange={(e, val) => {
                      setPage(val);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  />
                </c.PaginationContainer>
              </>
            )}
          </c.ContainerRight>
        </c.ContainerBody>
      </c.Container>
    </c.Section>
  );
};

export default ScholarshipFilter;
