import React from 'react';
import Dashboard from '../../../components/shared/Dashboard';
import SingleScholarship from '../../../components/university/dashboard-components/SingleScholarship.jsx';
const UniversitySingleScholarship = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <SingleScholarship />
          </>
        }
      />
    </div>
  );
};

export default UniversitySingleScholarship;
