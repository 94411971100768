import React from 'react';
import Content from '../components/courses/single-course/Content';
// import Header from '../components/courses/single-course/Header';
import useFetch from '../hooks/useFeth';
import { useParams } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

const SingleCourse = () => {
  let { id } = useParams();
  const { data, isError, isPending } = useFetch(`/api/v1/courses/${id}`);
  let matches = [];

  if (data) {
    // console.log({ data });
    // console.log({ reqs: data.data.course.entryRequirements });
    let reqs = data.data.course.entryRequirements.replace(/\n/g, '');
    let regex = /\[(.*?)\]/g;

    let match;
    while ((match = regex.exec(reqs))) {
      matches.push(match[1]);
    }
  }
  const override = {
    position: 'absolute',
    margin: '0 auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // borderColor: "red",
  };

  const LoadingContainer = styled.div`
    width: 100%;
    min-height: 20rem;
    position: relative;
  `;

  return (
    <>
      {isPending && (
        <LoadingContainer>
          <ClipLoader color="#333" size={60} cssOverride={override} />
        </LoadingContainer>
      )}
      {data && (
        <>
          {/* <Header
            img={data.data.course.courseCoverPhoto}
            title={data.data.course.courseName}
          /> */}
          <Content
            uni={data.data.course.university}
            img={data.data.course.courseCoverPhoto}
            title={data.data.course.courseName}
            duration={data.data.course.courseDuration}
            qualification={data.data.course.courseQualification}
            fee={data.data.course.coursePrice}
            entryReq={matches}
            description={data.data.course.courseDescription}
            courseId={data.data.course.id}
            universityId={data.data.course.university.id}
            universityName={data.data.course.university.name}
            fcCountry={data.data.course?.fcCountry}
            fcCoursePrice={data.data.course?.fcCoursePrice}
            fcDescription={data.data.course?.fcDescription}
            foreign={data.data.course?.foreign}
          />
        </>
      )}
    </>
  );
};

export default SingleCourse;
