import React from 'react'
import Dashboard from '../../components/shared/Dashboard'
import {AdminEventsComponent} from './../../components/admin/AdminEvents'

const AdminEvents = () => {
  return (
    <div><Dashboard
    rightContainerContent=
    {
      <AdminEventsComponent />
    }

  /></div>
  )
}

export default AdminEvents