import Dashboard from "../../../components/shared/Dashboard";
import AddEvent from "../../../components/university/dashboard-components/AddEvent";

const UniversityAddEvent = () => {
    return ( 
        <div>
        <Dashboard
            rightContainerContent={
            <>
                <AddEvent/>
            </>
            }
        />
        </div>
     );
}
 
export default UniversityAddEvent;