import React from 'react';
import * as a from './PrivacyPolicyElements';
import UniNotesLogo from '../../images/new_logos/Logo icon/uninotes-logo-icon.png';
import Facebook from '../../images/socialMedia Icons/facebook.png';
import Instagram from '../../images/socialMedia Icons/instagram.png';
const PrivacyPolicy = () => {
  return (
    <a.PrivacyPolicyContainer>
      <a.PrivacyPolicyTitle>Privacy Policy</a.PrivacyPolicyTitle>
      <a.UniNotesLogo>
        <img src={UniNotesLogo} />
      </a.UniNotesLogo>
      <a.PrivacyPolicyParaOne>
        <p>
          Last Updated: 06th September 2023 <br /> <br />
          Uninotes Ltd. ("we", "our", "us") is committed to protecting your
          privacy. This Privacy Policy ("Policy") describes how we collect, use,
          and disclose personal data obtained through our website <a href="https://uninotes.lk/">Uninotes.lk</a>.
          By using our Website, you consent to the practices described in this
          Policy. Please read this Policy carefully to understand our practices
          regarding your personal data.
        </p>
        <br />
        {/* ==================================================================================== */}
        <h4>1. Personal Data:</h4>
        <p>
          1.1 "Personal data" refers to any information that can identify an
          individual directly or indirectly. This may include, but is not
          limited to, your name, contact details, email address, and any other
          information you provide through the Website.
        </p>
        <br />
        {/* ==================================================================================== */}
        <h4>2. Collection, Use, and Disclosure of Personal Data:</h4>
        <p>
          2.1 We collect personal data when you voluntarily provide it to us,
          such as when you register for an account, subscribe to our newsletter,
          or interact with our Website.
        </p>
        <p>2.2 We use your personal data for the following purposes:</p>
        <p>a. To provide you with access to and use of our Website;</p>
        <p>
          b. To communicate with you, respond to your inquiries, and provide
          customer support;
        </p>
        <p>
          c. To send you newsletters, updates, and promotional materials (you
          can opt out at any time);
        </p>
        <p>
          d. To analyze and improve our Website, including user experience and
          content;
        </p>
        <p> e. To comply with legal obligations.</p>
        <p>
          2.3 We do not sell, trade, or rent your personal data to third
          parties. We may, however, share your personal data with trusted
          service providers who assist us in operating our Website, conducting
          our business, or servicing you, as long as they agree to keep your
          information confidential.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>3. Withdrawing Your Consent:</h4>
        <p>
          3.1 You may withdraw your consent for us to contact you or use your
          personal data for certain purposes by contacting us at
          connect@uninotes.lk. Please note that withdrawing consent may affect
          our ability to provide certain services to you.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>4. Access to and Correction of Personal Data:</h4>
        <p>
          4.1 You have the right to request access to, correction, or deletion
          of your personal data held by us. To exercise this right, please
          contact us at connect@uninotes.lk. We may require verification of your
          identity before fulfilling such requests.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>5. Protection of Personal Data:</h4>
        <p>
          5.1 We use reasonable physical, technical, and administrative measures
          to safeguard your personal data from unauthorized access, use, or
          disclosure. However, no data transmission over the internet or
          electronic storage is entirely secure, and we cannot guarantee its
          absolute security.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>6. Accuracy of Personal Data:</h4>
        <p>
          6.1 We strive to ensure that your personal data is accurate and up to
          date. You can help us maintain the accuracy of your information by
          promptly notifying us of any changes to your personal data.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>7. Retention of Personal Data:</h4>
        <p>
          7.1 We will retain your personal data for as long as necessary to
          fulfill the purposes for which it was collected or as required by
          applicable laws.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>8. Effect of Notice and Changes to Notice:</h4>
        <p>
          8.1 By using our Website, you consent to the collection and use of
          your personal data as described in this Policy.
        </p>
        <p>
          8.2 We may update this Policy from time to time to reflect changes in
          our practices or for other operational, legal, or regulatory reasons.
          The updated Policy will be posted on the Website, and the date of the
          latest revision will be indicated at the beginning of the Policy. Your
          continued use of the Website after such changes constitutes your
          acceptance of the updated Policy.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>9. Contact Information:</h4>
        <p>
          9.1 For any questions, concerns, or requests related to this Policy or
          your personal data, please contact us at connect@uninotes.lk. Thank
          you for trusting Uninotes with your personal data.
        </p>
        <br />
        {/* ==================================================================================== */}
      </a.PrivacyPolicyParaOne>
      {/* <a.PrivacyPolicyParaTwo>
        <p>
          Looking for your dream course but don't know where to start? Uninotes
          makes it easy for education seekers to find their desiredcourse in
          less than one minute! With a simple search, you can browse
          universities in Sri Lanka and find available scholarships thatmatch
          your interests.
        </p>
        <p>
          We understand that finding the right course can be overwhelming, which
          is why we've streamlined the process to make it easy andefficient.
          Whether you're a student or a working professional, Uninotes has
          everything you need to kickstart your education journey.So why wait?
          Start exploring today and take the first step toward your future
          career!
        </p>
      </a.PrivacyPolicyParaTwo> */}
      <a.PrivacyPolicyContact>
        <a.PrivacyPolicyContactColOne>
          <p>Phone: +94 74 255 4570</p>
        </a.PrivacyPolicyContactColOne>
        <a.PrivacyPolicyContactColTwo>
          <img src={Facebook} />
          <img src={Instagram} />
        </a.PrivacyPolicyContactColTwo>
        <a.PrivacyPolicyContactColThree>
          <p>Email: connect@uninotes.lk</p>
        </a.PrivacyPolicyContactColThree>
      </a.PrivacyPolicyContact>
    </a.PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
