import React from 'react';
import * as c from './CoursesElements';
// import { BiSearch } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import { FiPlusCircle, FiEdit, FiTrash2 } from 'react-icons/fi';
// import { FcClock } from 'react-icons/fi';
// import Pagination from '@mui/material/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import submitForm from '../../../hooks/submitForm';
import { toast } from 'react-hot-toast';
const Courses = ({ courses }) => {
  const navigate = useNavigate();
  const onCourseUpdate = (e, course) => {
    navigate(`/uni/courses/${course.id}`);
  };
  const onDelete = async (e, course) => {
    e.preventDefault();

    await toast.promise(
      submitForm(`/api/v1/courses/deactivate/${course.id}`, {}, 'patch', {}),
      {
        loading: 'Deleting Course...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.6rem',
        },
      }
    );
    navigate(`/uni/courses/`);
  };

  const onToggleVisible = async (e, course) => {
    navigate(`/courses/${course.id}`);
  };

  return (
    <c.Container>
      <c.HeaderContainer>
        <c.HeaderTitle>Courses</c.HeaderTitle>

        {/* <c.SearchBarContainer>
          <c.SearchBar placeholder="Enter name or Email" />
          <c.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </c.SearchIconContainer>
        </c.SearchBarContainer> */}
        <Link to="/uni/Addcourse">
          <c.AddEventBtn>
            <c.AddEventIcon>
              {' '}
              <FiPlusCircle />{' '}
            </c.AddEventIcon>{' '}
            Add Course
          </c.AddEventBtn>
        </Link>

        {/* <u.AddUniversityBtn>
          <u.AddUniIcon> <FiPlusCircle /> </u.AddUniIcon> Add University
        </u.AddUniversityBtn> */}
      </c.HeaderContainer>

      <c.TableContainer>
        <c.Table>
          <c.TableHeader>
            <c.TableDataCell th={true}>#</c.TableDataCell>
            <c.TableDataCell th={true}>Course</c.TableDataCell>
            <c.TableDataCell th={true}>Course Fee</c.TableDataCell>
            <c.TableDataCell th={true}>Qualification</c.TableDataCell>
            <c.TableDataCell th={true}>Entry Level</c.TableDataCell>
            <c.TableDataCell th={true}>Course Type</c.TableDataCell>
            <c.TableDataCell th={true}>Status</c.TableDataCell>
            <c.TableDataCell th={true}>Actions</c.TableDataCell>
          </c.TableHeader>

          {courses.map((course, i) => {
            return (
              <c.TableRow key={i}>
                <c.TableDataCell>{i + 1} </c.TableDataCell>
                <c.TableDataCell> {course.courseName} </c.TableDataCell>
                <c.TableDataCell minwidth="15rem">
                  {course.coursePrice}
                </c.TableDataCell>
                <c.TableDataCell className="flex">
                  {course.courseQualification}
                </c.TableDataCell>
                <c.TableDataCell minwidth="15rem">
                  {course.courseEntryLevel}{' '}
                </c.TableDataCell>
                <c.TableDataCell minwidth="15rem">
                  {course.courseType}{' '}
                </c.TableDataCell>
                <c.TableDataCell minwidth="15rem">
                  {course.active ? (
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: 'green',
                        borderRadius: '5px',
                        padding: '5px 10px',
                      }}
                    >
                      Accepted
                    </span>
                  ) : (
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: '#529ffc',
                        borderRadius: '5px',
                        padding: '5px 10px',
                      }}
                    >
                      Pending
                    </span>
                  )}
                </c.TableDataCell>
                <c.TableDataCell className="flex" minwidth="15rem">
                  <c.TableActionBtn
                    onClick={(e) => {
                      onCourseUpdate(e, course);
                    }}
                  >
                    <FiEdit />
                  </c.TableActionBtn>
                  <c.TableActionBtn
                    onClick={(e) => {
                      onDelete(e, course);
                    }}
                  >
                    <FiTrash2 />
                  </c.TableActionBtn>
                  <c.TableActionBtn
                    onClick={(e) => {
                      onToggleVisible(e, course);
                    }}
                  >
                    <AiOutlineEye />
                  </c.TableActionBtn>
                </c.TableDataCell>
              </c.TableRow>
            );
          })}
        </c.Table>
      </c.TableContainer>

      <c.TablePaginationContainer>
        {/* <Pagination
          // color="secondary"
          size="large"
          count={5} defaultPage={6} siblingCount={0} /> */}
      </c.TablePaginationContainer>
    </c.Container>
  );
};

export default Courses;
