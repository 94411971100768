import styled from "styled-components";

export const Container = styled.div`
  ${"" /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  ${"" /* background-color:red ; */}
  width:100%;
  display: flex;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;
export const FormContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 5% 0;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
export const Fields = styled.div`
  width: 100%;
`;
export const DateTime = styled.div`
  width: 100%;
  display: flex;
`;
export const Button = styled.div`
  width: 20%;
  margin: 4% 0;
  @media screen and (max-width: 800px) {
    width: 50%;
  }
`;
