import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
import { DashboardDropdown } from '../../shared/dashboard-form-elements/DropDown';
import { FaCamera } from 'react-icons/fa';
import TextBox from '../../shared/Form Elements/TextBox';
import * as c from './AddCourseElements';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';

import { locations } from '../../../data/locations.js';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import validateImage from '../../../hooks/validateImage';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';

/////////////////   Transfer List function (Start) /////////////////
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
/////////////////   Transfer List function (END) /////////////////

const AddCourse = () => {
  //set variable to pass db
  const { user } = useAuth();
  const [isAgency, setIsAgency] = useState(user?.uni?.isAgency);
  const navigate = useNavigate();

  const [local, setLocal] = useState(true);
  const [foreign, setForeign] = useState(false);
  const [fcDescription, setFcDescription] = useState('');
  const [fcCoursePrice, setFcCoursePrice] = useState('');
  const [fcCountry, setFcCountry] = useState({
    label: 'Select Country',
    value: '',
  });
  const [courseName, setCourseName] = useState('');
  const [commencement, setCommencement] = useState('');
  const [entryRequirements, setEntryRequirements] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [coursePrice, setCoursePrice] = useState('');
  const [courseType, setType] = useState({
    label: 'Select course type',
    value: '',
  });
  const [courseQualification, setQualification] = useState({
    label: 'Select course qualification',
    value: '',
  });
  const [courseEntryLevel, setEntryLevel] = useState({
    label: 'Select course entry level',
    value: '',
  });
  const [courseDuration, setDuration] = useState('');
  const [category, setCategory] = useState({
    label: 'Select course category',
    value: '',
  });

  const [cover, setCover] = useState(null);
  const handleCover = (e) => {
    setCover(e.target.files[0]);
    // validateImageResolution(e.target.files[0]);
    // validateImage(e.target.files[0], 1080, 1080, setCover);
  };

  const fcCountries = [
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Bangaladesh', value: 'Bangaladesh' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Canada', value: 'Canada' },
    { label: 'China', value: 'China' },
    { label: 'Cyprus', value: 'Cyprus' },
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Finland', value: 'Finland' },
    { label: 'France', value: 'France' },
    { label: 'Germany', value: 'Germany' },
    { label: 'India', value: 'India' },
    { label: 'Korea', value: 'Korea' },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Malayasia', value: 'Malayasia' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Romania', value: 'Romania' },
    { label: 'Russia', value: 'Russia' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'USA', value: 'USA' },
  ];
  const EntryLevels = [
    { label: 'Certificate', value: 'Certificate' },
    { label: 'GCE-O/L', value: 'GCE-O/L' },
    { label: 'GCE-A/L', value: 'GCE-A/L' },
    { label: 'Edexcel', value: 'Edexcel' },
    { label: 'Cambridge', value: 'Cambridge' },
    { label: 'HND', value: 'HND' },
    { label: `Bachelor's`, value: 'BSc' },
    { label: 'PGD', value: 'PGD' },
    { label: 'Masters', value: 'Masters' },
  ];
  const Qualifications = [
    { label: 'Foundation', value: 'Foundation' },
    { label: 'Certificate', value: 'Certificate' },
    { label: 'Diploma', value: 'Diploma' },
    { label: 'Top-Up', value: 'Top-Up' },
    { label: `Bachelor's`, value: 'Bachelor' },
    { label: 'Masters', value: 'Masters' },
    { label: 'Phd', value: 'Phd' },
  ];
  const Types = [
    { label: 'Full Time', value: 'Full-Time' },
    { label: 'Part Time', value: 'Part-Time' },
  ];
  const Categories = [
    { label: 'Accounting', value: 'Accounting' },
    { label: 'Architecture & Design ', value: 'Architecture & Design' },
    { label: 'Arts & Humanities ', value: 'Arts & Humanities' },
    { label: 'Aviation ', value: 'Aviation' },
    { label: 'Beauty & Hair ', value: 'Beauty & Hair' },
    { label: 'Business & Management  ', value: 'Business & Management' },
    { label: 'Computing & IT', value: 'Computing & IT' },
    { label: 'Construction ', value: 'Construction' },
    { label: 'Engineering  ', value: 'Engineering' },
    {
      label: 'Event Management & Tourism ',
      value: 'Event Management & Tourism',
    },
    {
      label: 'Food Science & Agriculture ',
      value: 'Food Science & Agriculture',
    },
    { label: 'Finance ', value: 'Finance' },
    { label: 'Hotel & Hospitality ', value: 'Hotel & Hospitality' },
    {
      label: 'Journalism & Media Studies ',
      value: 'Journalism & Media Studies',
    },
    { label: 'Languages ', value: 'Languages' },
    { label: 'Law & Administration  ', value: 'Law & Administration' },
    {
      label: 'Logistics & Supply chain Management',
      value: 'Logistics & Supply chain Management',
    },
    { label: 'Marketing  ', value: 'Marketing' },
    { label: 'Medicine and Health   ', value: 'Medicine and Health' },
    { label: 'Military Sciences  ', value: 'Military Sciences' },
    { label: 'Marine  ', value: 'Marine' },
    {
      label: 'Professional Certifications  ',
      value: 'Professional Certifications',
    },
    { label: 'Physical Science  ', value: 'Physical Science' },
    { label: 'Social Science  ', value: 'Social Science' },
    { label: 'Soft Skill Development  ', value: 'Soft Skill Development' },
    { label: 'Sports & Coaching  ', value: 'Sports & Coaching' },
  ];

  const handleSubmit = async (e, formData) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/api/v1/courses`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (err) {
      // console.log(err.response.data);
      throw err;
    }
  };

  const AddCourse = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('courseName', courseName);
    formData.append('courseDescription', courseDescription?.slice(0, 500));
    formData.append('coursePrice', coursePrice);
    formData.append('courseType', courseType.value);
    formData.append('courseQualification', courseQualification.value);
    formData.append('courseEntryLevel', courseEntryLevel.value);
    formData.append('category', category.value);

    formData.append('courseDuration', courseDuration?.slice(0, 15));
    formData.append('courseCoverPhoto', cover);
    formData.append('commencement', commencement);
    formData.append(
      'entryRequirements',
      entryRequirements.replace(/[\r\n]+/g, '')
    );
    formData.append('courseLocations', right);

    formData.append('local', local);
    formData.append('foreign', foreign);
    formData.append('fcDescription', fcDescription?.slice(0, 500));
    formData.append('fcCountry', fcCountry.value);
    formData.append('fcCoursePrice', fcCoursePrice);

    if (!courseName) {
      return toast.error('Please provide Course Name.');
    }
    if (!courseDescription) {
      return toast.error('Please provide Course Description.');
    }
    // if (!coursePrice) {
    //   return toast.error('Please provide course Fee.');
    // }
    if (!courseType.value) {
      return toast.error('Please provide Course Type.');
    }
    if (!courseQualification.value) {
      return toast.error('Please provide Course Qualification.');
    }
    if (!courseEntryLevel.value) {
      return toast.error('Please provide Course Entry Level.');
    }
    if (!category.value) {
      return toast.error('Please provide Course Category.');
    }
    if (!courseDuration) {
      return toast.error('Please provide Course Duration.');
    }

    if (!cover) {
      return toast.error('Please upload image cover.');
    }
    if (!commencement) {
      return toast.error('Please provide Course Commencement.');
    }
    if (entryRequirements.length < 1) {
      return toast.error('Please provide Course Entry Requirements.');
    }

    if (foreign && fcDescription.length < 1) {
      return toast.error('Please provide foreign Course description.');
    }
    if (foreign && !fcCountry.value) {
      return toast.error('Please provide foreign Course country.');
    }
    // if (foreign && !fcCoursePrice) {
    //   return toast.error('Please provide foreign Course price.');
    // }

    await toast.promise(
      handleSubmit(e, formData),
      {
        loading: 'Adding Course...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.6rem',
        },
      }
    );

    navigate('/uni/courses');
  };

  //////////////////////////////////////////// Location Transfer List (START) ////////////////////////////////////////////
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(locations);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  size="large"
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  //////////////////////////////////////////// Location Transfer List (END) ////////////////////////////////////////////

  // const handleChangeLocal = (event) => {
  //   setLocal(event.target.checked);
  // };

  const handleChangeForeign = (event) => {
    setForeign(event.target.checked);
  };

  return (
    <c.Container>
      <c.HeaderContainer>
        <c.HeaderTitle>Courses</c.HeaderTitle>
      </c.HeaderContainer>
      <c.FormContainer onSubmit={AddCourse}>
        <c.Cover>
          <c.CoverSection>
            {cover ? (
              <c.CoverImage src={URL.createObjectURL(cover)} />
            ) : (
              <p></p>
            )}
          </c.CoverSection>
          <c.CoverIcon>
            <FaCamera />
            <input
              type="file"
              id="file1"
              accept="image/*"
              onChange={handleCover}
            />
          </c.CoverIcon>
          <c.Text>
            <p>Course Cover Upload</p>
          </c.Text>
          <c.Text>
            <c.LogoPlaceholder>(1080px X 1080px)</c.LogoPlaceholder>
          </c.Text>
        </c.Cover>
        <c.Fields>
          {/* <c.PText>
            <Checkbox
              size="large"
              checked={local}
              onChange={(event) => handleChangeLocal(event)}
              disabled
            />
            Course Locally Available
          </c.PText> */}
          <>
            <c.Label>Enter course name. </c.Label>

            <DashboardTextField
              value={courseName}
              setValue={setCourseName}
              placeholder={'Ex: Software Engineering'}
            />
          </>
          <>
            <c.Label>Enter next intake. </c.Label>
            <DashboardTextField
              value={commencement}
              setValue={setCommencement}
              placeholder={'EX: September 2023'}
            />
          </>
          <>
            <c.Label>Enter course description. </c.Label>

            <TextBox
              value={courseDescription.slice(0, 500)}
              setValue={setCourseDescription}
              placeholder={'Max 500 characters'}
            />
          </>
          <>
            <c.Label>
              Enter each requirement within square brackets "[ ]".
            </c.Label>

            <TextBox
              value={entryRequirements}
              setValue={setEntryRequirements}
              placeholder={'Ex:\n[Requirement 1]\n[Requirement 2]'}
            />
          </>

          <>
            <c.Label>Select course category.</c.Label>
            <DashboardDropdown
              dropDownValues={Categories}
              currentDropdownVal={category}
              setCurrentDropdownVal={setCategory}
            />
          </>
          <>
            <c.Label>Select course qualification.</c.Label>
            <DashboardDropdown
              dropDownValues={Qualifications}
              currentDropdownVal={courseQualification}
              setCurrentDropdownVal={setQualification}
            />
          </>
          <>
            <c.Label>Select course entry level.</c.Label>
            <DashboardDropdown
              dropDownValues={EntryLevels}
              currentDropdownVal={courseEntryLevel}
              setCurrentDropdownVal={setEntryLevel}
            />
          </>
          <>
            <c.Label>Select course type.</c.Label>
            <DashboardDropdown
              dropDownValues={Types}
              currentDropdownVal={courseType}
              setCurrentDropdownVal={setType}
            />
          </>
          {local ? (
            <>
              <c.Label>Enter course Fee.</c.Label>

              <DashboardTextField
                value={coursePrice}
                setValue={setCoursePrice}
                placeholder={'EX: Rs. 1,675,000'}
              />
            </>
          ) : (
            <c.PText></c.PText>
          )}

          {isAgency ? (
            <>
              <c.Label>Select country.</c.Label>
              <DashboardDropdown
                dropDownValues={fcCountries}
                currentDropdownVal={fcCountry}
                setCurrentDropdownVal={setFcCountry}
              />
            </>
          ) : (
            <c.PText></c.PText>
          )}

          <>
            <c.Label>Enter course duration.</c.Label>

            <DashboardTextField
              value={courseDuration.slice(0, 15)}
              setValue={setDuration}
              placeholder={'EX: 3 years'}
            />
          </>
          {/* Transfer List (START) */}
          {!isAgency && (
            <>
              <c.Label>Select locations.</c.Label>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ my: 3 }}
              >
                <Grid item>{customList(left)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList(right)}</Grid>
              </Grid>
            </>
          )}

          {/* Transfer List (END) */}

          {!isAgency ? (
            <div>
              <c.Text>Foreign Course Details</c.Text>
              <c.PText>
                <Checkbox
                  size="large"
                  checked={foreign}
                  onChange={(event) => handleChangeForeign(event)}
                />
                Study abroad availability
              </c.PText>
            </div>
          ) : (
            <c.PText></c.PText>
          )}

          {foreign && !isAgency ? (
            <>
              <>
                <c.Label>Enter Foreign Course Description here</c.Label>
                <TextBox
                  value={fcDescription.slice(0, 500)}
                  setValue={setFcDescription}
                  placeholder={'Max 500 characters'}
                />
              </>
              <>
                <c.Label>Select country.</c.Label>
                <DashboardDropdown
                  dropDownValues={fcCountries}
                  currentDropdownVal={fcCountry}
                  setCurrentDropdownVal={setFcCountry}
                />
              </>
              <>
                <c.Label>Enter Foreign Course Fee in USD.</c.Label>
                <DashboardTextField
                  value={fcCoursePrice}
                  setValue={setFcCoursePrice}
                  placeholder={'EX: USD 180,000'}
                />
              </>
            </>
          ) : (
            <c.PText></c.PText>
          )}
        </c.Fields>
        <c.Button>
          <RegularButton
            type="submit"
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
          />
        </c.Button>
      </c.FormContainer>
    </c.Container>
  );
};

export default AddCourse;
