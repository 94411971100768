import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 2.8rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  color: #333;
  /* text-align: center; */

  @media (max-width: 1000px) {
    font-size: 2.3rem;
  }
  @media (max-width: 500px) {
    font-size: 2rem;
  }
`;

const DashboardWelcomeText = ({ text }) => {
  return (
    <>
      <Text>{text}</Text>
    </>
  );
};

export default DashboardWelcomeText;
