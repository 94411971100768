import React from 'react';
import * as a from './TermsConditionsElements';
import UniNotesLogo from '../../images/new_logos/Logo icon/uninotes-logo-icon.png';
import Facebook from '../../images/socialMedia Icons/facebook.png';
import Instagram from '../../images/socialMedia Icons/instagram.png';
const TermsConditions = () => {
  return (
    <a.TermsConditionsContainer>
      <a.TermsConditionsTitle>Terms & Conditions</a.TermsConditionsTitle>
      <a.UniNotesLogo>
        <img src={UniNotesLogo} />
      </a.UniNotesLogo>
      <a.TermsConditionsParaOne>
        <p>
          Last Updated: 06th September 2023 <br /> <br />
          Welcome to Uninotes ("we", "our", "us"). Please read these Terms of
          Use ("Terms") carefully before using our website <a href="https://uninotes.lk/">Uninotes.lk</a>. By
          accessing or using our Website, you agree to be bound by these Terms.
          If you do not agree to these Terms, please do not use our Website.
        </p>
        <br />
        {/* ==================================================================================== */}
        <h4>1. Acceptance of Terms:</h4>
        <p>
          1.1 These Terms constitute a legally binding agreement between you and
          Uninotes regarding your use of the Website.
        </p>
        <p>
          1.2 We may update these Terms from time to time, and any changes will
          be effective immediately upon posting on the Website. Your continued
          use of the Website after any such changes constitutes your acceptance
          of the revised Terms.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>2. Use of the Website:</h4>
        <p>
          2.1 You agree to use the Website for lawful purposes and in a manner
          consistent with all applicable laws and regulations.
        </p>
        <p>
          2.2 You may not use the Website in any way that could harm, disable,
          overburden, or impair the Website or interfere with other users'
          enjoyment of the Website.
        </p>
        <p>
          2.3 You may not attempt to gain unauthorized access to any part of the
          Website, other user accounts, or any systems or networks connected to
          the Website.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>3. User Accounts:</h4>
        <p>
          3.1 You may need to create a user account to access certain features
          of the Website. You are responsible for maintaining the
          confidentiality of your account and password.
        </p>
        <p>
          3.2 You agree to provide accurate, current, and complete information
          during the registration process and to update such information to keep
          it accurate, current, and complete.
        </p>
        <p>
          3.3 You are solely responsible for all activities that occur under
          your account, and you agree to notify us immediately of any
          unauthorized use of your account.
        </p>
        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>4. Privacy:</h4>
        <p>
          4.1 Your use of the Website is also governed by our Privacy Policy,
          which can be found at <a href="https://uninotes.lk/">Uninotes.lk</a>.
        </p>

        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>5. Intellectual Property:</h4>
        <p>
          5.1 All content on the Website, including text, graphics, logos,
          images, and software, is the property of Uninotes or its licensors and
          is protected by copyright and other intellectual property laws.
        </p>
        <p>
          5.2 You may not use, reproduce, distribute, or create derivative works
          based on the content of the Website without our prior written consent.{' '}
        </p>

        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>6. Disclaimer of Warranties:</h4>
        <p>
          6.1 The Website is provided "as is" and "as available" without any
          warranties of any kind, either express or implied, including, but not
          limited to, the implied warranties of merchantability, fitness for a
          particular purpose, or non-infringement.
        </p>
        <p>
          6.2 We do not warrant that the Website will be uninterrupted or
          error-free, that defects will be corrected, or that the Website is
          free of viruses or other harmful components.
        </p>

        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>7. Limitation of Liability:</h4>
        <p>
          7.1 To the fullest extent permitted by applicable law, Uninotes shall
          not be liable for any direct, indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from your
          use or inability to use the Website.
        </p>

        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>8. Governing Law and Jurisdiction:</h4>
        <p>
          8.1 These Terms are governed by and construed in accordance with the
          laws of Sri Lanka. You agree to submit to the exclusive jurisdiction
          of the courts of Sri Lanka for the resolution of any disputes arising
          under these Terms.
        </p>

        <br />
        {/* ==================================================================================== */}
        {/* ==================================================================================== */}
        <h4>9. Contact Information:</h4>
        <p>
          9.1 For any questions or concerns about these Terms, please contact us
          at connect@uninotes.lk
        </p>
        <p>
          By using our Website, you agree to these Terms. If you do not agree to
          these Terms, please do not use our Website. Thank you for using
          Uninotes!
        </p>

        <br />
        {/* ==================================================================================== */}
      </a.TermsConditionsParaOne>

      <a.TermsConditionsContact>
        <a.TermsConditionsContactColOne>
          <p>Phone: +94 74 255 4570</p>
        </a.TermsConditionsContactColOne>
        <a.TermsConditionsContactColTwo>
          <img src={Facebook} />
          <img src={Instagram} />
        </a.TermsConditionsContactColTwo>
        <a.TermsConditionsContactColThree>
          <p>Email: connect@uninotes.lk</p>
        </a.TermsConditionsContactColThree>
      </a.TermsConditionsContact>
    </a.TermsConditionsContainer>
  );
};

export default TermsConditions;
