import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  text-align: ${(props) => props.textalign || 'left'};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
`;

export const Section = styled.div`
  width: 100%;
  min-height: 30rem;
  display: flex;
  padding: 0rem 20rem;

  @media (max-width: 2561px) {
    padding: 3rem 50rem;
  }
  @media (max-width: 2200px) {
    padding: 3rem 40rem;
  }
  @media (max-width: 2000px) {
    padding: 3rem 35rem;
  }
  @media (max-width: 1800px) {
    padding: 3rem 30rem;
  }
  @media (max-width: 1700px) {
    padding: 1rem 20rem;
  }

  @media (max-width: 1000px) {
    padding: 0rem 14rem;
  }
  @media (max-width: 800px) {
    padding: 0rem 9rem;
  }
  @media (max-width: 600px) {
    padding: 0rem 4rem;
  }
  @media (max-width: 450px) {
    padding: 0rem 1rem;
  }
`;

export const Container = styled.div`
  flex: 0 1 100%;
  /* min-height: 20rem; */
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background-color: var(--ternary-blue);
  border-radius: 3rem;

  @media (max-width: 600px) {
    flex: 0 1 100%;
    padding: 1rem 1rem;
  }
`;

export const ContainerTop = styled.div`
  flex: 0 1 40%;
  padding: 1rem 1rem 1.5rem;
  display: flex;
  /* border-bottom: 1px solid #999; */

  @media (max-width: 1450px) {
    padding: 0;
  }
  @media (max-width: 1350px) {
    display: grid;
    padding: 0;
  }
  @media (max-width: 426px) {
    display: flex;
    flex: 1;
  }
`;

export const ContainerTopSearch = styled.div`
  flex: 0 1 45%;
  padding: 1rem 1rem 1.5rem;
  display: flex;
  border-bottom: 1px solid #999;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 426px) {
    flex: 0 1 100%;
  }
`;

export const ContainerTopDropDown = styled.div`
  flex: 0 1 100%;
  padding: 1rem 1rem 1.5rem;
  display: flex;
  border-bottom: 1px solid #999;
  @media (max-width: 660px) {
    flex: 0 1 50%;
    display: grid;
  }

  @media (max-width: 1100px) {
    flex: 0 1 50%;
    display: none;
  }
`;

export const DropDownContainer = styled.div`
  flex: 0 1 33%;
  padding: 0 1rem;

  @media (max-width: 1430px) {
    flex: 0 1 50%;
    padding: 0;
  }
  @media (max-width: 1100px) {
    flex: 0 1 50%;
  }
  @media (max-width: 660px) {
    padding: 1rem 4rem;
  }
  @media (max-width: 426px) {
    padding: 1rem 1rem;
  }
`;

export const ContainerBody = styled.div`
  flex: 0 1 100%;
  display: flex;

  @media (max-width: 1100px) {
    display: grid;
  }
`;

export const ContainerLeft = styled.div`
  flex: 0 1 30%;
  border-right: 1px solid #999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1rem;

  /* @media (max-width: 426px) {
    display: none;
  } */
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const ContainerRight = styled.div`
  flex: 1;
  ${'' /* background-color: green; */}
  padding: 1.5rem 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-height: 70rem;

  @media (max-width: 426px) {
    padding: 0;
  }
`;

export const Card = styled.div`
  width: 100%;
  ${'' /* min-height:20rem ; */}
  background-color:#fff;
  margin: 1rem 0;
  border-radius: 2rem;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s;

  :hover {
    -webkit-box-shadow: 0px 8px 15px 5px #c3c3c3;
    box-shadow: 0px 8px 15px 5px #c3c3c3;
  }

  @media (max-width: 1300px) {
    display: grid;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    padding: 2rem 1rem;
  }
`;

export const CardLeft = styled.div`
  flex: 0 1 20%;
  min-height: 10rem;
  /* background-image: url(${(props) => props.img}); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1300px) {
    display: grid;
    align-items: center;
    justify-content: center;
    padding-left: 5rem;
  }
`;

export const Image = styled.img`
  width: 70%;
  height: auto;
`;
export const CardRight = styled.div`
  flex: 1;

  /* background-color: green; */
  padding: 1rem 2rem;
  /* margin: 1rem 0; */
`;
export const LocationRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #fff;
  outline: none;
  border: 2px solid var(--fourth-blue);
  border-radius: 5rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: var(--primary-font);
`;

export const SearchBarContainer = styled.div`
  position: relative;
  flex: 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  /* background-color: red; */
  ${'' /* margin:0 auto; */}
`;

export const SearchBtn = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15%;
  font-size: 5rem;
  color: var(--fourth-blue);

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1350px) {
    right: 10%;
  }
  @media (max-width: 1300px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const PaginationContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;

export const LeftTitle = styled.p`
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  /* margin: 0 0 1rem; */
`;

export const LeftDrodownContainer = styled.div`
  margin: 1.5rem 0;
  width: 100%;
`;

export const ClearFiltersBtn = styled.div`
  width: 92%;
  background-color: #fff;
  border: 2px solid var(--fourth-blue);
  outline: none;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.1rem 1rem;
  border-radius: 3rem;
  margin: 0 auto;
  text-align: center;

  :hover {
    cursor: pointer;
  }
`;

export const ScholLabel = styled.div`
  max-width: 70%;
  padding: 1rem 1rem;
  background-color: var(--gold);
  font-size: 1.3rem;
  border-radius: 2rem;
  color: #fff;
  margin: 2rem 0 0;
  font-family: var(--dashboard-font);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardValueLabel = styled.p`
  font-weight: 600;
  color: #333;
  font-family: var(--dashboard-font);
  display: inline;
`;

export const CheckBoxesContainer = styled.div`
  padding: 1rem 0;
`;

export const StudyAbroadLabel = styled.div`
  background-color: var(--gold);
  font-family: var(--dashboard-font);
  font-size: 1.3rem;
  color: #fff;
  font-weight: 600;
  border-radius: 2rem;
  padding: 0.5% 2rem;
  width: 100%;
  /* margin: 0.5rem 0; */
  text-align: center;
`;
export const StudyAbroadLabelButton = styled.div`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBtnContainer = styled.div`
  position: relative;
  /* width: 60%; */
  /* height: 100%; */
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  /* min-height: 10rem; */
  /* background-color: red; */
`;

export const CardBtn = styled.div`
  /* position: absolute;
  top: 0;
  right: 0; */
  width: 80%;
  padding: 1rem 2rem;
  background-color: var(--fourth-blue);
  font-size: 1.2rem;
  color: #fff;
  border-radius: 1rem;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0);
  transition: all 0.2s;
  font-weight: 600;

  :hover {
    cursor: pointer;
    background-color: #fff;
    color: var(--main-blue);
    border: 2px solid var(--main-blue);
  }
`;
export const CardBtnInvert = styled(Link)`
  width: 80%;
  padding: 1rem 2rem;
  text-decoration: none;
  /* background-color: var(--fourth-blue); */
  background-color: #fff;
  font-size: 1.2rem;
  color: var(--fourth-blue);
  border-radius: 1rem;
  text-align: center;
  border: 2px solid var(--main-blue);
  transition: all 0.2s;
  font-weight: 600;
  margin: 1rem 0;

  :hover {
    cursor: pointer;
    background-color: var(--fourth-blue);
    color: #fff;
    border: 2px solid var(--fourth-blue);
  }
`;

export const FilterPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: var(--main-blue-shade); */
  /* background-color: #fff; */
  z-index: 999999;
  display: none;
  overflow-y: scroll;
  padding: 3rem 3rem;

  ::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    background-color: rgba(250, 248, 247, 0.76);
  }

  /* @media (max-width: 426px) {
    display: inline;
  } */
  @media (max-width: 1100px) {
    display: inline;
    padding: 2rem 15rem;
  }
  @media (max-width: 600px) {
    display: inline;
    padding: 2rem;
  }
`;

export const FilterPopupCloseBtn = styled.button`
  outline: none;
  border: none;
  color: var(--main-color);
  font-size: 3rem;
  background-color: rgba(250, 248, 247, 0);
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  color: var(--main-blue);
`;

export const FilterBtn = styled.button`
  padding: 0.5rem;
  /* background-color: red; */
  outline: none;
  border: none;
  font-size: 2.2rem;
  width: 3.5rem;
  height: 3.5rem;
  color: var(--main-blue);
  border-radius: 100%;
  border: 2px solid var(--main-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  /* @media (max-width: 426px) {
    display: flex;
  } */
  @media (max-width: 1100px) {
    display: flex;
  }
`;

export const AdContainer = styled.div`
  min-height: 10rem;
  width: 100%;
  /* padding: 1rem; */
  /* background-color: red; */
  display: flex;
  justify-content: center;
`;

export const AdImg = styled.div`
  /* height: 100%;
  width: auto; */
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
