import React from 'react'
import TermsConditions from '../components/shared/TermsConditions'

const AboutUs = () => {
    return ( 
        <>
            <TermsConditions />
        </>
     );
}
 
export default AboutUs;