import React, { useEffect, useState } from 'react';
import * as d from './DashboardHomeElements';
import DashboardWelcomeText from './DashboardWelcomeText';
import { adminCards } from './../../data/dashboard-home-cards/adminCards';
import { studentCards } from './../../data/dashboard-home-cards/studentCards';
import { uniCards } from './../../data/dashboard-home-cards/uniCards';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const DashboardHome = () => {
  const [cards, setCards] = useState(studentCards);

  const { user } = useAuth();

  // const navigate = useNavigate();

  useEffect(() => {
    switch (user?.role) {
      case 'admin':
        setCards(adminCards);
        break;
      case 'user':
        setCards(studentCards);
        break;
      case 'university':
        setCards(uniCards);
        break;

      default:
        // navigate('/login')
        break;
    }
  }, [user]);

  return (
    <d.Container>
      <d.HeaderContainer>
        <DashboardWelcomeText text={`Welcome, ${user?.name || 'user'}!`} />
      </d.HeaderContainer>

      <d.CardRow>
        {cards.map((card, i) => {
          return <d.CardContainer key={i}> {card.element} </d.CardContainer>;
        })}
      </d.CardRow>
    </d.Container>
  );
};

export default DashboardHome;
