import styled from 'styled-components';

export const Container = styled.div`
  ${'' /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;

  @media (max-width: 760px) {
    padding: 3rem 0rem;
  }
`;

export const HeaderContainer = styled.div`
  ${'' /* background-color:red ; */}
  width:100%;
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0 ;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const FormContaier = styled.div`
  width: 100%;
  @media screen and (max-width: 800px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-around'};
  margin: 2rem 0;
  @media screen and (max-width: 800px){
    flex-direction: ${(props) => props.flexdirection || 'row'};
    justify-content: center;
    align-items: center;
  }
`;

export const FormGroup = styled.div`
  flex: 0 1 45%;
  @media screen and (max-width: 800px){
    margin: 0 2%;
  }
`;

export const FormBtn = styled.button`
  flex: 0 1 20%;
  background-color: ${(props) => props.color || '#222'};
  color: ${(props) => props.txtcolor || '#eee'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  border-radius: 10rem;
  border: 2px solid #333;
  outline: none;
  font-size: 1.3rem;
  font-family: var(--dashboard-font);
  font-weight: 500;
  margin: 2rem 1rem;

  :hover {
    cursor: pointer;
  }
`;

export const SubmitIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-right: 0.5rem;
`;
