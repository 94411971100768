// import React from 'react';
import { toast } from 'react-hot-toast';

const validateImage = (imageFile, definedWidth, definedHeight, setImage) => {
  const image = new Image();
  image.src = URL.createObjectURL(imageFile);

  image.onload = () => {
    const width = image.width;
    const height = image.height;

    // console.log({
    //   width,
    //   height,
    // });

    // Define your resolution requirements here
    const minWidth = definedWidth;
    const minHeight = definedHeight;

    if (width !== minWidth || height !== minHeight) {
      toast.error(
        `Invalid Resolution. Resolution must be ${definedWidth}px x ${definedHeight}px`,
        {
          style: {
            textAlign: 'center',
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
      setImage(null);
    }

    // if (width < minWidth || height < minHeight) {
    //   alert(
    //     'Image resolution is too small. Please select an image with a higher resolution.'
    //   );
    //   setCover(null); // Reset the selected file
    // }
  };
  return {};
};

export default validateImage;
