import styled from 'styled-components';
import logo from './../../images/new_logos/Logo type 1/uninotes-logo-2.png';
import { Link } from 'react-router-dom';
// import { motion } from "framer-motion";

export const NavbarSection = styled.div`
  width: 100%;
  min-height: 10rem;
  ${'' /* background-color: red; */}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15rem;
  position: relative;

  @media (max-width: 2561px) {
    padding: 1rem 25rem;
  }

  @media (max-width: 2000px) {
    padding: 1rem 20rem;
  }
  @media (max-width: 1700px) {
    padding: 1rem 15rem;
  }
  @media (max-width: 1500px) {
    padding: 1rem 12rem;
  }

  @media (max-width: 1000px) {
    padding: 0 8rem;
  }
  @media (max-width: 800px) {
    padding: 0 5rem;
  }
  @media (max-width: 500px) {
    padding: 0 2rem;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  min-height: 6.5rem;
  background-color: var(--main-blue);
  border-radius: 10rem;
  display: flex;
  padding: 1rem 10rem;
  position: relative;
  @media (max-width: 2561px) {
    padding: 2rem 10rem;
  }
  @media (max-width: 2000px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 550px) {
    padding: 1rem 5rem;
  }
`;

export const NavBrandContainer = styled(Link)`
  flex: 0 1 20%;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 12;

  @media (max-width: 1300px) {
    flex: 0 1 30%;
  }
  @media (max-width: 900px) {
    flex: 0 1 40%;
  }
  @media (max-width: 800px) {
    flex: 0 1 50%;
  }
  @media (max-width: 550px) {
    flex: 0 1 100%;
  }
`;

export const NavToggleBtnContainer = styled.button`
  flex: 0 1 2.5%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: var(--white);
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    margin: 0 0 0 auto;
  }
`;

export const NavLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem;
  position: relative;
  z-index: 11;

  @media (max-width: 1000px) {
    padding: 0 8rem;
    display: none;
  }
`;

export const NavLinkBtnContainer = styled.div`
  flex: 0 1 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
`;

export const NavDropDownLinksContainer = styled.div`
  width: 25%;
  background-color: var(--main-blue);
  ${'' /* background-color:red; */}
  position:absolute;

  top: 20%;
  right: 0;
  padding: 5rem 0 2rem 0;
  z-index: 9999999999999999;
  border-radius: 3rem;
  display: ${(props) => (props.toggle ? 'block' : 'none')};

  @media (max-width: 1220px) {
    width: 40%;
  }
  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const NavDropdownAuthBtnContainer = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const NavDropDownBtnContainer = styled.div`
  width: 60%;
  margin: 2rem auto;

  /* @media (max-width: 2561px) {
    width: 20%;
  } */
`;
