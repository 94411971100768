import React from 'react';
import * as b from './BannerElements';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import ClipLoader from 'react-spinners/ClipLoader';

import banner_1 from './../../images/default_banners/1.webp';
import banner_2 from './../../images/default_banners/2.webp';
import banner_3 from './../../images/default_banners/3.webp';
import banner_4 from './../../images/default_banners/4.webp';

import useFetch from './../../hooks/useFeth';

const Banner = () => {
  const { data: banners, isPending, isError } = useFetch('/api/v1/banners');

  if (banners) {
    // console.log({ banners });
    // setDisplayBanners(banners.data.banners);
    // console.log({ banners });
  }

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  // };
  // const override = {
  //   position: 'absolute',
  //   margin: '0 auto',
  //   top: '30%',
  //   left: '50%',
  //   transform: 'translate(-50%,-50%)',
  //   // borderColor: "red",
  // };
  return (
    <>
      <b.Section>
        <b.Container>
          {/* <b.CarousalContainer>
            <Slider {...settings}>
              {isPending && (
                <b.Banner key={1}>
                  <ClipLoader size={60} cssOverride={override} />
                </b.Banner>
              )}

              {banners?.data?.banners?.length === 0 && !isPending && (
                <b.Banner>
                  <b.BannerImage
                    img={
                      'https://uninotesstorageaccount.blob.core.windows.net/default-images/default_banner.jpg'
                    }
                  />
                </b.Banner>
              )}

              {banners?.data?.banners.length > 0 &&
                !isPending &&
                banners.data.banners.slice(0, 3).map((banner, i) => {
                  return (
                    <b.Banner key={i}>
                      <b.BannerImage
                        img={
                          banner.bannerPhotoURL ||
                          'https://uninotesstorageaccount.blob.core.windows.net/default-images/default_banner.jpg'
                        }
                      />
                    </b.Banner>
                  );
                })}
            </Slider>
          </b.CarousalContainer> */}

          <b.BannerRow>
            <b.Banner>
              <b.BannerImage img={banner_1} />
            </b.Banner>
            <b.Banner>
              <b.BannerImage img={banner_2} />
            </b.Banner>
          </b.BannerRow>
          <b.BannerRow>
            <b.Banner>
              <b.BannerImage img={banner_3} />
              {/* <b.Text fontsize="2rem">Banner 1</b.Text> */}
            </b.Banner>
            <b.Banner>
              <b.BannerImage img={banner_4} />
            </b.Banner>
          </b.BannerRow>
        </b.Container>
      </b.Section>
    </>
  );
};

export default Banner;
