import WelcomePageCard from '../../components/shared/WelcomePageCard';
import { FiActivity, FiSliders, FiEdit } from 'react-icons/fi';

const cards = [
  {
    element: (
      <WelcomePageCard
        icon={<FiActivity />}
        cardcolor="#F0F0FF"
        iconcirclecolor="#D9D9FC"
        text="View Insights"
        url="/uni/course-insights"
      />
    ),
  },
  {
    element: (
      <WelcomePageCard
        icon={<FiSliders />}
        cardcolor="#EBFAF0"
        iconcirclecolor="#CCEBD7"
        text="Update Public Page"
        url="/uni/public-profile"
      />
    ),
  },
  {
    element: (
      <WelcomePageCard
        icon={<FiEdit />}
        cardcolor="#FFF9E5"
        iconcirclecolor="#FCEEC5"
        text="Add Courses"
        url="/uni/Addcourse"
      />
    ),
  },
  // {
  //     element: <WelcomePageCard icon={<FiEdit />} cardcolor='#FFF9E5' iconcirclecolor='#FCEEC5' text='View Users' />,
  //     url:'/admin/user/add'
  // },
];

export { cards as uniCards };
