import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFeth';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
// import Header from '../../components/scholarships/single-scholarship/Header';
import Content from '../../components/scholarships/single-scholarship/Content';

const SingleScholarship = () => {
  let { id } = useParams();
  const { data, isError, isPending } = useFetch(`/api/v1/scholarships/${id}`);
  let matches = [];
  if (data) {
    // console.log({ data });
    let reqs = data.data.scholarship.entryRequirements;
    let regex = /\[(.*?)\]/g;

    let match;
    while ((match = regex.exec(reqs))) {
      matches.push(match[1]);
    }
  }
  const override = {
    position: 'absolute',
    margin: '0 auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // borderColor: "red",
  };

  const LoadingContainer = styled.div`
    width: 100%;
    min-height: 20rem;
    position: relative;
  `;

  return (
    <>
      {isPending && (
        <LoadingContainer>
          <ClipLoader color="#333" size={60} cssOverride={override} />
        </LoadingContainer>
      )}
      {data && (
        <>
          {/* <Header
            img={data.data.scholarship.scholarCoverPhoto}
            title={data.data.scholarship.scholarName}
          /> */}
          <Content
            img={data.data.scholarship.scholarCoverPhoto}
            title={data.data.scholarship.scholarName}
            scholarId={data.data.scholarship.id}
            name={data.data.scholarship.scholarName}
            description={data.data.scholarship.scholarDescription}
            universityId={data.data.scholarship.university.id}
            universityName={data.data.scholarship.university.name}
            req={matches}
          />
        </>
      )}
    </>
  );
};

export default SingleScholarship;
