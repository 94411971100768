import React from 'react';
import Dashboard from '../../components/shared/Dashboard';
import useFetch from '../../hooks/useFeth';
import ClipLoader from 'react-spinners/ClipLoader';
import StudyAbroadsComp from '../../components/admin/StudyAbroadsComp';

const StudyAbroadAgencies = () => {
  const { data, isPending, isError } = useFetch(
    '/api/v1/universities?isAgency=true'
  );

  const override = {
    position: 'absolute',
    margin: '0 auto',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // borderColor: "red",
  };
  return (
    <>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {!isPending && (
        <Dashboard
          rightContainerContent={
            <StudyAbroadsComp uniList={data?.data?.universities} />
          }
        />
      )}
    </>
  );
};

export default StudyAbroadAgencies;
