import React, { useState } from 'react';
import { DashboardTextField } from '../shared/dashboard-form-elements/TextField';
import Label from '../shared/Form Elements/Label';
import * as a from './AddUniversitiesElements';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import submitForm from './../../hooks/submitForm';
import { useNavigate } from 'react-router-dom';

const AddStudyAbroadComp = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [completeName, setCompleteName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      toast.error('Password and Password Confirm are not equal!', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      });
      setPassword('');
      setPasswordConfirm('');
    }

    const headers = {
      'Content-Type': 'applcation/json',
    };
    const formData = {
      name,
      email,
      password,
      passwordConfirm,
      uniNameComplete: completeName,
      isAgency: true,
    };

    // console.log({ formData });

    await toast.promise(
      submitForm('/api/v1/universities', formData, 'post', {}),
      {
        loading: 'Adding Agency...',
        success: (data) => {
          // console.log({ data });
          return ` ${data.data.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );

    navigate('/admin/study-abroad-agencies/');
  };

  return (
    <>
      <a.Container>
        <a.HeaderContainer>
          <a.HeaderTitle>Add Study Abroad Agency</a.HeaderTitle>
        </a.HeaderContainer>

        <a.FormContaier>
          <form onSubmit={onSubmit}>
            <a.FormRow>
              <a.FormGroup>
                <Label text="Name of the agency" />
                <DashboardTextField
                  value={name.toUpperCase()}
                  setValue={setName}
                  placeholder="Enter agency name here | ex : SLLIT"
                />
              </a.FormGroup>
              <a.FormGroup>
                <Label text="Email of the agency" />
                <DashboardTextField
                  value={email}
                  setValue={setEmail}
                  placeholder="Enter email here | ex : abcd@mail.com"
                />
              </a.FormGroup>
            </a.FormRow>

            <a.FormRow>
              <a.FormGroup>
                <Label text="Password for agency admin" />
                <DashboardTextField
                  type={'password'}
                  value={password}
                  setValue={setPassword}
                  placeholder="Enter password here"
                />
              </a.FormGroup>
              <a.FormGroup>
                <Label text="Password Confirm for agency admin" />
                <DashboardTextField
                  type={'password'}
                  value={passwordConfirm}
                  setValue={setPasswordConfirm}
                  placeholder="Confirm password here"
                />
              </a.FormGroup>
            </a.FormRow>

            <a.FormRow>
              <a.FormGroup>
                <Label text="Complete name of the agency" />
                <DashboardTextField
                  type={'text'}
                  value={completeName}
                  setValue={setCompleteName}
                  placeholder="Enter complete name here | ex: Sri Lanka Institute of ..."
                />
              </a.FormGroup>
              <a.FormGroup>{/* <DropDown /> */}</a.FormGroup>
            </a.FormRow>

            <a.FormRow justifycontent="flex-end" flexdirection="column">
              <a.FormBtn type="submit">
                <a.SubmitIcon>
                  <FiPlusCircle />
                </a.SubmitIcon>
                Add Agency
              </a.FormBtn>
              <a.FormBtn
                onClick={() => {
                  setName('');
                  setPasswordConfirm('');
                  setPassword('');
                  setEmail('');
                  setCompleteName('');
                }}
                type="reset"
                txtcolor="#222"
                color="#fff"
              >
                <a.SubmitIcon>
                  <FiTrash2 />
                </a.SubmitIcon>
                Clear
              </a.FormBtn>
            </a.FormRow>
          </form>
        </a.FormContaier>
      </a.Container>
    </>
  );
};

export default AddStudyAbroadComp;
