import React from 'react';
import ForgotPasswordForm from '../components/forgot-password/ForgotPasswordForm';
// import ForgotPasswordFormElements from '../components/forgot-password/ForgotPasswordFormElements'

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
