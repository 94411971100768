import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-top-color: #275a8f;
  animation: spin 1s ease-in-out infinite;
`;

const Loading = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default Loading;
