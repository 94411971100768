import styled from "styled-components";


export const Container = styled.div`
    ${'' /* background-color:green ; */}
    width:100% ;
    display:flex ;
    flex-direction:column ;
    min-height:100% ;
    padding:3rem 2rem ;
    ${'' /* background-color:red ; */}
    @media (max-width: 550px) {
        padding:3rem 0rem ;

    }
`;

export const HeaderContainer = styled.div`
    ${'' /* background-color:red ; */}
    width:100% ;

`;

export const CardRow = styled.div`
    width:55% ;
    padding:2rem 0 ;
    display:flex ;
    ${'' /* justify-content:space-around ; */}
    justify-content:flex-start ;
    flex-wrap:wrap ;

    @media (max-width: 1520px) {
        width:60% ;
    }
    @media (max-width: 1400px) {
        width:75% ;
    }
    @media (max-width: 1300px) {
        width:80% ;
    }
    @media (max-width: 1200px) {
        width:85% ;
    }
    @media (max-width: 1100px) {
        width:90% ;
    }
    @media (max-width: 1000px) {
        width:100% ;
    }

`;

export const CardContainer = styled.div`

    flex:0 0 30% ;
    min-height:18rem ;
    margin-right:2rem ;
    margin-top:1rem ;

    @media (max-width: 760px) {
        flex:0 0 30% ;
        margin-right:1.5rem ;

    }
    @media (max-width: 700px) {
        flex:0 0 40% ;
        margin-right:2rem ;
        margin-top:2rem ;
    }
    @media (max-width: 600px) {
        flex:0 0 45% ;
        margin-right:2rem ;
        margin-top:2rem ;
    }
    @media (max-width: 550px) {
        flex:0 0 100% ;
        margin-right:2rem ;
        margin-top:2rem ;
        min-height:12rem ;

    }



`;













