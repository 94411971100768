import React, { useEffect, useState } from 'react';
import * as l from './LoginFormElements';
import TextField from './../shared/Form Elements/TextField';
import PasswordField from './../shared/Form Elements/PasswordField';
import RegularButton from './../shared/Form Elements/RegularButton';
import { FcGoogle } from 'react-icons/fc';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { login, continueWithGoogle, user, loading, isAuthenticated } =
    useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user && !loading) {
      switch (user?.role) {
        case 'admin':
          navigate('/admin/home');
          break;
        case 'university':
          navigate('/uni/home');
          break;
        case 'user':
          navigate('/user/home');
          break;

        default:
          navigate('/login');
      }
    }
  }, [isAuthenticated, user]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      //   console.log({codeResponse})

      const googleAccessToken = codeResponse.access_token;

      toast.promise(
        continueWithGoogle({ googleAccessToken }),
        {
          loading: 'Loggin in ...',
          success: (data) => `Logged in successfully `,
          error: (err) => {
            if (!err.response.data.message) {
              return 'Something went wrong. Try again.';
            }
            return `${err?.response?.data?.message?.toString()}`;
          },
        },
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '1.5rem',
          },
        }
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast.promise(
      login({ email, password }),
      {
        loading: 'Logging In ...',
        success: (data) => `Logged in successfully `,
        error: (err) => {
          if (!err.response.data.message) {
            return 'Something went wrong. Try again.';
          }
          return `${err?.response?.data?.message?.toString()}`;
        },
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  return (
    <l.LoginSection>
      <l.LoginContainer>
        <l.LoginLeftSection>
          <l.LeftLogoContainer />
        </l.LoginLeftSection>

        <l.LoginRightSection>
          <l.LoginRightTopSection>
            <l.ImageContainer to={'/'} />
            <l.LoginTitle>Login To Your Account </l.LoginTitle>
          </l.LoginRightTopSection>
          <l.LoginRIghtFormContainer>
            <form onSubmit={handleSubmit}>
              <l.InputWrappers>
                <TextField
                  required={true}
                  value={email}
                  setValue={setEmail}
                  placeholder={'Email'}
                />
              </l.InputWrappers>
              <l.InputWrappers>
                <PasswordField
                  required={true}
                  value={password}
                  setValue={setPassword}
                  placeholder={'Password'}
                />
              </l.InputWrappers>
              <l.ForgotPassword>
                <l.ForgotPasswordText to="/forgot-password">
                  <u>Forgot your password ?</u>
                </l.ForgotPasswordText>
              </l.ForgotPassword>
              <l.ButtonSection>
                <RegularButton
                  fontweight={'700'}
                  textcolor={'var(--white)'}
                  btntext={'Login'}
                  padding={'1.2rem 3rem'}
                  backgroundcolor={'var(--main-blue)'}
                />
              </l.ButtonSection>
            </form>
            <l.TextOR>OR</l.TextOR>
            <l.InputWrappers>
              <l.CustomGoogleBtn onClick={() => googleLogin()}>
                <l.IconContainer>
                  {' '}
                  <FcGoogle />{' '}
                </l.IconContainer>
                <l.GoogleBtnTextContainer>
                  Continue with Google
                </l.GoogleBtnTextContainer>
              </l.CustomGoogleBtn>
            </l.InputWrappers>
            <l.CreateNewAccount>
              <l.CreateNewAccountText>
                <p>
                  Don't have an account ?{' '}
                  <l.CreateNewAccountTextUnderline to="/signup">
                    Signup from here
                  </l.CreateNewAccountTextUnderline>
                </p>
              </l.CreateNewAccountText>
            </l.CreateNewAccount>
          </l.LoginRIghtFormContainer>
        </l.LoginRightSection>
      </l.LoginContainer>
    </l.LoginSection>
  );
};

export default LoginForm;
