import React from 'react';
import Dashboard from '../../components/shared/Dashboard';
import useFetch from '../../hooks/useFeth';
import { AdminCoursesComponent } from './../../components/admin/AdminCourses';
import ClipLoader from 'react-spinners/ClipLoader';
// import { useEffect } from 'react';
// import { useState } from 'react';

const AdminCourses = () => {
  const { data, isPending, isError } = useFetch('/api/v1/courses');
  // console.log(data);

  // const[]

  const override = {
    position: 'absolute',
    margin: '0 auto',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // borderColor: "red",
  };

  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {data && (
        <Dashboard
          rightContainerContent={
            <AdminCoursesComponent courseList={data.data.courses} />
          }
        />
      )}
      {/* <Dashboard rightContainerContent={<AdminCoursesComponent />} /> */}
    </div>
  );
};

export default AdminCourses;
