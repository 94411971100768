import styled from 'styled-components';
import img from './../../../images/course_cover.jpg';
import { Link } from 'react-router-dom';

export const Text = styled.p`
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '1.5rem'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  font-family: ${(props) => props.fontfamily || 'var(--primary-font)'};
  line-height: ${(props) => props.lineheight || '2.5rem'};
  display: ${(props) => props.display || 'block'};

  @media (max-width: 625px) {
    font-size: ${(props) => props.fontsize || '1.5rem'};
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontsize || '1.5rem'};
  color: ${(props) => props.color || '#333'};
  font-weight: ${(props) => props.fontweight || '1.5rem'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.bacgroundcolor || 'none'};
  border-radius: ${(props) => props.borderradius || '0'};
  padding: ${(props) => props.padding || '0'};
  position: relative;

  @media (max-width: 1000px) {
    font-size: 1.3rem;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;
export const Container = styled.div`
  width: 100%;
  /* min-height: 30rem; */
  padding: 0rem 20rem;
  position: relative;
  /* background-color: red; */
  @media (max-width: 2561px) {
    padding: 5rem 40rem;
  }
  @media (max-width: 1800px) {
    padding: 1rem 20rem;
  }
  @media (max-width: 1400px) {
    padding: 1rem 15rem;
  }

  @media (max-width: 1100px) {
    padding: 2rem 15rem;
  }
  @media (max-width: 768px) {
    padding: 5rem 10rem;
  }
  @media (max-width: 500px) {
    padding: 5rem 7rem;
  }
  @media (max-width: 426px) {
    padding: 1rem 3rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderLeft = styled.div`
  /* flex: 0 1 45%; */
  /* min-height: 20rem; */
  /* background-color: red; */
  /* padding: 2rem; */
  width: 55rem;
  height: 55rem;
  /* background-image: url(${img}); */
  /* background-image: ${(props) => props.img || `${img}`}; */
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1440px) {
    width: 45rem;
    height: 45rem;
  }
  @media (max-width: 450px) {
    width: 40rem;
    height: 40rem;
  }
`;
export const HeaderRight = styled.div`
  flex: 1;
  min-height: 20rem;
  padding: 0 0 0 2rem;
  /* background-color: green; */
  @media (max-width: 1200px) {
    padding: 2rem 0;
  }
`;

export const UniNmeLink = styled(Link)`
  text-decoration: none;
  color: #333;
  transition: all 0.2s;
  :hover {
    cursor: pointer;
    color: var(--secondary-blue);
  }
`;

export const MiniNavContainer = styled.div`
  margin: 2rem auto 0;
  min-height: 5rem;
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #ddd;

  @media (max-width: 2560px) {
    width: 70%;
  }
  @media (max-width: 2260px) {
    width: 100%;
  }
  @media (max-width: 1900px) {
    width: 100%;
  }
`;

export const MiniNavItem = styled.button`
  width: 50%;
  background-color: #fff;
  outline: none;
  border: none;
  font-weight: 600;
  padding: 1rem 1rem;
  font-family: var(--dashboard-font);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  color: ${(props) => (props.select ? '#275A8F' : '#333')};
  :hover {
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    width: 50%;
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
  @media (max-width: 600px) {
    width: 80%;
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
`;

export const InfoContainer = styled.div`
  width: 70%;

  margin: 1.5rem auto 0;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: green; */
`;
export const InfoContainerAlign = styled.div`
  width: 100%;
  display: grid;
  /* display: flex; */
  flex: 0 1 100%;
  max-height: 15rem;
  overflow-y: scroll;
  /* background-color: red; */
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    background-color: #ccc;
    width: 1rem;
    height: 10%;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--fourth-blue);
    border-radius: 0.5rem;
  }
`;

export const CheckListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

export const ForeignCourses = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;
export const SummaryRow = styled.div`
  width: 110%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const SummaryRowForeign = styled.div`
  width: 110%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const SummaryVal = styled.div`
  margin: 0 0 0 auto;
`;

export const SubmitBtn = styled.div`
  max-width: 25rem;
  margin: 2rem auto 0;
  background-color: #275a8f;
  padding: 1rem 2rem;
  border: 1px solid #fff;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  font-family: var(--dashboard-font);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1350px) {
    font-size: 1.2rem;
  }
  @media (max-width: 944px) {
    max-width: 30%;
  }
  @media (max-width: 830px) {
    max-width: 35%;
  }
  @media (max-width: 660px) {
    max-width: 40%;
  }
  @media (max-width: 600px) {
    max-width: 60%;
  }
  @media (max-width: 360px) {
    max-width: 90%;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  ${'' /* transform: translate(-50%, -50%); */}
  width:100%;
  height: 100%;
  z-index: 999999;

  ${'' /* background-color: red; */}

  display:flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    background-color: rgba(39, 91, 143, 0.86);
  }
`;

export const PopupForm = styled.form`
  flex: 0 1 50%;
  ${'' /* min-height: 0rem; */}
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  justify-content: flex-start;
  padding: 3rem 5rem;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 1000px) {
    flex: 0 1 70%;
  }
  @media (max-width: 600px) {
    flex: 0 1 90%;
  }
`;

export const FormTitle = styled.p`
  font-size: 2.5rem;
  font-family: var(--dashboard-font);
  color: #333;
  font-weight: 700;
  margin: 0 0 1rem 0;
  margin-right: auto;
`;

export const FormGroup = styled.div`
  width: 80%;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const ContactSubmitBtn = styled.button`
  max-width: 35%;
  margin: 2rem auto;
  background-color: #275a8f;
  padding: 1rem 3rem;
  border: 1px solid #fff;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  font-family: var(--dashboard-font);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 0%.75;
  }
`;

export const PopupCloseBtn = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: none;
  outline: none;
  ${'' /* background-color: red; */}
  font-size:3rem;
  color: var(--fourth-blue);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;
