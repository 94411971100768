import React from 'react'
import Dashboard from '../../../components/shared/Dashboard'
import SingleEvent from '../../../components/university/dashboard-components/SingleEvent'

const UniversitySingleEvent = () => {
  return (
      <div>
        <Dashboard
        rightContainerContent={
          <>
            <SingleEvent/>
          </>
        }
      />
      </div>
  )
}

export default UniversitySingleEvent