import React from 'react';
import * as u from './AdminCoursesElements';
// import { BiSearch } from 'react-icons/bi';
// import { FiPlusCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import { toast } from 'react-hot-toast';
import ToggleSwitch from '../shared/dashboard-form-elements/ToggleSwitch';
import submitForm from '../../hooks/submitForm';

const AdminCourses = ({ courseList, setUrl }) => {
  const updateUniVisibilityLocal = async (course) => {
    await toast.promise(
      submitForm(
        `/api/v1/courses/${course.local ? 'loDeactivate' : 'loActivate'}/${
          course._id
        }`,
        {},
        'patch',
        {}
      ),
      {
        loading: 'Updating Local Course...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  const updateUniVisibilityForeign = async (course) => {
    await toast.promise(
      submitForm(
        `/api/v1/courses/${course.foreign ? 'fcDeactivate' : 'fcActivate'}/${
          course._id
        }`,
        {},
        'patch',
        {}
      ),
      {
        loading: 'Updating Foreign Course...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  const updateCourseVisibility = async (course) => {
    // console.log(course.active);
    await toast.promise(
      submitForm(
        `/api/v1/courses/${course.active ? 'deactivate' : 'activate'}/${
          course._id
        }`,
        {},
        'patch',
        {}
      ),
      {
        loading: 'Updating Course...',
        success: (data) => {
          // console.log({ data });
          window.location.reload(false);
          return ` ${data.data?.message} ` || 'success';
        },
        error: (err) => `${err.response.data.message}`,
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '2rem',
        },
      }
    );
  };

  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>All Courses</u.HeaderTitle>

        {/* <u.SearchBarContainer>
          <u.SearchBar placeholder="Enter University or Course Name" />
          <u.SearchIconContainer>
            {' '}
            <BiSearch />{' '}
          </u.SearchIconContainer>
        </u.SearchBarContainer> */}
      </u.HeaderContainer>

      <u.TableContainer>
        <u.Table>
          <u.TableHeader>
            <u.TableDataCell th={true}>#</u.TableDataCell>
            <u.TableDataCell th={true}>University</u.TableDataCell>
            <u.TableDataCell th={true}>Course Name</u.TableDataCell>
            <u.TableDataCell th={true}>Course Local Status</u.TableDataCell>
            <u.TableDataCell th={true}>Course Foreign Status</u.TableDataCell>
            <u.TableDataCell th={true}>Status</u.TableDataCell>
            <u.TableDataCell th={true}>Date Added</u.TableDataCell>
          </u.TableHeader>

          {courseList.map((course, i) => {
            return (
              <u.TableRow key={i}>
                <u.TableDataCell>{i + 1}</u.TableDataCell>
                <u.TableDataCell>{course.university.name}</u.TableDataCell>
                <u.TableDataCell>{course.courseName}</u.TableDataCell>
                <u.TableDataCell>
                  <ToggleSwitch
                    checked={course.local}
                    handleChange={() => {
                      updateUniVisibilityLocal(course);
                    }}
                  />
                  {course.local ? 'Active' : 'Deactive'}
                </u.TableDataCell>
                <u.TableDataCell>
                  <ToggleSwitch
                    checked={course.foreign}
                    handleChange={() => {
                      updateUniVisibilityForeign(course);
                    }}
                  />
                  {course.foreign ? 'Active' : 'Deactive'}
                </u.TableDataCell>
                <u.TableDataCell>
                  <ToggleSwitch
                    checked={course.active}
                    handleChange={() => {
                      updateCourseVisibility(course);
                    }}
                  />
                  {course.active ? 'Active' : 'Deactive'}
                </u.TableDataCell>
                <u.TableDataCell minwidth="10rem">
                  {course.createdAt.split('T')[0]}
                </u.TableDataCell>
              </u.TableRow>
            );
          })}
        </u.Table>
      </u.TableContainer>

      <u.TablePaginationContainer>
        <Pagination
          // color="secondary"
          size="large"
          count={5}
          defaultPage={1}
          siblingCount={0}
        />
      </u.TablePaginationContainer>
    </u.Container>
  );
};

export { AdminCourses as AdminCoursesComponent };
