import React from 'react';
import styled from 'styled-components';

const InputField = styled.input`
  width: 100%;
  border-radius: 1rem;
  outline: none;
  border: none;
  padding: 1.5rem 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1% 0;
  color: var(--form-input-text-color);
  border: 1px solid #ddd;
  font-family: var(--dashboard-font);

  :hover {
    border: 1px solid #999;
  }

  :focus {
    outline: 1px solid #333;
  }

  ::placeholder {
    color: #999;
  }

  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

const TextField = ({
  placeholder,
  value,
  setValue,
  required,
  type,
  disabled,
}) => {
  return (
    <InputField
      required={required}
      value={value !== 'undefined' ? value : placeholder}
      onChange={(e) => setValue(e.target.value)}
      type={'text'}
      placeholder={placeholder || 'placeholder'}
      disabled={disabled}
    />
  );
};

export { TextField as DashboardTextField };
