import React from 'react';
import Dashboard from '../../components/shared/Dashboard';
import AddStudyAbroadComp from '../../components/admin/AddStudyAbroadComp';

const AddStudyAbroad = () => {
  return (
    <>
      <Dashboard rightContainerContent={<AddStudyAbroadComp />} />
    </>
  );
};

export default AddStudyAbroad;
