import React, { useEffect, useRef, useState } from 'react';
import * as n from './NavbarElements';
import { BsList } from 'react-icons/bs';
import LinkButton from './LinkButton';
import useAuth from '../../hooks/useAuth';

const Navbar = () => {
  const [navToggle, setNavToggle] = useState(false);

  const { isAuthenticated } = useAuth();

  const navRef = useRef(null);

  const handleClickOutside = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setNavToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <n.NavbarSection>
        <n.NavContainer>
          <n.NavBrandContainer to="/" />
          <n.NavLinksContainer>
            {!isAuthenticated ? (
              <>
                <n.NavLinkBtnContainer>
                  <LinkButton
                    fontsize={'1.4rem'}
                    fontweight={'500'}
                    to={'/signup'}
                    btntext={'SignUp'}
                    padding={'0.8rem'}
                  />
                </n.NavLinkBtnContainer>
                <n.NavLinkBtnContainer>
                  <LinkButton
                    fontsize={'1.4rem'}
                    fontweight={'500'}
                    to={'/login'}
                    btntext={'LogIn'}
                    padding={'0.8rem'}
                  />
                </n.NavLinkBtnContainer>
              </>
            ) : (
              <n.NavLinkBtnContainer>
                <LinkButton
                  fontsize={'1.4rem'}
                  fontweight={'500'}
                  to={'/login'}
                  btntext={'Profile'}
                  padding={'0.8rem 1.5rem'}
                />
              </n.NavLinkBtnContainer>
            )}
          </n.NavLinksContainer>
          <n.NavToggleBtnContainer
            ref={navRef}
            onClick={() => setNavToggle(!navToggle)}
          >
            <BsList />
          </n.NavToggleBtnContainer>

          <n.NavDropDownLinksContainer toggle={navToggle}>
            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/"
                btntext={'Home'}
              />
            </n.NavDropDownBtnContainer>

            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/aboutus"
                btntext={'About Us'}
              />
            </n.NavDropDownBtnContainer>
            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/universities"
                btntext={'Universities'}
              />
            </n.NavDropDownBtnContainer>
            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/courses"
                btntext={'Courses'}
              />
            </n.NavDropDownBtnContainer>
            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/scholarships"
                btntext={'Scholarships'}
              />
            </n.NavDropDownBtnContainer>
            <n.NavDropDownBtnContainer>
              <LinkButton
                fontsize={'1.4rem'}
                fontweight={'bold'}
                to="/study-abroad"
                btntext={'Study Abroad'}
              />
            </n.NavDropDownBtnContainer>

            <n.NavDropdownAuthBtnContainer>
              <n.NavDropDownBtnContainer>
                <LinkButton
                  to={'/signup'}
                  fontweight={'bold'}
                  btntext={'Sign Up'}
                />
              </n.NavDropDownBtnContainer>
              <n.NavDropDownBtnContainer>
                <LinkButton
                  to={'/login'}
                  fontweight={'bold'}
                  btntext={'LogIn'}
                />
              </n.NavDropDownBtnContainer>
            </n.NavDropdownAuthBtnContainer>
          </n.NavDropDownLinksContainer>
        </n.NavContainer>
      </n.NavbarSection>
    </>
  );
};

export default Navbar;
