import React, { useState } from "react";
import Dashboard from "../../../components/shared/Dashboard";
import ScholarshipInsights from "../../../components/university/dashboard-components/ScolarshipInsights.jsx";
import useFetch from "../../../hooks/useFeth";
import ClipLoader from "react-spinners/ClipLoader";
import useAuth from "../../../hooks/useAuth";

const override = {
  position: "absolute",
  margin: "0 auto",
  top: "30%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  // borderColor: "red",
};

const UniversityScholarshipsInsights = () => {
  const { user } = useAuth();
  const [uniId] = useState(user?.uni?.id);

  const { data, isPending, isError } = useFetch(
    "/api/v1/insights/scholar/" + uniId
  );

  return (
    <div>
      {isPending && (
        <Dashboard
          rightContainerContent={
            <ClipLoader color="#275A8F" size={60} cssOverride={override} />
          }
        />
      )}
      {isError && <Dashboard rightContainerContent={<h3>error ...</h3>} />}
      {data && !isPending && (
        <Dashboard
          rightContainerContent={
            <ScholarshipInsights scholarInsights={data.data.scholarInsights} university={user.uni}/>
          }
        />
      )}
      {/* <Dashboard
        rightContainerContent={
          <>
            <Courses />
          </>
        }
      /> */}
    </div>
  );
};

export default UniversityScholarshipsInsights;
