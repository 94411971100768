import styled from "styled-components";

export const Container = styled.div`
  ${"" /* background-color:green ; */}
  width:100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3rem 2rem;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  ${"" /* background-color:red ; */}
  width:100%;
  display: flex;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--dashboard-font);
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const FormContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 5% 0;
  @media screen and (max-width: 800px){
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
export const Fields = styled.div`
  @media screen and (max-width: 800px){ 
    margin-top: 15%;
  }
`;
export const Button = styled.div`
  width: 20%;
  margin: 4% 0;
  @media screen and (max-width: 800px){
    width: 50%;
  }
`;
export const Logo = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 20px;
  margin-bottom: 5%;
`;
export const ImageSubSec = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: var(--ternary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;

`;
export const Icon = styled.label`
  & {
    width: fit-content;
    height: fit-content;
    border-radius: 50px;
    background-color: var(--main-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 5%;
    position: relative;
    top: -30%;
    left: 5%;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Cover = styled.div`
  width: 100%;
  height: 200px;
  margin: 2% 0 8% 0;
  border-radius: 20px;
  background-color: var(--ternary-blue);
`;
export const CoverSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const CoverIcon = styled.label`
  & {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: var(--main-blue);
    display: flex;
    margin-bottom: 1%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: relative;
    top: -15%;
    left: 0%;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Text = styled.div`
  display: flex;
  position: relative;
  top: -15%;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 800px){
    font-size: 14px;
  }
`;
