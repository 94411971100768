import React from 'react'
import PrivacyPolicy from '../components/shared/PrivacyPolicy'

const AboutUs = () => {
    return ( 
        <>
            <PrivacyPolicy />
        </>
     );
}
 
export default AboutUs;