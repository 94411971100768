import React, { useEffect, useState } from 'react';
import { FaCamera } from 'react-icons/fa';
import { DashboardTextField } from '../../shared/dashboard-form-elements/TextField';
import RegularButton from '../../shared/Form Elements/RegularButton';
import TextBox from '../../shared/Form Elements/TextBox';
import * as u from './UniProfileElements';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth.js';
import axios from 'axios';
import validateImage from '../../../hooks/validateImage';

const UniProfile = () => {
  const { user } = useAuth();
  const [userId] = useState(user?.id);

  const [logo, setLogo] = useState(null);
  const [uniName, setUniName] = useState('');
  const [uniNameComplete, setUniNameComplete] = useState('');
  const [uniDescription, setUniDescription] = useState('');
  const [uniAddress, setUniAddress] = useState('');
  const [uniUrl, setUniUrl] = useState('');
  const [cover, setCover] = useState(null);
  const [uniId, setUniId] = useState('');

  useEffect(() => {
    fetch(`/api/v1/universities/getByClerk/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error loading data');
        }
        return response.json();
      })
      .then((data) => {
        // console.log({ data });
        setUniName(data.name);
        setLogo(data.uniLogo);
        setCover(data.uniCoverPhoto);
        setUniDescription(data.uniDescription);
        setUniAddress(data.uniAddress);
        setUniUrl(data.uniUrl);
        setUniId(data._id);
        setUniNameComplete(data?.uniNameComplete);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId]);

  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
    // console.log({ logo });
    // console.log(typeof logo);
    validateImage(e.target.files[0], 200, 200, setLogo);
  };
  const handleCover = (e) => {
    setCover(e.target.files[0]);
    validateImage(e.target.files[0], 1000, 300, setCover);
  };

  const handleUpload = async (e, formData) => {
    try {
      const res = await axios({
        method: 'PATCH',
        url: `/api/v1/universities/${uniId}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      // console.log(err.response.data);
    }
  };

  /////////////////// Update University Profile Details (START) ///////////////////
  const updatePublicProfile = async (e) => {
    const formData = new FormData();

    formData.append('uniLogo', logo);
    formData.append('uniId', uniId);
    //formData.append('uniUrl', uniUrl);
    formData.append('uniAddress', uniAddress);
    formData.append('uniDescription', uniDescription);
    formData.append('name', uniName);
    formData.append('uniNameComplete', uniNameComplete);
    formData.append('uniCoverPhoto', cover);

    if (!cover) {
      return toast.error('Please upload cover image.');
    }
    if (!logo) {
      return toast.error('Please upload logo image.');
    }

    // const formData = { name, uniDescription, uniAddress, uniUrl };

    // console.log({
    //   uniId,
    //   cover,
    //   //uniUrl,
    //   uniAddress,
    //   uniDescription,
    //   uniName,
    //   logo,
    //   uniNameComplete,
    // });

    return toast.promise(
      handleUpload(e, formData),
      {
        loading: 'Updating University...',
        success: 'University updated successfully!',
        error: 'Failed to update University',
      },
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          fontSize: '1.5rem',
        },
      }
    );
  };

  /////////////////// Update University Profile Details (END) ///////////////////
  return (
    <u.Container>
      <u.HeaderContainer>
        <u.HeaderTitle>Public Profile </u.HeaderTitle>
      </u.HeaderContainer>
      <u.FormContainer>
        <u.Logo>
          <u.ImageSubSec>
            {logo ? (
              <u.LogoImage
                src={
                  typeof logo === 'object' ? URL.createObjectURL(logo) : logo
                }
              />
            ) : (
              <p></p>
            )}
          </u.ImageSubSec>
          <u.Icon>
            <FaCamera />
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={handleLogo}
            />
          </u.Icon>
          <u.Text>
            <p>Logo Upload </p>
          </u.Text>
          <u.Text>
            <u.LogoPlaceholder>(200px X 200px)</u.LogoPlaceholder>
          </u.Text>
        </u.Logo>
        <u.Fields>
          <DashboardTextField
            placeholder={'University Name'}
            value={uniName.toUpperCase()}
            setValue={setUniName}
          />
          <DashboardTextField
            placeholder={'Complete University Name'}
            value={uniNameComplete}
            setValue={setUniNameComplete}
          />
          <TextBox
            placeholder={'Description'}
            value={uniDescription}
            setValue={setUniDescription}
          />
          {/* <DashboardTextField
            placeholder={'URL'}
            value={uniUrl}
            setValue={setUniUrl}
          /> */}
          <DashboardTextField
            placeholder={'Address'}
            value={uniAddress}
            setValue={setUniAddress}
          />
        </u.Fields>
        <u.Cover>
          <u.CoverSection
            img={
              cover && typeof cover === 'object'
                ? URL.createObjectURL(cover)
                : cover
            }
          >
            {/* {cover ? (
              <u.CoverImage
                src={
                  typeof cover === 'object' ? URL.createObjectURL(cover) : cover
                }
              />
            ) : (
              <p></p>
            )} */}
          </u.CoverSection>
          <u.CoverIcon>
            <FaCamera />
            <input
              type="file"
              id="file1"
              accept="image/*"
              onChange={handleCover}
            />
          </u.CoverIcon>
          <u.Text>
            <p>Cover Upload</p>
          </u.Text>
          <u.Text>
            <u.LogoPlaceholder>(1000px X 300px)</u.LogoPlaceholder>
          </u.Text>
        </u.Cover>
        <u.Button>
          <RegularButton
            fontweight={'bold'}
            backgroundcolor={'var(--fourth-blue)'}
            textcolor={'white'}
            btntext={'Submit'}
            onClick={updatePublicProfile}
          />
        </u.Button>
      </u.FormContainer>
    </u.Container>
  );
};

export default UniProfile;
