import Dashboard from '../../../components/shared/Dashboard';
import Addcourse from '../../../components/university/dashboard-components/AddCourse';
// import AddEvent from "../../../components/university/dashboard-components/AddEvent";

const UniversityAddCourse = () => {
  return (
    <div>
      <Dashboard
        rightContainerContent={
          <>
            <Addcourse />
          </>
        }
      />
    </div>
  );
};

export default UniversityAddCourse;
